.video-placeholder {
  @include vertical-margin(4);
	position: relative;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  // fixed problem with ie
  @supports(display:grid) {
    display: flex;
    justify-content: center; 
    align-items: center;
    grid-column: 1 / -1;
  }
  &__play-button {
    width: 100%;
    max-width: 80px; 
    position: absolute;
    left: 45%;
    top: 35%;   
    z-index: 2; 
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    svg {
      width: 100%;
      path {
        fill: $c-darkNeutral;
        fill: var(--c-dark-neutral);
        &:last-child {
          fill: $c-lightNeutral;
          fill: var(--c-light-neutral);
        }
      }
    }
  }
  &__image {				
    position: absolute;
    top: -16.8%;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%!important;
    margin: 0!important;
  }
  &__video {
		position: relative;
		overflow: hidden;
		padding-top: 56.25%;
		width: 100%;
		z-index: 0;
		opacity: 0;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
		}
  }
  &--active {
		.video-placeholder__video{
			z-index: 2;
			opacity: 1;
			transition: all 1s ease;
    }
    .video-placeholder__play-button {
      opacity: 0;
    }
	}
  @supports(display:grid) {
    &__play-button {
      left: auto;
      top: auto;
    }
  }
  // @media all and (min-width:$large-tablet) {
  //   &__image {
  //     width: 100%;
  //     margin: 0;
  //   }
  // }
}