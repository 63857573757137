.grid {
  @supports (display:grid){
    display:grid;
    grid-template-columns:repeat(12, 1fr);
    grid-gap:20px;    
    .ktc-border-root {
      grid-column: 1/-1;
    }   
  }
  // styling for browsers that do not support grid
  max-width: 1160px;
  margin: 0 auto;
  h1, h2, h3, h4, h5, h6, p {
    grid-column: 1/-1;
  }
}


//when grid is within a form, align the grid items to end, as they are likely inputs
//otherwise wrapping of labels causes mis-alignment of rows
form {
  .grid {
    align-items: end;
  }
}