.btn {
  background-image: linear-gradient(0deg, #FFBB48 0%, #FFBB48 100%);
  background-size: 100%;
  position: relative;
  @include color-light-neutral;
  font-size: 16px;
  border-radius: $border-radius; 
  padding: 12px 20px;
  font-weight: $regular;
  transition: all 0.3s ease-in;
  text-decoration: none!important;
  display: inline-block;
  z-index: 2;
  cursor: pointer;
  &:before {
    background-image: linear-gradient(0deg, #F39200 0%, #FFBB48 100%);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    opacity: 1;
    width: 100%;
    z-index: -1;
    transition: opacity 0.3s ease-in;
    border-radius: 3px;
  }
  &:hover {
    &:before {
      opacity: 0;
    }
  }
}