.card.card--community {
  min-height: 0!important;

  .card__content {
    border-top: 1px solid $c-primary; 
    display: flex;
    flex-wrap: wrap;
    p {
      text-decoration: underline;
      align-self: flex-end;
      @include color-primary-accent;
    }
  }

  .card__image {
    @include background-light-neutral;
    padding: 15px; //space between image/logo and edge of card
    // margin: 1px 1px 0; //prevent image overlapping parent border - this causes a doubling up of the border on hover, but better than being cropped by default
  }
  &:hover {
  	// .card__image {
  	// 	// margin: 0; //remove image margin on hover - inconsistent results, sometimes results in border covered again, and creates 'zoom' effect on image
  	// }
  }
}