.gallery {
  display: flex;
  flex-wrap: wrap;
  @include margin-top(6);
  @supports(display:grid) {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns:repeat(12, 1fr);
    grid-gap:20px;
    &__item {
      grid-column: span 6;
      cursor: pointer;
      margin-right: 0;
    }
  }
  h2 {
    grid-column: 1/-1;
    flex-basis: 100%;
  }
  &__item {
    height: 130px;
    width: 100%;
    // margin-right: 20px;
    flex-basis: 46%;
    margin-bottom: 10px;
    &:nth-child(even) {
      margin-right: 15px;
    }
    @supports(display:grid) {
      margin-bottom: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media all and (min-width: $screen-xxs) {
    @supports(display:grid) {
      &__item {
        grid-column: span 4;
      }
    }
    &__item {
      height: 90px;
      
    }
  }
  @media all and (min-width:$screen-extra-small){
    &__item {
      height: 130px;
      flex-basis: 30%;
      margin-right: 10px;
      &:nth-child(even) {
        margin-right: 10px;
      }
      @supports(display:grid) {
        margin-right: 0;
      }
    }
  }
  @media all and (min-width:$screen-medium){
    
    @supports(display:grid) {
      &__item {
        grid-column: span 3;
      }
    }
  }
  @media all and (min-width:$large-tablet) {
    &__item {
      flex-basis: 23%;
    }
    @supports(display:grid) {
      grid-column: 2/12;
    }
  }
  @media all and (min-width:$screen-xl){
    &__item {
      height: 175px;
    }
  }
}

