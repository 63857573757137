.gallery-popup {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
  top:0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
	z-index: 30;
	&__content {
    position: relative;
    overflow: hidden;
    max-width: 90%;
	}
  &__image-container {
		display: none;
		&--active{
      display:block;
      max-height: 720px;
			.gallery-popup__info {
				display: block;
			}
		}		
	}
	&__btn {
    @include background-primary-accent;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 40%;
    &:after {
      @include before-after;
      left: 39px;
      top: 40%;
      width: 10px;
      height: 10px;      
      transform: rotate(-135deg);
      transition: all .4s ease;
      border-top: 2px solid $c-lightNeutral;
      border-right: 2px solid $c-lightNeutral;       
    }    
    &--prev { 
      left: -30px;           
    }
    &--next { 
      right: -30px;  
      &:after {
        left: auto;
        right: 40px;
        transform: rotate(45deg);
      }          
    }
	}
	&__close {
		@include background-primary-accent;
		padding: 16px;
		cursor:pointer;
		position: absolute;
		right:0;
    top: 0;    
    svg {
      width: 100%;
      max-width: 20px;
      max-height: 30px;
      circle {
        fill: $c-lightNeutral;
        fill: var(--c-light-neutral);        
      }
      path {
        fill: $c-primary;
        fill: var(--c-primary);
      }
    }
	}
	&__info {
		@include background-primary;
		flex-basis: 100%;
		padding: 20px;
    text-align: center;   
    min-height: 102px;
    margin-top: -3px;
    display: none;
    p {
      @include color-light-neutral;
    }
	}
	&--active {
		display:flex;
		flex-wrap:wrap;
		justify-content: center;
		align-items: center;		
  }
  @media all and (min-width:$screen-small){
    &__close {
      padding: 20px;
      svg {
        max-width: 25px;
      }
    }
  }
  @media all and (min-width:$screen-medium) {
    &__content {
      max-width: 80%;
    }
  }
  @media all and (min-width:$large-tablet) {
    &__content {
      max-width: 800px;
    }
  }
}