
.hero.hero--fallback {
  overflow: hidden;
  position: relative;  
  &:before, &:after {
    @include before-after;
    border-radius: 50%;
  }
  &:before {
    @include background-primary-accent;
    height: 300px;
    width: 300px;
    top: -150px;
    right:-100px;
    z-index: 0; 
    animation: floatOne 9s infinite ease-in-out;
  }
  &:after {
    @include background-light-neutral;
    height: 150px;
    width: 150px;
    bottom: -40px;
    right:-50px;
    z-index: 1; 
    opacity: 0.6;
    animation: floatTwo 9s infinite ease-in-out;
  }
  @media all and (min-width: $large-tablet){
    &:before {
      width: 500px;
      height: 500px;
      top: -250px;
      right: 30px;
    }
    &:after {
      width: 300px;
      height: 300px;
      bottom: -110px;
      right: 10px;
    }
  }
  @media all and (min-width: $screen-xl){
    &:before {
      width: 700px;
      height: 700px;
      top: -450px;
      right: 90px;
    }
    &:after {
      width: 450px;
      height: 450px;
      bottom: -310px;
      right: 70px;
    }
  }
  @keyframes floatOne {
    0%, 100% {
      transform: translate(0,0);
    }
    20% {
      transform: translate(3%,3%);
    }
    50% {
      transform: translate(1%,2%);
    }
    75% {
      transform: translate(2%,4%);
    }
  }
  @keyframes floatTwo {
    0%, 100% {
      
      transform: translate(-1%,-4%);
    }
    10% {
      transform: translate(0,-2%);
    }
    50% {
      transform: translate(-3%,2%);
    }
    75% {
      transform: translate(1%,3%);
    }
  }
}