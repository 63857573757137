.nav__primary {
  @include background-light-neutral;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 124px;
  bottom: 0;
  transform: translateX(100%);
  transition: .4s ease;
  opacity: 0;
  overflow-x: scroll;
  padding-bottom: 100px;
  // padding-bottom: 200px;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav__franchise-name {
    display: flex;
    align-items: center;
    a {
      @include color-primary;
      width: 100%;
      span {
        width: 25px;
        svg {
          margin-right: 10px;
          max-width: 20px;
        }
      }
    }
  }
  a {
    @include color-dark-neutral;
    text-decoration: none;
    padding: 30px 0 30px 30px;
    display: block;
    line-height: 22px;
    @include footer-border(0 0 2px 0);
    li {
      &:after {
        display: none;
      }
    }
  }
  .btn {
    @include color-light-neutral;
    padding: 15px 30px;
    margin-top: 40px;
  }
  li {
    margin-bottom: 0;

  }

  .nav__trigger {
    display: block;
    padding: 30px 0 30px 30px;
    @include footer-border(0 0 2px 0);
    position: relative;
    cursor: pointer;
    &:after {
      @include before-after;
      right: 30px;
      top: 40%;
      @include arrow(45deg);
    }
  }
  .nav__dropdown-button {
    max-width: 400px;
    margin: 0 auto;
    padding: 0 30px;
    text-align: center;
    .btn {
      text-align: center;
    }
  }

  .nav__social-dropdown {
    margin-top: 40px;
    ul {
      text-align: center;
      li {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        a {
          border: none;
          padding: 0;
        }
      }
    }
  }
  .nav__level-two {
    @include background-light-neutral;
    // height: calc(100vh - 80px);
    left: initial;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 124px;
    bottom: 0;
    transform: translateX(100%);
    transition: .4s ease;
    width: 100%;
    padding-bottom: 100px;
    z-index: 1;
    opacity: 0;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    ul {
      @include margin-bottom(3);
    }
  }
  .nav__level-two-back {
    @include background-light-grey;
    padding: 29px 38px;
    position: relative;
    @include margin-right(2);
    margin-left: -11px;
    &:before {
      @include before-after;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      width: 7px;
      height: 7px;
      left: 44%;
      top: 44%;
      transform: rotate(-135deg);
    }
  }
  .nav__level-primary {
    @include color-primary;
    padding: 30px 0 30px 13px;
    @include footer-border(0 0 2px 0);
    &:after {
      display: none!important;
    }
  }
  .nav__level-three {
    @include background-light-neutral;
    // height: calc(100vh - 80px);
    height: 100%;
    left: initial;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 124px;
    bottom: 0;
    transform: translateX(100%);
    transition: .4s ease;
    width: 100%;
    z-index: 1;
    opacity: 0;
    padding-bottom: 300px;
    overflow-x: scroll;
   -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    // li {
    //   &:after {
    //     display: none;
    //   }
    // }
  }
  .nav__info-card {
    @include background-secondary;
    border-radius: 5px;
    @include vertical-margin(3);
    @include horizontal-margin(3);
    @include vertical-padding(2);
    @include horizontal-padding(2);
    h5, p ,a{
      @include color-light-neutral;
    }
    h5 {
      @include margin-bottom(2);
    }
    p {
      @include margin-bottom(2);
    }
    a {
      text-decoration: underline;
      border: none;
      padding: 0;
    }
    .link-arrow {
      &:after {
        padding: 0px 8px 1px;
        font-weight: 600;
      }
    }
  }
  .nav__view-more {
    a {
      text-decoration: underline;
    }
    span {
      font-size: 20px;
    }
  }
  @media all and (min-width:$large-tablet) {
    transform: none;
    background: none;
    max-height: 81px;
    top: 89px;
    opacity: 1;
    padding: 0 40px;
    overflow-x: visible;
    @include background-primary-ukraine;
    box-shadow: 0px 6px 22px -10px rgba(0,0,0,0.57);
    &:before {
      @include primary-line-horizontal-ukraine;
      top: auto;
      bottom: 0;
    }
    &:after {
      @include secondary-line-horizontal-ukraine;
      top: auto;
      bottom: 9px;
    }
    .nav__franchise-name {
      display: none;
    }
    .btn {
      display: none;
    }
    .nav__social-dropdown  {
      display: none;
    }
    .nav__non-level-link {

      @include color-light-neutral;

    }
    li {
      display: inline-block;
      @include color-light-neutral;
      @include margin-bottom(0);
      margin-right: -4px;
      transition: $basicHover;
      &:hover {
        @include background-primary-accent-ukraine;
        & > .nav__trigger-arrow:after {
          transform: rotate(-45deg) translateY(100%);
          transform-origin: left bottom;
        }
      }
      a {
        border: none;
        display: inline-block;
        padding: 22px 20px;
      }
    }
    .nav__trigger {
      border: none;
      padding: 25px 35px 25px 15px;
      &:after {
        border-color: $c-lightNeutral;
        border-color: var(--c-light-neutral);
        right: 10px;
        width: 6px;
        height: 6px;
        transform: rotate(135deg);
      }

    }
    .nav__level-two  {
      display: none;
      opacity: 1;
      transform: none;
      border-radius: 5px;
      box-shadow: $Shadow;
      top: 66px;
      left: 40px;
      bottom: auto;
      width: 960px;
      height: auto;
      min-height: 460px;
      overflow: hidden;
      position: absolute;
      padding-bottom: 0;
      @include background-light-grey;
      ul {
        order: 1;
        flex-basis: 33%;
        @include footer-border(0 2px 0 0);
        border-color: $light-grey;
        @include background-light-neutral;
        margin-bottom: 0;
        min-height: 450px;
        li {
          @include color-primary;
          font-size: 18px;
          font-weight: $semi-bold;
          display: block;
          @include footer-border(0 0 2px 0);
          border-color: $light-grey;
          &:after {
            border-color: $c-darkNeutral;
            border-color: var(--c-dark-neutral);
            transform: rotate(45deg);
          }
          &:hover {
            background: none;
          }
          &:hover>.nav__trigger:after {
            transform: rotate(45deg);
            // transform-origin: 0%;
          }
          a {
            font-weight: $regular;
            padding-left: 25px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .nav__trigger {
        padding-left: 25px;
        line-height: 26px;
        &:after {
          border-color: $c-darkNeutral;
          border-color: var(--c-dark-neutral);
          right: 25px;
          width: 6px;
          height: 6px;
          transform: rotate(45deg);
        }
        &--active {
          background: $light-grey;
        }
      }
    }
    .nav__level-two.child-menu-active {
      display: flex;
      flex-wrap: wrap;

    }
    .nav__level-three {
      opacity: 1;
      @include background-light-grey;
      background-color: $light-grey!important;
      background-color: var(--light-grey)!important;
      transform: none;
      padding-bottom: 0;
      position: absolute;
      top: 0;
      height: 100%;
      display: none;
      max-height: 600px;
      @supports(display:grid) {
        width: 37%;
      }
      // ie fix
      width: 29%;
      // ie fix
      overflow: hidden;
      .nav__info-card {
        display: none;
      }
      a {
        display: block;
        border-width: 0;
        font-weight: $regular;
        font-size: 14px;
        padding: 17px 45px 0 45px;
        line-height: 22px;
        // @include color-dark-neutral;


        //short desktop screens -
        //make the third-level nav items shorter so don't go offscreen
        @media (min-width:$large-tablet) and (max-height: 640px){
          padding-top: 11px;
        }
      }
      li {
        border: none!important;
      }
    }
    .nav__level-three.child-menu-active {
      // transform: none!important;
      // transform: translate3d(75%,0,0)!important;
      display: block;
    }
    .nav__info-card {
      @include horizontal-margin(0);
      @include vertical-margin(0);
      flex-basis: 30%;
      @include vertical-padding(3);
      @include horizontal-padding(3);
      border-radius: 5px 0 0 5px;
      h5 {
        font-size: 22px;
      }
      a {
        padding: 0;
      }
    }
    .nav__dropdown-button {
      display: none;
    }
    .nav__level-two-back, .nav__level-primary {
      display: none!important;
    }
    .child-menu-active {
      display: block;
    }
  }
  @media all and (min-width:1100px) {

    .nav__trigger {
      padding: 25px 30px 25px 20px;
    }

  }
  @media all and (min-width: $screen-xl) {
     li {
       a {
         padding: 22px 25px;
       }
     }
    .nav__trigger {
      padding: 25px 50px 25px 25px;
      &:after {
        right: 20px;
      }
    }
  }

  @media all and (min-width:1300px) {
    .nav__level-two {
      left: 0;
    }
    .nav__list-item {
      position: relative;
    }
  }
  @media all and (min-width:1500px) {
    padding: 0 80px;
    .nav__level-two {
      width: 960px;
    }
  }
}

.child-menu-active, .main-menu-active {
  opacity: 1!important;
  transform: none!important;
}
// .child-menu-active {
//   position: fixed!important;
// }
