.main-content{
  padding-top: 120px;
  display: block;
  &--campaign {
    padding-top: 92px;
  }
  @media all and (min-width: $large-tablet) {
    padding-top: 155px;
    &--campaign {
      padding-top: 92px;
    }
  }
}