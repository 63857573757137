.homepage-hero.homepage-hero--campaign {
  @include margin-bottom(3);
  @include padding-bottom(5);
  min-height: 480px;
  height: auto;
  .homepage-hero__content {
    padding-bottom: 35px;
    &:after {
      bottom: -20px;
    }
  }
  .contact-form {
    @include margin-bottom(3);
    max-width: 700px;
    form {
      padding: 0;
    }
  }
  .cta-bar {
    text-align: center;
    position: relative;
    z-index: 2;
  }
  
  h2, h3, h4, h5, h6 {
    color: var(--c-lightNeutral);
    font-weight: $regular;
  }
  .homepage-hero__container {
    max-width: 963px;
    margin: 0 auto;
  }
  form {
    @include background-secondary;    
    padding: 32px;
    z-index: 1;
    border-radius: 3px;
    max-width: 700px;
    margin: 0;
    @include margin-bottom(3);
    p {
     color: $c-lightNeutral;
    }
  }
  .contact-form {
    margin: 0;
    display: block;
  }
  .homepage-hero__widgets {
    position: relative;
    z-index: 1;
  }
  @media all and (min-width: $screen-small) {
    .homepage-hero__content {
      @include padding-top(10);
      display: block;
      &:after {
        bottom: -30px;
      }
      h1 {
        max-width: 600px;
      }       
    }
    
  }
  @media all and (min-width:$screen-medium) {
    .cta-bar {
      text-align: left;
    }
    .homepage-hero__content {
      padding-left: 20px;
    }
  }
  @media all and (min-width:$screen-large) {
    
    .homepage-hero__container {
      margin: 0 auto;
      max-width: 1100px;
    }
  }
  @media all and (min-width:$large-tablet) {
    min-height: 515px;
    
    .homepage-hero__content {      
      padding-top: 100px;
      padding-left: 0;
      &:after {
        bottom: -105px;
      }   
      h1 {
        max-width: 700px;
        grid-column: 4/ 9;
      }    
    }
    .container {
      padding: 0;
    }
    .homepage-hero__widgets {
      max-width: 740px;
    }
  }
  // @media all and (min-width:$screen-xl) {
  //   .homepage-hero__container {
  //     max-width: 963px;
  //     margin: 0 auto;
  //   }
  // }
}
