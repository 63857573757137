.full-width {
  @include vertical-padding(6);
  @include margin-top(3);
  p {
    @include color-light-neutral;
  }
  h2, h3, h4, h5, h6 {
    @include color-light-neutral;
  }
  &__link {
    @include color-light-neutral;
    text-decoration: underline;
    text-align: center;
    display: block;
    @supports(display:grid) {
      grid-column: 1/-1;
    }    
  }
  img {
    width: auto;
    max-width: 100%;
  }
  .card {
    @include background-light-neutral;
    h3, h4, h5 {
      @include color-primary;
    }
    p {
      @include color-dark-neutral;
    }
  }
  .cta-bar {
    margin: 0;
  }
  & + .container {
    @include margin-top(6);
  }
  .contact-form.contact-form--campaign {
    margin-top: auto;
  }
  // .container + & {
  //   background: red;
  // }
  @media all and (min-width: $screen-extra-small) {
    @supports(display:grid) {
      .quote {
        grid-column: 3/11;
      }      
    }
  }  
    
}

// .container {
//   .full-width + & {
//     .contact-form.contact-form--campaign {
//       margin-top: auto;
//     }
//   }
// }