.card.card--news {
  // border-bottom: 10px solid $c-primary;
  padding: 0;
  position: relative;
  z-index: 0;
  min-height: 400px;
  padding-bottom: 10px;
  h2, h3, h4, h5, h6, p {
    text-decoration: none;
    transition: $basicHover;
  }
  .card__content {
    padding: 20px;
    z-index: 1;
  }
  .card__date {
    padding: 20px;
    p {
      width: auto;
    }
  }
  .card__image {
    max-height: 200px;
    overflow: hidden;
    width: 100%;
  }
  &:before {
    @include secondary-line-horizontal;
    background: linear-gradient(to top,$c-primaryAccent 0,$c-primaryAccent 30%,70%,$c-tertiaryAccent 70%,$c-tertiaryAccent 100%);
    height: 13px;
    bottom: 0;
    top: auto;
  }
  &:after {
    @include before-after;
    @include card-hover(scaleY(0), 50% 100%);
  }
  &:hover {
    @include color-light-neutral;
    h2, h3, h4, h5, p {
      @include color-light-neutral;
    }
    &:after {
      transform: scaleY(1);
    }
  }
  @media all and (min-width:$screen-extra-small){
    grid-column: span 6;
  }
}