.grid-masonry {
  grid-column: 1/-1;
  &__item {
    margin-bottom: 20px;
    width: 100%;
  }
  @media all and (min-width: $screen-medium) {
    &__item {
      width: 48%;
      margin-bottom: 20px;
      &:nth-child(odd) {
        margin-right: 2%;
      }
    }
  }
  @media all and (min-width: $large-tablet) {
    grid-column: 2/12;
    &__results {
      grid-column: 2/12!important;
    }
  }  
}