.hero.hero--image {
  min-height: 250px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to right, black, rgba(255,0,0,0));
    opacity: .6; 
    z-index: 0;
  }
  .hero__date p{
    color: $c-lightNeutral;
  }
  @media all and (min-width: $large-tablet) {
    height: 480px;
    .hero__container {
      min-height: 480px;
    }
  }
}