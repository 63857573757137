.expandable-panels.expandable-panels--faded {
  .expandable-panels__item {
    border-style: solid;
    border-width: 1px 1px 1px 10px;
    border-color: $c-primary;
    border-color: var(--c-primary);
    padding: 0;
  }
  .expandable-panels__image {
    margin-right: 20px;    
    img {
      max-width: 100px;
      max-height: 140px;
      object-fit: cover;
      @media all and (min-width:$screen-medium) {
        max-width: 200px;
      }
    }
  }
  .expandable-panels__content {
    max-height: 60px;
    padding: 20px;
  }
  .expandable-panels__top-container {
    border: 0;
    margin: 0;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid $light-grey;
    &::before{
      display: none;
    }
    h2 {
      margin-bottom: 10px;
    }
    p {
      @include color-primary;
      font-weight: $semi-bold;
    }
  }
  .expandable-panels__bottom-container {
		@include background-primary-accent;
		padding: 20px 0;
		position: relative;
		&:after {
			@include before-after;
			background: linear-gradient(to top, rgba(255,255,255,1) 44%,rgba(255,255,255,0) 100%);
			height: 140px;
			width: 100%;
			bottom: 0;
			left: 0;
			z-index: 0;
			transition: all 0.7s ease-in-out;
		}
  } 
  .expandable-panels__cross {
    margin: 0 auto;
    z-index: 1;
    @include background-primary;
    &:before, &:after {
      @include background-light-neutral;
    }
  }
  @media all and (min-width:$large-tablet) {
    grid-column: 2/12;
  }
}
.expandable-panels--open {
  .expandable-panels__bottom-container {
    padding-top: 20px;
    position: relative;
    &:after {
      height: 10px!important;
      transition: all 0.2s ease-in-out;
      opacity: 0;
    }
  }
}