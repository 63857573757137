.hero.hero--simple {
  @include background-light-grey;
  .bread-crumbs li, .bread-crumbs li:after, .bread-crumbs a, .hero__date p {
    @include color-primary;
  } 
  .hero__content {
    h1, p {
      @include color-primary;
    }
  }  
  &:before,&:after {
    display: none;
  }
  .hero__social-icons svg {
    circle {
      fill:$c-primary;
      fill:var(--c-primary);
    }
    path {
      fill:#fff;
    }
  }
  .hero__social-icons.social-icons ul {
    display: flex;
    align-items: center;   
    margin-top: 40px; 
    li {
      &:first-child {
        display: inline-block;
        margin-right: 16px;
      }
      p {
        margin-bottom: 0;
      }
      svg {
        max-width: 30px;
        &:hover {
          circle {
            fill:$c-primaryAccent;
            fill:var(--c-primary-accent);
          }
        }
      }
    }
  } 
  @media all and (min-width:$large-tablet) {
    .cta-bar__icon {
      margin: 0;
    }
  }
  
}