.opening-section {
  &__content-container {
    @include background-primary;
    position: relative;
    h2 {
      @include color-light-neutral;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 20px;
      @include color-light-neutral;
    }
    a {
      @include color-light-neutral;
    }
  }  
  &__content {
    padding: 40px 20px;
    a {
      text-decoration: underline;
      font-weight: $semi-bold;
      font-size: 18px;
    }
  }
  &__image-list {    
    margin: 0 auto;
    .image-list {
      &__item {
        padding: 32px 0;
      }
    }
  }
  .video-placeholder {
    margin: 0;
  }
  @media all and (min-width:$screen-large) {
    display: flex;
    flex-wrap: wrap;
    &__content-container {
      flex-basis: 48%;
      @supports(display:grid) {
        flex-basis: 50%;
      }
    }
    &__content {
      padding: 40px;
    }
    &__left-container {
      flex-basis: 52%;
      display: flex;
      flex-wrap: wrap-reverse;
      @supports(display:grid) {
        flex-basis: 50%;
      }
    }
    &__image-list {
      flex-basis: 100%;
    }
  }
  @media all and (min-width:$large-tablet){
    &__content-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__content {
      padding: 40px 0;
      max-width: 450px;
    }
  }
  @media all and (min-width:$screen-xl){
    
    .video-placeholder--background-image {
      height: 350px;
    }
  }
  @media all and (min-width:$screen-xxl){
    &__content-container {
      flex-basis: 40%;
    }
    &__left-container {
      flex-basis: 60%;
    }
    .video-placeholder--background-image {
      height: 400px;
    }
  }
  @media all and (min-width:2000px){
    &__content-container {
      flex-basis: 35%;
    }
    &__left-container {
      flex-basis: 65%;
    }
  }
}