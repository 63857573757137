.k-disallow--two-column-2-1 {
  .k-disallow-message--two-column-2-1 {
    display: block;
  }
  .k-section--two-column-2-1 {
    display: none;
  }
}
.k-section--two-column-2-1 {
  max-width: 1100px;
  .page-builder-txt {
    max-width: 700px;
  }
}