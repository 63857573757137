h1,h2,h3,h4,h5,h6,p,li,a,label,input, div, button, textarea{
  font-family:$font;
}
h1 {
  font-size: 32px;
  line-height: 44px;
  font-weight: $semi-bold;
  @include color-light-neutral;
  @include margin-bottom(2);
}
h2 {
  font-size: 28px;
  line-height: 40px;
  font-weight: $light;
  margin-bottom: 20px;
  @include primary-font-styling;
}
h3, h3 a {
  font-size: 24px;
  line-height: 34px;
  @include primary-font-styling;
  margin-bottom: 10px;
} 
h4 {
  font-size: 20px;
  line-height: 28px;
  @include primary-font-styling;
}
h5 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  @include primary-font-styling;
}
p {
  @include margin-bottom(0.5);
  line-height: 26px;
  font-size: 16px;
  @include color-dark-neutral;
}
a {
  font-size: 16px;
  @include color-primary;
  // text-decoration: underline;
}
li {
  margin-bottom: 5px;
}
@media all and (min-width: $large-tablet) {
  h1 {
    font-size: 48px;
    line-height: 64px;
  }
  h2 { 
    font-size: 38px;
    line-height: 50px;
  }
  h3, h3 a {
    font-size: 32px;
    line-height: 48px;
  }
  h4 {
    font-size: 28px;
    line-height: 40px;
  }
  h5 {
    font-size: 20px;
    line-height: 28px;
  }
}

.opening-text {
  @include color-primary;
  font-weight: $semi-bold;
  font-size: 18px;
  margin-bottom: 40px;
}