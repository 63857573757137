.card.card--download {
  padding: 0;
  position: relative;
  overflow: hidden;
  h2, h3, h4, h5, h6, p{
    @include color-light-neutral;
  }
  .card__content {
    @include background-primary;
    padding: 20px;
    z-index: 1;
    width: 100%;
    margin-top: 150px;
    display: flex;
    flex-wrap: wrap;
    .btn {
      width: auto!important;
      align-self: flex-end;
    }
    &:before {
      @include before-after;
      @include background-primary;
      border-radius: 50%;
      width: 550px;
      height: 300px;
      top: 34px;
      right: -28px;
      transform-origin: 50% 100%;
      transition-property: transform;
      transition-duration: .2s;
      transition-timing-function: ease-out;   
      z-index: -1;    
    }
    &:hover {
      &:before {
        transform: scaleY(1.8);
      }
    }
  }
  &:hover {
    .card__top-semi-circle {
      &:before {
        transform: scaleY(1.8);
      }
    }   
  }
  @media all and (min-width: $screen-xxs) {
    .card__top-semi-circle {
      &:before {
        width: 650px;
        height: 400px;
        right: -65px;
      }
    }
  }
  @media all and (min-width: $screen-extra-small){
    .card__top-semi-circle {
      &:before {
        width: 1000px;
        height: 500px;
        right: -133px;
      }
    }    
  }
  @media all and (min-width: $screen-medium){
    .card__top-semi-circle {
      &:before {
        width: 550px;
        height: 300px;
        right: -28px;
      }
    }    
  }
}