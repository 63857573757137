.grid.grid--two-column-1-1 {
  display: flex;
  flex-wrap: wrap;

  @supports(display: grid){
    display: grid;
    grid-template-columns: repeat(2, 6fr);
  }

  
  .grid__item {

    //flex fallback
    flex-basis: calc((100% /2) - 20px);

    @supports(display:grid){
      grid-column: span 1;
    }
  }

  //single column on small screens
  @media screen and (max-width: $screen-small) {
    grid-template-columns: 12fr;

    //flex fallback
    flex-basis: 100%;

    //strip bottom margins from inputs to prevent double spacing due to row-gap
    //with the exception of the last one in the grid
    //can't just remove row-gap, as we only want this to apply when the children are inputs
    .grid__item:not(:last-child) {
      .form-field{
        .form-control {
        	margin-bottom: 0;
        }
      }
    }
  }


}