.homepage-services {
  @include padding-bottom(4);
  &__content {
    @include margin-top(6);
    p {
      margin-bottom: 20px;
      max-width: 500px;
    }
    a {
      text-decoration: underline;
    }
  } 
  tbody tr td {  
    display: block;   
    margin-bottom: 10px;
  } 
  div.cqc-widget {
    border-color: $c-primary!important;
    border-color: var(--c-primary)!important;
  }
  @media all and (min-width:$screen-xl) {
    margin: 0 auto;
    p {
      max-width: 700px;
    }
  }
}