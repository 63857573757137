.testimonial {
  @include background-primary;
  // grid-column: 1 / -1;
  &:nth-child(2n+1) {
    @include background-primary-accent;
  }
  &__content-container {
    position: relative;
  }
  &__content {
    padding: 35px 40px;
    @include color-light-neutral;
    p {
      @include color-light-neutral;
    }
  }
  &__quote-mark {
    position: absolute;
    width: 100%;
    max-width: 20px;
    &--left {
      top: 15px;
      left: 15px;
    }
    &--right {
      bottom: 15px;
      right: 15px;
    }
    svg {
      path {
        mask: none;
      }
    }
  }  
  &__author {   
    font-weight: $semi-bold;
    font-size: 20px;      
  }
  &__image-container {    
    height: 200px; 
  }
  .video-placeholder {
    margin: 0;
  }
  @media all and (min-width:$screen-extra-small){
    &__image-container {
      height: 300px; 
    }
    &__quote-mark {
      max-width: 30px;
    }
  }
  @media all and (min-width:$screen-small){
    &__content {
      padding: 40px 55px;
    }
  }
  @media all and (min-width: $screen-medium) {
    flex-basis: 48%;
    margin-right: 2%;
    &:last-child {
      margin-right: 0;
    }
    @supports(display:grid) {
      grid-column: span 6;
      margin-right: 0;
    }
  }
  @media all and (min-width: $screen-xl) {
    &__image-container {
      height: 350px;
    }
  }
} 