.franchise-contact-us {
  .full-width {
    margin-top: 0;
  }
  .card-container--small {
    max-width: 900px;
    margin: 0 auto;
    // .card {
    //   flex-basis: 32%;
    // }
  }
  
  @media all and (min-width:$large-tablet) {
    .rich-text {
      grid-column: 2/12;
      padding: 0 10px;
      max-width: 100%;
    }
    .card-container--small {
      .card {
        flex-basis: 33%;
      }
    }
  }
  @media all and (min-width:$screen-xl) {
    .rich-text {
      grid-column: 3/11;
    }
  }
} 