.icon-circle{
  @include background-primary-accent;
  margin: 0 auto;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; 
  i {
    font-size: 18px;
    @include color-light-neutral;
  }
  svg {
    position: absolute;
    max-width: 35px;
    top: 0;
    left: 35%;
    @supports(display:grid) {
      top: auto;
      left: auto;
    }
  }
  @media all and (min-width:$large-tablet) {
    height: 100px;
    width: 100px;
    i {
      font-size: 32px;
    }
  }
}