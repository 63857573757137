.bread-crumbs {
  li {
    display: inline-block;
    @include margin-right(0.5);
    font-size: 12px;
    &:after {
      content: "/";
      display: inline-block;
      @include color-light-neutral;
      @include padding-left(0.5);
      
    }
    &:last-of-type {
      &:after {
        display: none;
      }      
    }    
  }
  &__prev {
    text-decoration: underline;
  }
  a {
    @include color-light-neutral;
    font-size: 12px;
  }
  @media all and (min-width: $large-tablet) {
    li, a {
      font-size: 14px;
    }
  }
}