.card.card--vacancies {
  padding: 0;
  grid-column: 1 / -1;
  .card__vacancies-top {
    border-bottom: 1px solid $light-grey;
    padding: 20px;
    width: 100%;
    h3 {
      margin-bottom: 10px;
      cursor: pointer;
    }
    p {
      margin-bottom: 0;
    }
  }
  .card__vacancies-bottom {
    padding: 20px;
  }
  .card__vacancies-info {
    margin-bottom: 20px;
    line-height: 24px;
    ul {
      li {
        @include color-primary;
        font-weight: $semi-bold;
        font-size: 18px;
        margin-right: 10px;
        margin-bottom: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .card__vacancies-summary {
    //display: none;
    margin-bottom: 5px;
    line-height: 26px;
    font-size: 16px;
    color: $c-darkNeutral;
    color: var(--c-dark-neutral);
  }

  .card__vacancies-read-more {
    @include color-primary;
    margin-top: 15px;
    display: block;
    cursor: pointer;
  }

  @media all and (min-width: $screen-xxs) {
    .card__vacancies-summary {
      display: block;
    }
  }
  @media all and (min-width: $screen-small) {
    .card__vacancies-info {
      ul {
        li {
          display: inline-block;
        }
      }
    }
  }
}