.error {
  .footer__container {
    display: block;
  }
  
  @media all and (min-width:$screen-small) {
    .footer__item-grid {
      display: block;
    }
    .footer__logos {
      text-align: center;
    }
    .footer__buttons {
      display: block;
    }
  }
  @media all and (min-width:$screen-medium){
    .footer .btn.btn--full-width {
      display: inline-block;
    }
  }
  @media all and (min-width:$large-tablet){
    .footer__container {
      display: flex;
    }
    .footer__item:first-child {
      flex-basis: 35%;
    }
    .footer__logos {
      text-align: left;
      .bluebird-logo {
        display: inline-block;
      }
    }
    .footer__item-grid {
      text-align: right;
    }
    .footer__item-large {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}