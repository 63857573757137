.homepage-hero {  
  @include color-light-neutral;
  height: 560px;
  overflow: hidden;
  position: relative; 
  &:before {
    @include before-after;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to right, black, rgba(255, 0, 0, 0));
    opacity: .6;
    z-index: 1;
  }
  &__sub-section {
    margin-top: 32px;    
  }
  &__content {   
    padding-top: 80px;
    position: relative;     
    z-index: 1;    
    text-align: center;
    max-width: 350px;
    margin: 0 auto;
    h1 {
      font-weight: $light;
      font-size: 34px;
      margin-bottom: 8px;
    }
    h2 {
      @include color-light-neutral;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    p {
      @include color-light-neutral;
    }
  }  
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;    
  }
  @media all and (min-width:$screen-extra-small) {
    .input-button input[type="text"] {
      min-width: 248px;
      width: 77%;
    }
  }
  @media all and (min-width:$screen-medium) {
    height: 600px;
    &__content {
      @include structure-grid(0, 32px);
      max-width: 100%;
      margin: auto;
      text-align: left;
      padding-top: 200px;
      h1 {
        font-size: 48px;
      }
    }
    &__title {
      @include two-column;
    }
    &__section {
      @include two-column;
    }
    &__sub-section {
      &:first-child {
        padding-bottom: 32px;
        border-bottom: 1px solid #fff;
      }
    }
  }  
  @media all and (min-width:$screen-xl){
    &__title {
      @include three-column;
    }
    &__section {
      grid-column: 6/13;
      display: flex;
      align-items: center;
    }
    &__sub-section {
      margin-left: 64px;
      margin-top: 0;
      &:first-child {
        padding-bottom: 0;
        border-bottom: none;
        // border-right: 1px solid #fff;
        padding-right: 64px;
        margin-left: 0;
        position: relative;
        &:after {
          @include before-after;
          width: 1px;
          background-color: #fff;
          height: 200px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .input-button input[type="text"] {
      margin-right: 16px;
      min-width: 235px;
      width: 73%;
    }
  }
}