.card-container.card-container--small {
  max-width: 850px;
  margin: 0 auto;
  @include vertical-margin(3);
  // .card {
  //   flex-basis: 100%;
  //   margin-bottom: 10px;
  // }
  
  @supports(display:grid) {
    margin: 30px 0;
  }
  @media all and (min-width: $screen-extra-small) {
    @supports(display:grid) {
      grid-column: 2/12;
    }
  }
  @media all and (min-width:$screen-medium){
    .card {
      flex-basis: 39%;
      @supports(display:grid) {
        flex-basis: 42%;
      }
    }
    .card--icon-small {
      flex-basis: 36%;
    }
  }
  
  @media all and (min-width:$large-tablet){
    
    .card--icon-small {
      @include horizontal-padding(3);
    }
  }
  @media all and (min-width:$screen-xl){
    @supports(display:grid) {
      grid-column: 3/11;
    }
    .card--icon-small {
      @include horizontal-padding(7);
    }
  }
  
}