.container.container--small {
  @media all and (min-width: $screen-extra-small) {
    @supports(display:grid) {
      grid-column: 3/11;
    }
  }
  @media all and (min-width:$screen-medium){
    .card {
      flex-basis: 45%;
    }
    .card--icon-small {
      flex-basis: 36%;
    }
  }
  
  @media all and (min-width:$large-tablet){
    
    .card--icon-small {
      @include horizontal-padding(3);
    }
  }
  @media all and (min-width:$screen-xl){
    .card--icon-small {
      @include horizontal-padding(7);
    }
  }
}