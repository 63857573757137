img {
  width: 100%; }

img {
  width: 100%; }

:root {
  --c-primary: #008bcb;
  --c-secondary: #003a78;
  --c-primary-accent: #F39200;
  --c-secondary-accent: #F39200;
  --c-tertiaryAccent: #F9D900;
  --c-primaryAccentLight: #F8A61C;
  --c-dark-neutral: #000;
  --c-mid-neutral: #787a7b;
  --c-light-neutral: #fff;
  --mid-grey: #c7c7c7;
  --light-grey: #f5f5f5;
  --red: #D32D27;
  --error-red: #f33f3f;
  --primary-header-colour: --primary; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html {
  scroll-behavior: smooth; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, .how-to__steps-item h5 a, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }
  body section {
    padding: 0; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

.image-list {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  /* three items */ }
  .image-list li {
    display: inline-block;
    margin-right: 10px; }
    .image-list li:last-child {
      margin-right: 0; }
    .image-list li img {
      max-height: 100px;
      width: auto;
      max-width: 100%; }
  @media all and (min-width: 1024px) {
    .image-list li {
      margin-right: 20px; } }

@supports (display: grid) {
  .image-list.image-list--grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px; } }

.image-list.image-list--grid img {
  max-height: 150px;
  object-fit: cover; }

@media all and (min-width: 640px) {
  .image-list.image-list--grid img {
    max-height: 200px; } }

@supports (display: grid) {
  .image-list.image-list--grid-three {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px; } }

.image-list.image-list--grid-three img {
  max-height: 80px; }

@media all and (min-width: 550px) {
  .image-list.image-list--grid-three img {
    max-height: 140px; } }

@media all and (min-width: 1024px) {
  .image-list.image-list--grid-three img {
    max-height: 120px; } }

@media all and (min-width: 1200px) {
  .image-list.image-list--grid-three img {
    max-height: 150px; } }

.input-button input, .input-button input[type="text"] {
  width: auto;
  padding: 12px;
  min-width: 205px;
  border: none;
  margin-right: 8px;
  margin-bottom: 0; }

.input-button__container {
  display: flex;
  flex-wrap: wrap; }

.input-button__button {
  display: inline; }

@media all and (min-width: 640px) {
  .input-button input {
    padding: 13px; } }

.btn, input[type=submit] {
  background-image: linear-gradient(0deg, #FFBB48 0%, #FFBB48 100%);
  background-size: 100%;
  position: relative;
  color: #fff;
  color: var(--c-light-neutral);
  font-size: 16px;
  border-radius: 3px;
  padding: 12px 20px;
  font-weight: 400;
  transition: all 0.3s ease-in;
  text-decoration: none !important;
  display: inline-block;
  z-index: 2;
  cursor: pointer; }
  .btn:before, input[type=submit]:before {
    background-image: linear-gradient(0deg, #F39200 0%, #FFBB48 100%);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    z-index: -1;
    transition: opacity 0.3s ease-in;
    border-radius: 3px; }
  .btn:hover:before, input[type=submit]:hover:before {
    opacity: 0; }

.btn.btn--white, input.btn--white[type=submit] {
  background-color: #fff;
  background-color: var(--c-light-neutral);
  color: #008bcb;
  color: var(--c-primary); }
  .btn.btn--white:hover, input.btn--white:hover[type=submit] {
    background-color: #008bcb;
    background-color: var(--c-primary);
    color: #fff;
    color: var(--c-light-neutral); }

.btn.btn--large, input.btn--large[type=submit] {
  font-size: 16px; }
  @media all and (min-width: 1024px) {
    .btn.btn--large, input.btn--large[type=submit] {
      font-size: 20px; } }

.btn.btn--full-width, input.btn--full-width[type=submit] {
  display: block; }
  @media all and (min-width: 768px) {
    .btn.btn--full-width, input.btn--full-width[type=submit] {
      display: inline-block; } }

.btn.btn--card, input.btn--card[type=submit] {
  display: block;
  text-align: center;
  align-self: flex-end;
  flex-basis: 100%; }

.btn.btn--light:before, input.btn--light[type=submit]:before {
  background-image: linear-gradient(0deg, #FFBB48 35%, #FFCE7C 100%); }

.btn.btn--apply, input.btn--apply[type=submit] {
  width: 100%;
  line-height: 24px; }

.card {
  border-color: #008bcb;
  border-color: var(--c-primary);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 3px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  transition: 0.3s ease;
  box-shadow: 0 0 17px -5px rgba(0, 0, 0, 0.23);
  margin-top: 15px;
  flex-basis: 100%;
  margin-bottom: 10px; }
  @supports (display: grid) {
    .card {
      grid-column: 1 / -1;
      margin-top: 0;
      margin-bottom: 0; } }
  .card p {
    margin-bottom: 20px;
    flex-basis: 100%; }
  .card a[href^="tel:"] {
    color: #008bcb;
    color: var(--c-primary);
    text-decoration: none;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 10px;
    display: block;
    flex-basis: 100%; }
  .card a[href^="mailto:"] {
    margin-bottom: 10px;
    display: block;
    font-weight: 600;
    font-size: 18px;
    word-break: break-all; }
  .card h3, .card h4, .card h5, .card .how-to__steps-item h5 a, .how-to__steps-item h5 .card a {
    margin-bottom: 20px;
    flex-basis: 100%; }
  .card form {
    width: 100%; }
  .card button {
    width: 100%;
    padding: 15px 30px;
    background-color: #F39200;
    background-color: var(--c-primary-accent); }
  .card__miles {
    display: flex;
    flex-basis: 100%;
    align-items: baseline;
    margin-bottom: 20px; }
    .card__miles p {
      flex-basis: auto;
      margin-right: 20px;
      width: auto !important; }
    .card__miles a {
      font-size: 18px;
      font-weight: 600;
      width: auto !important; }
  .card__opening-times {
    margin: 0 auto;
    text-align: left; }
    .card__opening-times p {
      margin-bottom: 0; }
  .card__content {
    width: 100%; }
  .card__date {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: flex-end; }
    .card__date svg {
      width: 100%;
      max-width: 30px; }
    .card__date p {
      font-weight: 700;
      font-size: 18px;
      flex-basis: auto;
      margin-bottom: 0;
      margin-left: 10px;
      color: #000;
      color: var(--c-dark-neutral); }
  .card .local-office-cta__inputs {
    display: block; }
    .card .local-office-cta__inputs input {
      margin-right: 0;
      width: 90%;
      margin-bottom: 15px; }
  .card .page-builder-txt {
    margin-top: 0; }
  .card .container {
    padding: 0; }
  .card .grid {
    display: block; }
  @media all and (min-width: 768px) {
    .card {
      flex-basis: 44%;
      margin-right: auto;
      margin-bottom: 15px; }
      @supports (display: grid) {
        .card {
          grid-column: span 6;
          margin-right: 0;
          margin-bottom: 0; } }
      .card:last-child {
        margin-right: 0; }
      .card a[href^="tel:"] {
        font-size: 32px; }
      .card h3 {
        font-size: 29px;
        line-height: 38px; } }

.card-popup {
  padding: 0; }
  .card-popup__content {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px; }
  .card-popup__icon {
    background-color: #f5f5f5;
    background-color: var(--light-grey);
    cursor: pointer;
    padding: 10px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px; }
    .card-popup__icon svg {
      width: 100%;
      max-width: 30px; }
      .card-popup__icon svg circle {
        transition: 0.3s ease; }
    .card-popup__icon:hover > svg circle {
      fill: #F39200;
      fill: var(--c-primary-accent); }
  @media all and (min-width: 768px) {
    .card-popup__content {
      flex-basis: 72%; }
    .card-popup__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 15%; }
    @supports (display: grid) {
      .card-popup__content {
        flex-basis: 85%; }
      .card-popup__icon {
        flex-basis: 15%; } } }
  @media all and (max-width: 900px) {
    .card-popup__icon {
      width: 100%;
      top: auto; } }

.card.card--community {
  min-height: 0 !important; }
  .card.card--community .card__content {
    border-top: 1px solid #008bcb;
    display: flex;
    flex-wrap: wrap; }
    .card.card--community .card__content p {
      text-decoration: underline;
      align-self: flex-end;
      color: #F39200;
      color: var(--c-primary-accent); }
  .card.card--community .card__image {
    background-color: #fff;
    background-color: var(--c-light-neutral);
    padding: 15px; }

.card.card--vacancies {
  padding: 0;
  grid-column: 1 / -1; }
  .card.card--vacancies .card__vacancies-top {
    border-bottom: 1px solid #f5f5f5;
    padding: 20px;
    width: 100%; }
    .card.card--vacancies .card__vacancies-top h3 {
      margin-bottom: 10px;
      cursor: pointer; }
    .card.card--vacancies .card__vacancies-top p {
      margin-bottom: 0; }
  .card.card--vacancies .card__vacancies-bottom {
    padding: 20px; }
  .card.card--vacancies .card__vacancies-info {
    margin-bottom: 20px;
    line-height: 24px; }
    .card.card--vacancies .card__vacancies-info ul li {
      color: #008bcb;
      color: var(--c-primary);
      font-weight: 600;
      font-size: 18px;
      margin-right: 10px;
      margin-bottom: 15px; }
      .card.card--vacancies .card__vacancies-info ul li:last-child {
        margin-right: 0; }
  .card.card--vacancies .card__vacancies-summary {
    margin-bottom: 5px;
    line-height: 26px;
    font-size: 16px;
    color: #000;
    color: var(--c-dark-neutral); }
  .card.card--vacancies .card__vacancies-read-more {
    color: #008bcb;
    color: var(--c-primary);
    margin-top: 15px;
    display: block;
    cursor: pointer; }
  @media all and (min-width: 440px) {
    .card.card--vacancies .card__vacancies-summary {
      display: block; } }
  @media all and (min-width: 640px) {
    .card.card--vacancies .card__vacancies-info ul li {
      display: inline-block; } }

.card.card--detailed {
  position: relative;
  padding-left: 28px; }
  .card.card--detailed:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #F9D900;
    height: 100%;
    width: 5px;
    bottom: 0;
    left: 0;
    left: 9px; }
  .card.card--detailed:before {
    content: "";
    display: block;
    position: absolute;
    background-color: #F39200;
    background-color: var(--c-primary-accent);
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scaleX(0.03);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.25s;
    transition-timing-function: ease-out; }
  .card.card--detailed p {
    margin-bottom: 5px; }
  .card.card--detailed a {
    text-decoration: underline; }
  .card.card--detailed h5 a, .how-to__steps-item h5 .card.card--detailed a a {
    text-decoration: none;
    color: #008bcb;
    font-size: 16px;
    line-height: 24px; }
  .card.card--detailed .link-arrow:after {
    background-color: #008bcb;
    background-color: var(--c-primary);
    color: #fff;
    color: var(--c-light-neutral); }
  .card.card--detailed a[href^="tel:"] {
    font-size: 20px;
    text-decoration: none; }
  @media all and (min-width: 550px) {
    .card.card--detailed:after {
      left: 10px; } }
  @media all and (min-width: 640px) {
    .card.card--detailed:after {
      left: 12px; } }
  @media all and (min-width: 768px) {
    .card.card--detailed:after {
      left: 9px; } }
  @media all and (min-width: 1024px) {
    .card.card--detailed h5 a, .how-to__steps-item h5 .card.card--detailed a a {
      font-size: 20px;
      line-height: 28px; } }

.card.card--detail-button {
  color: #008bcb;
  color: var(--c-primary);
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  transition: 0.3s all;
  grid-column: 1/-1;
  padding: 20px 50px 20px 26px;
  line-height: 22px; }
  .card.card--detail-button:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #008bcb;
    background-color: var(--c-primary);
    transform: scaleX(0.03);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.25s;
    transition-timing-function: ease-out; }
  .card.card--detail-button:after {
    left: 0;
    width: 13px;
    background: linear-gradient(to right, #F39200 0, #F39200 30%, 70%, #F9D900 70%, #F9D900 100%); }
  .card.card--detail-button span {
    position: absolute;
    top: 18px;
    right: 15px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #008bcb;
    background-color: var(--c-primary);
    transition: 0.5s 0.2s all; }
    .card.card--detail-button span:after {
      content: "";
      display: block;
      position: absolute;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      width: 7px;
      height: 7px;
      transform: rotate(45deg);
      display: block;
      transition: all .2s ease;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      top: 8px;
      left: 6px;
      transition: 0.5s 0.2s all; }
  .card.card--detail-button:hover {
    color: #fff;
    color: var(--c-light-neutral); }
    .card.card--detail-button:hover:before {
      transform: scaleX(1); }
    .card.card--detail-button:hover > span {
      background-color: #fff;
      background-color: var(--c-light-neutral); }
      .card.card--detail-button:hover > span:after {
        border-top: 2px solid #008bcb;
        border-right: 2px solid #008bcb; }
  @media all and (min-width: 768px) {
    .card.card--detail-button {
      grid-column: span 6; } }

.card.card--icon {
  text-align: center;
  display: block; }
  .card.card--icon .card__icon {
    max-width: 140px;
    margin: 0 auto;
    margin-bottom: 20px; }
  @media all and (min-width: 640px) {
    .card.card--icon {
      grid-column: span 6; } }
  @media all and (min-width: 768px) {
    .card.card--icon {
      grid-column: span 4; } }

.card.card--icon-small {
  padding: 30px;
  position: relative; }
  .card.card--icon-small .card__icon {
    max-width: 90px; }
  .card.card--icon-small a[href^="mailto:"] {
    position: absolute;
    left: 0;
    padding: 0 10px;
    font-size: 16px; }
  .card.card--icon-small a[href^="tel:"] {
    font-size: 22px; }
  @media all and (min-width: 440px) {
    .card.card--icon-small {
      padding: 30px 70px; } }
  @media all and (min-width: 768px) {
    .card.card--icon-small {
      padding: 30px 20px;
      grid-column: span 6; }
      .card.card--icon-small p {
        font-size: 18px; }
      .card.card--icon-small a[href^="tel:"] {
        font-size: 26px; } }
  @media all and (min-width: 1024px) {
    .card.card--icon-small {
      padding: 30px 70px; } }

.card-container.card-container--small .card.card--icon-medium {
  padding: 30px; }
  @media all and (min-width: 440px) {
    .card-container.card-container--small .card.card--icon-medium {
      padding-left: 40px;
      padding-right: 40px; } }
  @media all and (min-width: 1024px) {
    .card-container.card-container--small .card.card--icon-medium {
      padding-left: 60px;
      padding-right: 60px; } }

.card.card--news {
  padding: 0;
  position: relative;
  z-index: 0;
  min-height: 400px;
  padding-bottom: 10px; }
  .card.card--news h2, .card.card--news h3, .card.card--news h4, .card.card--news h5, .card.card--news .how-to__steps-item h5 a, .how-to__steps-item h5 .card.card--news a, .card.card--news h6, .card.card--news p {
    text-decoration: none;
    transition: 0.3s ease; }
  .card.card--news .card__content {
    padding: 20px;
    z-index: 1; }
  .card.card--news .card__date {
    padding: 20px; }
    .card.card--news .card__date p {
      width: auto; }
  .card.card--news .card__image {
    max-height: 200px;
    overflow: hidden;
    width: 100%; }
  .card.card--news:before {
    content: "";
    display: block;
    position: absolute;
    background-color: #F9D900;
    height: 5px;
    width: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(to top, #F39200 0, #F39200 30%, 70%, #F9D900 70%, #F9D900 100%);
    height: 13px;
    bottom: 0;
    top: auto; }
  .card.card--news:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #008bcb;
    background-color: var(--c-primary);
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition-property: transform;
    transition-duration: 0.25s;
    transition-timing-function: ease-out; }
  .card.card--news:hover {
    color: #fff;
    color: var(--c-light-neutral); }
    .card.card--news:hover h2, .card.card--news:hover h3, .card.card--news:hover h4, .card.card--news:hover h5, .card.card--news:hover .how-to__steps-item h5 a, .how-to__steps-item h5 .card.card--news:hover a, .card.card--news:hover p {
      color: #fff;
      color: var(--c-light-neutral); }
    .card.card--news:hover:after {
      transform: scaleY(1); }
  @media all and (min-width: 550px) {
    .card.card--news {
      grid-column: span 6; } }

.card.card--download {
  padding: 0;
  position: relative;
  overflow: hidden; }
  .card.card--download h2, .card.card--download h3, .card.card--download h4, .card.card--download h5, .card.card--download .how-to__steps-item h5 a, .how-to__steps-item h5 .card.card--download a, .card.card--download h6, .card.card--download p {
    color: #fff;
    color: var(--c-light-neutral); }
  .card.card--download .card__content {
    background-color: #008bcb;
    background-color: var(--c-primary);
    padding: 20px;
    z-index: 1;
    width: 100%;
    margin-top: 150px;
    display: flex;
    flex-wrap: wrap; }
    .card.card--download .card__content .btn, .card.card--download .card__content input[type=submit] {
      width: auto !important;
      align-self: flex-end; }
    .card.card--download .card__content:before {
      content: "";
      display: block;
      position: absolute;
      background-color: #008bcb;
      background-color: var(--c-primary);
      border-radius: 50%;
      width: 550px;
      height: 300px;
      top: 34px;
      right: -28px;
      transform-origin: 50% 100%;
      transition-property: transform;
      transition-duration: .2s;
      transition-timing-function: ease-out;
      z-index: -1; }
    .card.card--download .card__content:hover:before {
      transform: scaleY(1.8); }
  .card.card--download:hover .card__top-semi-circle:before {
    transform: scaleY(1.8); }
  @media all and (min-width: 440px) {
    .card.card--download .card__top-semi-circle:before {
      width: 650px;
      height: 400px;
      right: -65px; } }
  @media all and (min-width: 550px) {
    .card.card--download .card__top-semi-circle:before {
      width: 1000px;
      height: 500px;
      right: -133px; } }
  @media all and (min-width: 768px) {
    .card.card--download .card__top-semi-circle:before {
      width: 550px;
      height: 300px;
      right: -28px; } }

.card.card--download-image .card__content {
  background-color: rgba(0, 139, 203, 0.82) !important;
  background: transparent; }
  .card.card--download-image .card__content::before {
    opacity: 0.8; }

.card.card--news-background-image {
  border-color: #003a78;
  border-color: var(--secondary); }
  .card.card--news-background-image:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #008bcb;
    background-color: var(--c-primary);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
    opacity: 0.6;
    transition: 0.3s ease; }
  .card.card--news-background-image h2, .card.card--news-background-image h3, .card.card--news-background-image h4, .card.card--news-background-image h5, .card.card--news-background-image .how-to__steps-item h5 a, .how-to__steps-item h5 .card.card--news-background-image a, .card.card--news-background-image h6, .card.card--news-background-image p {
    color: #fff;
    color: var(--c-light-neutral);
    transition: 0.3s ease; }
  .card.card--news-background-image .card__image--background {
    position: absolute;
    max-height: 100%;
    z-index: -1; }
    .card.card--news-background-image .card__image--background:after {
      content: "";
      display: block;
      position: absolute;
      background-color: #008bcb;
      background-color: var(--c-primary);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.6;
      transition: 0.3s ease; }
  .card.card--news-background-image .card__date svg circle {
    fill: #fff;
    fill: var(--c-light-neutral);
    transition: 0.3s ease; }
  .card.card--news-background-image .card__date svg path {
    fill: #008bcb;
    fill: var(--c-primary);
    transition: 0.3s ease; }
  .card.card--news-background-image:hover:after {
    transform: none;
    background-color: #fff;
    background-color: var(--c-light-neutral); }
  .card.card--news-background-image:hover .card__image--background:after {
    background-color: #fff;
    background-color: var(--c-light-neutral); }
  .card.card--news-background-image:hover h2, .card.card--news-background-image:hover h3, .card.card--news-background-image:hover h4, .card.card--news-background-image:hover h5, .card.card--news-background-image:hover .how-to__steps-item h5 a, .how-to__steps-item h5 .card.card--news-background-image:hover a, .card.card--news-background-image:hover h6 {
    color: #008bcb;
    color: var(--c-primary); }
  .card.card--news-background-image:hover p {
    color: #000;
    color: var(--c-dark-neutral); }
  .card.card--news-background-image:hover .card__date svg circle {
    fill: #008bcb;
    fill: var(--c-primary); }
  .card.card--news-background-image:hover .card__date svg path {
    fill: #fff;
    fill: var(--c-light-neutral); }

.campaign-img {
  text-align: right; }

.campaign-txt {
  margin-top: 60px;
  grid-column: 1/-1; }
  @media all and (min-width: 640px) {
    .campaign-txt {
      grid-column: 2/12; } }
  @media all and (min-width: 1024px) {
    .campaign-txt {
      grid-column: 3/11; } }

.contact-form {
  position: relative;
  max-width: 650px;
  margin: 0 auto; }
  @supports (display: grid) {
    .contact-form {
      margin: auto;
      grid-column: 1/-1; } }
  .contact-form fieldset {
    min-width: 0; }
  .contact-form textarea {
    min-height: 100px; }
  .contact-form h2, .contact-form h3, .contact-form h4, .contact-form h5, .contact-form .how-to__steps-item h5 a, .how-to__steps-item h5 .contact-form a, .contact-form p, .contact-form label, .contact-form div, .contact-form a {
    color: #fff;
    color: var(--c-light-neutral); }
  .contact-form button {
    width: 100%; }
  .contact-form .field-validation-error {
    font-size: 14px;
    margin: 5px 0; }
  .contact-form__phone-number {
    text-decoration: underline; }
  .contact-form .grid {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @supports (display: grid) {
      .contact-form .grid {
        display: grid; } }
    .contact-form .grid__item {
      flex-basis: 48%; }
      .contact-form .grid__item .ktc-checkbox label {
        font-size: 14px;
        line-height: 17px;
        margin: 0 15px 10px 0; }
        .contact-form .grid__item .ktc-checkbox label:before {
          z-index: 2; }
        .contact-form .grid__item .ktc-checkbox label:after {
          z-index: 3; }
      .contact-form .grid__item .explanation-text {
        position: relative;
        padding-left: 40px;
        top: -15px; }
    .contact-form .grid.grid--two-column-1-1 {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); }
  .contact-form input[type="text"], .contact-form input[type="email"], .contact-form textarea {
    margin-bottom: 0; }
  .contact-form .ktc-default-section {
    margin-bottom: 20px; }
  .contact-form .input-validation-error {
    border-color: #f33f3f;
    border-color: var(--error-red);
    border-width: 2px; }
    .contact-form .input-validation-error:focus {
      border-color: #f33f3f;
      border-color: var(--error-red); }
  .contact-form .field-validation-error {
    color: #f33f3f;
    color: var(--error-red);
    font-weight: 600; }
  .contact-form .loader-overlay {
    background-color: #003a78;
    background-color: var(--c-secondary); }
  .contact-form .formwidget-submit-text {
    position: absolute;
    background-color: #003a78;
    background-color: var(--c-secondary);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; }
    .contact-form .formwidget-submit-text p {
      font-size: 28px; }
  .contact-form__text {
    margin-bottom: 20px; }
  @media all and (min-width: 440px) {
    @supports (display: grid) {
      .contact-form {
        grid-column: 2/12; } } }
  @media all and (min-width: 640px) {
    @supports (display: grid) {
      .contact-form {
        grid-column: 3/11; } } }
  @media all and (min-width: 1024px) {
    @supports (display: grid) {
      .contact-form .grid__item {
        grid-column: span 6; } }
    .contact-form .grid__item iframe {
      right: 0;
      position: absolute; } }
  @media all and (min-width: 1200px) {
    @supports (display: grid) {
      .contact-form {
        grid-column: 4/10; } } }

@media screen and (max-width: 440px) {
  .contact-form .grid.grid--two-column-1-1 {
    grid-template-columns: 1fr; } }

.field-validation-error {
  color: #f33f3f;
  color: var(--error-red);
  font-weight: 600; }

.input-validation-error {
  border-color: #f33f3f;
  border-color: var(--error-red);
  border-width: 2px; }
  .input-validation-error:focus {
    border-color: #f33f3f;
    border-color: var(--error-red); }

.contact-form.contact-form--campaign {
  background-color: #003a78;
  background-color: var(--c-secondary);
  padding: 40px;
  z-index: 2;
  grid-column: 1/-1;
  text-align: left;
  border-radius: 3px; }
  @supports (display: grid) {
    .contact-form.contact-form--campaign {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 16px; }
      .contact-form.contact-form--campaign h3 {
        margin-bottom: 0; }
      .contact-form.contact-form--campaign input {
        margin-top: 5px; } }
  .contact-form.contact-form--campaign input[type="radio"] ~ label,
  .contact-form.contact-form--campaign input[type="checkbox"] ~ label {
    box-sizing: border-box;
    font-size: 16px;
    position: relative; }
    .contact-form.contact-form--campaign input[type="radio"] ~ label::before,
    .contact-form.contact-form--campaign input[type="checkbox"] ~ label::before {
      left: 0;
      top: 2px; }
  .contact-form.contact-form--campaign .contact-form__button-wrapper .btn, .contact-form.contact-form--campaign .contact-form__button-wrapper input[type=submit] {
    top: auto; }
  .contact-form.contact-form--campaign .ktc-default-section {
    margin-top: 15px; }
    .contact-form.contact-form--campaign .ktc-default-section div {
      margin-bottom: 15px; }
      .contact-form.contact-form--campaign .ktc-default-section div:last-child {
        margin-bottom: 0; }
  @media all and (min-width: 640px) {
    .contact-form.contact-form--campaign {
      margin: 0 auto; }
      .contact-form.contact-form--campaign .formwidget-submit-text {
        position: relative;
        max-width: 650px;
        height: 200px; } }
  @media all and (min-width: 768px) {
    .contact-form.contact-form--campaign {
      max-width: 700px; }
      .contact-form.contact-form--campaign .grid__item {
        grid-column: span 6; }
      .contact-form.contact-form--campaign .formwidget-submit-text {
        max-width: 700px; } }
  @media all and (min-width: 900px) {
    .contact-form.contact-form--campaign {
      max-width: 800px;
      padding: 40px 80px; }
      .contact-form.contact-form--campaign .formwidget-submit-text {
        max-width: 800px; }
      .grid--two-column-2-1 .contact-form.contact-form--campaign {
        padding-left: 40px;
        padding-right: 40px; } }
  @media all and (min-width: 1024px) {
    .contact-form.contact-form--campaign .formwidget-submit-text {
      height: 400px; } }

.cta-bar {
  text-align: center;
  grid-column: 1/-1;
  margin-top: 30px;
  margin-bottom: 30px; }
  .cta-bar__icon {
    max-width: 60px;
    margin: 0 auto;
    background-color: #008bcb;
    background-color: var(--c-primary);
    border-radius: 50%;
    padding: 15px; }
    .cta-bar__icon .fa, .cta-bar__icon .fas, .cta-bar__icon .far {
      font-size: 30px;
      color: #fff;
      color: var(--c-light-neutral); }
  .cta-bar h2, .cta-bar h3, .cta-bar h4, .cta-bar h5, .cta-bar .how-to__steps-item h5 a, .how-to__steps-item h5 .cta-bar a, .cta-bar h6 {
    margin: 20px 0; }

.hero {
  background-color: #003a78;
  background-color: var(--c-secondary);
  margin-bottom: 60px; }
  .hero__container {
    grid-column: 1 / -1;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    min-height: 250px;
    color: #fff;
    color: var(--c-light-neutral); }
  .hero .bread-crumbs {
    opacity: 2;
    position: relative;
    margin-bottom: 20px;
    flex-basis: 100%; }
  .hero__content {
    z-index: 2;
    position: relative;
    flex-basis: 100%; }
    .hero__content p {
      margin-bottom: 10px;
      color: #fff;
      color: var(--c-light-neutral); }
  .hero__article-assets {
    z-index: 2;
    position: relative; }
  .hero__social-icons.social-icons li:first-child {
    display: block; }
  .hero__social-icons.social-icons svg {
    margin-right: 3px; }
    .hero__social-icons.social-icons svg:hover circle {
      fill: #fff;
      fill: var(--c-light-neutral); }
    .hero__social-icons.social-icons svg circle {
      fill: #fff;
      fill: var(--c-light-neutral); }
    .hero__social-icons.social-icons svg path {
      fill: #008bcb;
      fill: var(--c-primary); }
  .hero__date {
    color: #000;
    color: var(--c-dark-neutral); }
  @media all and (min-width: 1024px) {
    .hero__container {
      min-height: 320px; }
      .hero__container h1, .hero__container p {
        max-width: 800px; }
    .hero__content h1 {
      font-size: 28px;
      line-height: 41px;
      margin-bottom: 8px; }
    .hero__date p {
      font-size: 18px; } }
  @media all and (min-width: 1200px) {
    .hero__content {
      flex-basis: 70%; }
    .hero__article-assets {
      flex-basis: 30%;
      text-align: right; } }

.hero.hero--image {
  min-height: 250px;
  position: relative; }
  .hero.hero--image:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to right, black, rgba(255, 0, 0, 0));
    opacity: .6;
    z-index: 0; }
  .hero.hero--image .hero__date p {
    color: #fff; }
  @media all and (min-width: 1024px) {
    .hero.hero--image {
      height: 480px; }
      .hero.hero--image .hero__container {
        min-height: 480px; } }

.hero.hero--fallback {
  overflow: hidden;
  position: relative; }
  .hero.hero--fallback:before, .hero.hero--fallback:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%; }
  .hero.hero--fallback:before {
    background-color: #F39200;
    background-color: var(--c-primary-accent);
    height: 300px;
    width: 300px;
    top: -150px;
    right: -100px;
    z-index: 0;
    animation: floatOne 9s infinite ease-in-out; }
  .hero.hero--fallback:after {
    background-color: #fff;
    background-color: var(--c-light-neutral);
    height: 150px;
    width: 150px;
    bottom: -40px;
    right: -50px;
    z-index: 1;
    opacity: 0.6;
    animation: floatTwo 9s infinite ease-in-out; }
  @media all and (min-width: 1024px) {
    .hero.hero--fallback:before {
      width: 500px;
      height: 500px;
      top: -250px;
      right: 30px; }
    .hero.hero--fallback:after {
      width: 300px;
      height: 300px;
      bottom: -110px;
      right: 10px; } }
  @media all and (min-width: 1200px) {
    .hero.hero--fallback:before {
      width: 700px;
      height: 700px;
      top: -450px;
      right: 90px; }
    .hero.hero--fallback:after {
      width: 450px;
      height: 450px;
      bottom: -310px;
      right: 70px; } }

@keyframes floatOne {
  0%, 100% {
    transform: translate(0, 0); }
  20% {
    transform: translate(3%, 3%); }
  50% {
    transform: translate(1%, 2%); }
  75% {
    transform: translate(2%, 4%); } }

@keyframes floatTwo {
  0%, 100% {
    transform: translate(-1%, -4%); }
  10% {
    transform: translate(0, -2%); }
  50% {
    transform: translate(-3%, 2%); }
  75% {
    transform: translate(1%, 3%); } }

.hero.hero--simple {
  background-color: #f5f5f5;
  background-color: var(--light-grey); }
  .hero.hero--simple .bread-crumbs li, .hero.hero--simple .bread-crumbs li:after, .hero.hero--simple .bread-crumbs a, .hero.hero--simple .hero__date p {
    color: #008bcb;
    color: var(--c-primary); }
  .hero.hero--simple .hero__content h1, .hero.hero--simple .hero__content p {
    color: #008bcb;
    color: var(--c-primary); }
  .hero.hero--simple:before, .hero.hero--simple:after {
    display: none; }
  .hero.hero--simple .hero__social-icons svg circle {
    fill: #008bcb;
    fill: var(--c-primary); }
  .hero.hero--simple .hero__social-icons svg path {
    fill: #fff; }
  .hero.hero--simple .hero__social-icons.social-icons ul {
    display: flex;
    align-items: center;
    margin-top: 40px; }
    .hero.hero--simple .hero__social-icons.social-icons ul li:first-child {
      display: inline-block;
      margin-right: 16px; }
    .hero.hero--simple .hero__social-icons.social-icons ul li p {
      margin-bottom: 0; }
    .hero.hero--simple .hero__social-icons.social-icons ul li svg {
      max-width: 30px; }
      .hero.hero--simple .hero__social-icons.social-icons ul li svg:hover circle {
        fill: #F39200;
        fill: var(--c-primary-accent); }
  @media all and (min-width: 1024px) {
    .hero.hero--simple .cta-bar__icon {
      margin: 0; } }

.homepage-hero {
  color: #fff;
  color: var(--c-light-neutral);
  height: 560px;
  overflow: hidden;
  position: relative; }
  .homepage-hero:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to right, black, rgba(255, 0, 0, 0));
    opacity: .6;
    z-index: 1; }
  .homepage-hero__sub-section {
    margin-top: 32px; }
  .homepage-hero__content {
    padding-top: 80px;
    position: relative;
    z-index: 1;
    text-align: center;
    max-width: 350px;
    margin: 0 auto; }
    .homepage-hero__content h1 {
      font-weight: 300;
      font-size: 34px;
      margin-bottom: 8px; }
    .homepage-hero__content h2 {
      color: #fff;
      color: var(--c-light-neutral);
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 8px; }
    .homepage-hero__content p {
      color: #fff;
      color: var(--c-light-neutral); }
  .homepage-hero__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom; }
  @media all and (min-width: 550px) {
    .homepage-hero .input-button input[type="text"] {
      min-width: 248px;
      width: 77%; } }
  @media all and (min-width: 768px) {
    .homepage-hero {
      height: 600px; }
      .homepage-hero__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 100%;
        margin: auto;
        text-align: left;
        padding-top: 200px; }
        @supports (display: grid) {
          .homepage-hero__content {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-row-gap: 0;
            grid-column-gap: 32px; } }
        .homepage-hero__content h1 {
          font-size: 48px; }
      .homepage-hero__title {
        flex-basis: 46%; }
        @supports (display: grid) {
          .homepage-hero__title {
            grid-column: span 6; } }
      .homepage-hero__section {
        flex-basis: 46%; }
        @supports (display: grid) {
          .homepage-hero__section {
            grid-column: span 6; } }
      .homepage-hero__sub-section:first-child {
        padding-bottom: 32px;
        border-bottom: 1px solid #fff; } }
  @media all and (min-width: 1200px) {
    .homepage-hero__title {
      flex-basis: 27%; }
      @supports (display: grid) {
        .homepage-hero__title {
          grid-column: span 4; } }
    .homepage-hero__section {
      grid-column: 6/13;
      display: flex;
      align-items: center; }
    .homepage-hero__sub-section {
      margin-left: 64px;
      margin-top: 0; }
      .homepage-hero__sub-section:first-child {
        padding-bottom: 0;
        border-bottom: none;
        padding-right: 64px;
        margin-left: 0;
        position: relative; }
        .homepage-hero__sub-section:first-child:after {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          background-color: #fff;
          height: 200px;
          right: 0;
          top: 50%;
          transform: translateY(-50%); }
    .homepage-hero .input-button input[type="text"] {
      margin-right: 16px;
      min-width: 235px;
      width: 73%; } }

.homepage-hero-video {
  background-color: var(--c-dark-neutral);
  position: relative;
  height: 560px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .homepage-hero-video video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    display: none; }
  .homepage-hero-video video[poster] {
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .homepage-hero-video .container {
    height: 100%;
    margin: 0; }
  .homepage-hero-video .homepage-hero-video__section:nth-child(3) {
    margin-top: 24px; }
  .homepage-hero-video__content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 2;
    max-width: 650px; }
    .homepage-hero-video__content h1, .homepage-hero-video__content h2, .homepage-hero-video__content p {
      color: var(--c-light-neutral); }
    .homepage-hero-video__content h1 {
      font-weight: 300;
      font-size: 34px;
      margin-bottom: 8px; }
    .homepage-hero-video__content h2 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 8px; }
    .homepage-hero-video__content .input-button input, .homepage-hero-video__content .input-button input[type="text"] {
      min-width: 245px; }
  .homepage-hero-video__title {
    position: relative;
    padding-bottom: 40px; }
    .homepage-hero-video__title:after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      left: 50%;
      bottom: 16px;
      background-color: var(--c-light-neutral);
      transform: translate(-50%, 0);
      max-width: 350px; }
  .homepage-hero-video__play-button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    margin: 0 auto;
    margin-top: 16px; }
    .homepage-hero-video__play-button svg {
      max-width: 22px;
      max-height: 22px;
      width: 100%;
      margin-right: 8px; }
  .homepage-hero-video__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
    z-index: 1;
    opacity: 0.6; }
  @media all and (min-width: 768px) {
    .homepage-hero-video {
      height: 85vh; }
      .homepage-hero-video video {
        display: block; }
      .homepage-hero-video__content h1 {
        font-size: 48px; }
      .homepage-hero-video__image {
        display: none; } }

.homepage-hero.homepage-hero--campaign {
  margin-bottom: 30px;
  padding-bottom: 50px;
  min-height: 480px;
  height: auto; }
  .homepage-hero.homepage-hero--campaign .homepage-hero__content {
    padding-bottom: 35px; }
    .homepage-hero.homepage-hero--campaign .homepage-hero__content:after {
      bottom: -20px; }
  .homepage-hero.homepage-hero--campaign .contact-form {
    margin-bottom: 30px;
    max-width: 700px; }
    .homepage-hero.homepage-hero--campaign .contact-form form {
      padding: 0; }
  .homepage-hero.homepage-hero--campaign .cta-bar {
    text-align: center;
    position: relative;
    z-index: 2; }
  .homepage-hero.homepage-hero--campaign h2, .homepage-hero.homepage-hero--campaign h3, .homepage-hero.homepage-hero--campaign h4, .homepage-hero.homepage-hero--campaign h5, .homepage-hero.homepage-hero--campaign .how-to__steps-item h5 a, .how-to__steps-item h5 .homepage-hero.homepage-hero--campaign a, .homepage-hero.homepage-hero--campaign h6 {
    color: var(--c-lightNeutral);
    font-weight: 400; }
  .homepage-hero.homepage-hero--campaign .homepage-hero__container {
    max-width: 963px;
    margin: 0 auto; }
  .homepage-hero.homepage-hero--campaign form {
    background-color: #003a78;
    background-color: var(--c-secondary);
    padding: 32px;
    z-index: 1;
    border-radius: 3px;
    max-width: 700px;
    margin: 0;
    margin-bottom: 30px; }
    .homepage-hero.homepage-hero--campaign form p {
      color: #fff; }
  .homepage-hero.homepage-hero--campaign .contact-form {
    margin: 0;
    display: block; }
  .homepage-hero.homepage-hero--campaign .homepage-hero__widgets {
    position: relative;
    z-index: 1; }
  @media all and (min-width: 640px) {
    .homepage-hero.homepage-hero--campaign .homepage-hero__content {
      padding-top: 100px;
      display: block; }
      .homepage-hero.homepage-hero--campaign .homepage-hero__content:after {
        bottom: -30px; }
      .homepage-hero.homepage-hero--campaign .homepage-hero__content h1 {
        max-width: 600px; } }
  @media all and (min-width: 768px) {
    .homepage-hero.homepage-hero--campaign .cta-bar {
      text-align: left; }
    .homepage-hero.homepage-hero--campaign .homepage-hero__content {
      padding-left: 20px; } }
  @media all and (min-width: 900px) {
    .homepage-hero.homepage-hero--campaign .homepage-hero__container {
      margin: 0 auto;
      max-width: 1100px; } }
  @media all and (min-width: 1024px) {
    .homepage-hero.homepage-hero--campaign {
      min-height: 515px; }
      .homepage-hero.homepage-hero--campaign .homepage-hero__content {
        padding-top: 100px;
        padding-left: 0; }
        .homepage-hero.homepage-hero--campaign .homepage-hero__content:after {
          bottom: -105px; }
        .homepage-hero.homepage-hero--campaign .homepage-hero__content h1 {
          max-width: 700px;
          grid-column: 4/ 9; }
      .homepage-hero.homepage-hero--campaign .container {
        padding: 0; }
      .homepage-hero.homepage-hero--campaign .homepage-hero__widgets {
        max-width: 740px; } }

.hero.hero--campaign {
  text-align: center; }
  .hero.hero--campaign h1 {
    font-weight: 300;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 25px; }
  .hero.hero--campaign label {
    color: #008bcb;
    color: var(--c-primary); }
  .hero.hero--campaign .hero__container {
    align-items: center; }
  .hero.hero--campaign .hero__content .container {
    padding: 0; }
  .hero.hero--campaign .contact-form {
    display: block; }
  .hero.hero--campaign .cta-bar {
    text-align: center; }
    .hero.hero--campaign .cta-bar h2, .hero.hero--campaign .cta-bar h3, .hero.hero--campaign .cta-bar h4, .hero.hero--campaign .cta-bar h5, .hero.hero--campaign .cta-bar .how-to__steps-item h5 a, .how-to__steps-item h5 .hero.hero--campaign .cta-bar a, .hero.hero--campaign .cta-bar h6 {
      color: var(--c-mid-neutral);
      font-weight: 400; }
  .hero.hero--campaign .contact-form--campaign, .hero.hero--campaign .formwidget-submit-text {
    background: none;
    padding: 0 20px; }
  .hero.hero--campaign .contact-form--campaign .formwidget-submit-text {
    height: 100%;
    width: 100%;
    justify-content: end;
    padding: 0; }
  @media all and (min-width: 640px) {
    .hero.hero--campaign .contact-form--campaign {
      margin: 0; } }
  @media all and (min-width: 1024px) {
    .hero.hero--campaign {
      text-align: left; }
      .hero.hero--campaign .hero__container {
        min-height: 350px; }
      .hero.hero--campaign h1 {
        font-size: 55px;
        max-width: 600px;
        line-height: 75px; }
      .hero.hero--campaign .cta-bar {
        text-align: left; }
      .hero.hero--campaign .contact-form--campaign {
        padding: 0; } }

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px; }
  @supports (display: grid) {
    .gallery {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 20px; }
      .gallery__item {
        grid-column: span 6;
        cursor: pointer;
        margin-right: 0; } }
  .gallery h2 {
    grid-column: 1/-1;
    flex-basis: 100%; }
  .gallery__item {
    height: 130px;
    width: 100%;
    flex-basis: 46%;
    margin-bottom: 10px; }
    .gallery__item:nth-child(even) {
      margin-right: 15px; }
    @supports (display: grid) {
      .gallery__item {
        margin-bottom: 0; } }
    .gallery__item:last-child {
      margin-right: 0; }
  @media all and (min-width: 440px) {
    @supports (display: grid) {
      .gallery__item {
        grid-column: span 4; } }
    .gallery__item {
      height: 90px; } }
  @media all and (min-width: 550px) {
    .gallery__item {
      height: 130px;
      flex-basis: 30%;
      margin-right: 10px; }
      .gallery__item:nth-child(even) {
        margin-right: 10px; }
      @supports (display: grid) {
        .gallery__item {
          margin-right: 0; } } }
  @media all and (min-width: 768px) {
    @supports (display: grid) {
      .gallery__item {
        grid-column: span 3; } } }
  @media all and (min-width: 1024px) {
    .gallery__item {
      flex-basis: 23%; }
    @supports (display: grid) {
      .gallery {
        grid-column: 2/12; } } }
  @media all and (min-width: 1200px) {
    .gallery__item {
      height: 175px; } }

.gallery-popup {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 30; }
  .gallery-popup__content {
    position: relative;
    overflow: hidden;
    max-width: 90%; }
  .gallery-popup__image-container {
    display: none; }
    .gallery-popup__image-container--active {
      display: block;
      max-height: 720px; }
      .gallery-popup__image-container--active .gallery-popup__info {
        display: block; }
  .gallery-popup__btn {
    background-color: #F39200;
    background-color: var(--c-primary-accent);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 40%; }
    .gallery-popup__btn:after {
      content: "";
      display: block;
      position: absolute;
      left: 39px;
      top: 40%;
      width: 10px;
      height: 10px;
      transform: rotate(-135deg);
      transition: all .4s ease;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff; }
    .gallery-popup__btn--prev {
      left: -30px; }
    .gallery-popup__btn--next {
      right: -30px; }
      .gallery-popup__btn--next:after {
        left: auto;
        right: 40px;
        transform: rotate(45deg); }
  .gallery-popup__close {
    background-color: #F39200;
    background-color: var(--c-primary-accent);
    padding: 16px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0; }
    .gallery-popup__close svg {
      width: 100%;
      max-width: 20px;
      max-height: 30px; }
      .gallery-popup__close svg circle {
        fill: #fff;
        fill: var(--c-light-neutral); }
      .gallery-popup__close svg path {
        fill: #008bcb;
        fill: var(--c-primary); }
  .gallery-popup__info {
    background-color: #008bcb;
    background-color: var(--c-primary);
    flex-basis: 100%;
    padding: 20px;
    text-align: center;
    min-height: 102px;
    margin-top: -3px;
    display: none; }
    .gallery-popup__info p {
      color: #fff;
      color: var(--c-light-neutral); }
  .gallery-popup--active {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
  @media all and (min-width: 640px) {
    .gallery-popup__close {
      padding: 20px; }
      .gallery-popup__close svg {
        max-width: 25px; } }
  @media all and (min-width: 768px) {
    .gallery-popup__content {
      max-width: 80%; } }
  @media all and (min-width: 1024px) {
    .gallery-popup__content {
      max-width: 800px; } }

.footer-form {
  background: red; }

.bread-crumbs li {
  display: inline-block;
  margin-right: 5px;
  font-size: 12px; }
  .bread-crumbs li:after {
    content: "/";
    display: inline-block;
    color: #fff;
    color: var(--c-light-neutral);
    padding-left: 5px; }
  .bread-crumbs li:last-of-type:after {
    display: none; }

.bread-crumbs__prev {
  text-decoration: underline; }

.bread-crumbs a {
  color: #fff;
  color: var(--c-light-neutral);
  font-size: 12px; }

@media all and (min-width: 1024px) {
  .bread-crumbs li, .bread-crumbs a {
    font-size: 14px; } }

.testimonial {
  background-color: #008bcb;
  background-color: var(--c-primary); }
  .testimonial:nth-child(2n+1) {
    background-color: #F39200;
    background-color: var(--c-primary-accent); }
  .testimonial__content-container {
    position: relative; }
  .testimonial__content {
    padding: 35px 40px;
    color: #fff;
    color: var(--c-light-neutral); }
    .testimonial__content p {
      color: #fff;
      color: var(--c-light-neutral); }
  .testimonial__quote-mark {
    position: absolute;
    width: 100%;
    max-width: 20px; }
    .testimonial__quote-mark--left {
      top: 15px;
      left: 15px; }
    .testimonial__quote-mark--right {
      bottom: 15px;
      right: 15px; }
    .testimonial__quote-mark svg path {
      mask: none; }
  .testimonial__author {
    font-weight: 600;
    font-size: 20px; }
  .testimonial__image-container {
    height: 200px; }
  .testimonial .video-placeholder {
    margin: 0; }
  @media all and (min-width: 550px) {
    .testimonial__image-container {
      height: 300px; }
    .testimonial__quote-mark {
      max-width: 30px; } }
  @media all and (min-width: 640px) {
    .testimonial__content {
      padding: 40px 55px; } }
  @media all and (min-width: 768px) {
    .testimonial {
      flex-basis: 48%;
      margin-right: 2%; }
      .testimonial:last-child {
        margin-right: 0; }
      @supports (display: grid) {
        .testimonial {
          grid-column: span 6;
          margin-right: 0; } } }
  @media all and (min-width: 1200px) {
    .testimonial__image-container {
      height: 350px; } }

@media all and (min-width: 1200px) {
  .testimonial.testimonial--side-image {
    display: flex;
    flex-wrap: wrap; }
    .testimonial.testimonial--side-image .testimonial__image-container {
      flex-basis: 36%;
      height: auto; }
    .testimonial.testimonial--side-image .testimonial__content-container {
      flex-basis: 64%; } }

@media all and (min-width: 1200px) {
  .testimonial.testimonial--side-image-right .testimonial__image-container {
    order: 2; } }

.video-placeholder {
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  cursor: pointer;
  width: 100%;
  overflow: hidden; }
  @supports (display: grid) {
    .video-placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-column: 1 / -1; } }
  .video-placeholder__play-button {
    width: 100%;
    max-width: 80px;
    position: absolute;
    left: 45%;
    top: 35%;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.5s ease-in-out; }
    .video-placeholder__play-button svg {
      width: 100%; }
      .video-placeholder__play-button svg path {
        fill: #000;
        fill: var(--c-dark-neutral); }
        .video-placeholder__play-button svg path:last-child {
          fill: #fff;
          fill: var(--c-light-neutral); }
  .video-placeholder__image {
    position: absolute;
    top: -16.8%;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    margin: 0 !important; }
  .video-placeholder__video {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    width: 100%;
    z-index: 0;
    opacity: 0; }
    .video-placeholder__video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0; }
  .video-placeholder--active .video-placeholder__video {
    z-index: 2;
    opacity: 1;
    transition: all 1s ease; }
  .video-placeholder--active .video-placeholder__play-button {
    opacity: 0; }
  @supports (display: grid) {
    .video-placeholder__play-button {
      left: auto;
      top: auto; } }

.video-placeholder.video-placeholder--background-image {
  background: url(https://picsum.photos/1000/700);
  background-size: cover;
  width: 100%;
  height: 300px; }

.video-placeholder.video-placeholder--popup {
  height: 250px; }
  @media all and (min-width: 1024px) {
    .video-placeholder.video-placeholder--popup {
      height: 300px; } }
  @media all and (min-width: 1200px) {
    .video-placeholder.video-placeholder--popup {
      height: 400px; } }

.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 35;
  display: none; }
  .video-popup__close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.6;
    cursor: pointer; }
    .video-popup__close:hover {
      opacity: 1; }
    .video-popup__close:before, .video-popup__close:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #fff;
      background-color: var(--c-light-neutral); }
    .video-popup__close:before {
      transform: rotate(45deg); }
    .video-popup__close:after {
      transform: rotate(-45deg); }
  .video-popup__container {
    max-width: 800px;
    width: 90%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .video-popup__video {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    width: 100%;
    background-color: #000;
    background-color: var(--c-dark-neutral); }
    .video-popup__video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0; }
  .video-popup--active {
    display: block; }
  @media all and (min-width: 1200px) {
    .video-popup__container {
      max-width: 940px; } }

.google-maps {
  margin-top: 40px;
  grid-column: 1/-1; }
  .google-maps #map {
    height: 400px;
    /* The height is 400 pixels */
    width: 100%; }
  @media all and (min-width: 550px) {
    .google-maps {
      grid-column: 2/12; } }

.icon-circle {
  background-color: #F39200;
  background-color: var(--c-primary-accent);
  margin: 0 auto;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .icon-circle i {
    font-size: 18px;
    color: #fff;
    color: var(--c-light-neutral); }
  .icon-circle svg {
    position: absolute;
    max-width: 35px;
    top: 0;
    left: 35%; }
    @supports (display: grid) {
      .icon-circle svg {
        top: auto;
        left: auto; } }
  @media all and (min-width: 1024px) {
    .icon-circle {
      height: 100px;
      width: 100px; }
      .icon-circle i {
        font-size: 32px; } }

.logo-title {
  grid-column: 1/-1;
  display: flex;
  width: 100%;
  align-items: center; }
  .logo-title__logo {
    max-width: 120px;
    margin-right: 20px; }
  .logo-title h2, .logo-title h3 {
    margin-bottom: 0 !important; }

input::placeholder {
  color: #c7c7c7;
  color: var(--mid-grey); }

input[type="text"], input[type="email"], textarea {
  border: 1px solid #787a7b;
  border-radius: 3px;
  outline: none;
  padding: 13px;
  width: 100%;
  width: -webkit-fill-available;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 20px; }
  input[type="text"]:focus, input[type="email"]:focus, textarea:focus {
    border-color: #008bcb; }

label {
  display: block;
  margin-bottom: 3px; }

input[type="radio"], input[type="checkbox"] {
  position: absolute;
  left: -10000px; }

input[type="radio"] ~ label, input[type="checkbox"] ~ label {
  padding-left: 40px;
  margin-bottom: 0; }
  input[type="radio"] ~ label:before, input[type="checkbox"] ~ label:before {
    content: "";
    height: 22px;
    width: 22px;
    border: 1px solid #787a7b;
    background-color: #fff;
    display: block;
    position: absolute;
    left: 0;
    top: -4px;
    cursor: pointer; }
  input[type="radio"] ~ label:hover:before, input[type="checkbox"] ~ label:hover:before {
    border-color: #008bcb; }

input[type="radio"]:checked ~ label:before, input[type="checkbox"]:checked ~ label:before {
  border-color: #008bcb; }

input[type="radio"]:checked ~ label:after, input[type="checkbox"]:checked ~ label:after {
  content: "";
  display: block;
  background-color: #008bcb;
  position: absolute; }

button {
  border: none;
  cursor: pointer;
  outline: none;
  background: none;
  color: #fff;
  color: var(--c-light-neutral);
  font-size: 16px;
  border-radius: 3px;
  font-weight: 400;
  transition: 0.3s ease;
  text-decoration: none;
  display: inline-block; }
  button:hover {
    background-color: #008bcb;
    background-color: var(--c-primary); }

select {
  padding: 14px 40px 14px 10px;
  border-radius: 3px;
  border-color: #787a7b;
  border-width: 1px;
  border-color: var(--grey);
  display: block;
  width: 100%;
  color: #787a7b;
  color: var(--grey);
  outline: none;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: url("../Images/arrow.svg") no-repeat right center;
  cursor: pointer;
  margin-bottom: 20px; }

input[type=submit] {
  outline: none;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  cursor: pointer; }

.radio-input, .ktc-radio {
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
  display: block; }
  .radio-input label:before, .ktc-radio label:before {
    border-radius: 50%; }
  .radio-input input:checked ~ label:before, .ktc-radio input:checked ~ label:before {
    border-color: #008bcb; }
  .radio-input input:checked ~ label:after, .ktc-radio input:checked ~ label:after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    left: 6px;
    top: 2px; }

.checkbox, .ktc-checkbox {
  position: relative;
  margin-bottom: 20px;
  margin-top: 5px; }
  .checkbox label, .ktc-checkbox label {
    margin-top: 15px;
    line-height: 18px; }
    .checkbox label:before, .ktc-checkbox label:before {
      border-radius: 3px; }
  .checkbox input:checked ~ label:before, .ktc-checkbox input:checked ~ label:before {
    border-color: #008bcb;
    background-color: #008bcb; }
  .checkbox input:checked ~ label:after, .ktc-checkbox input:checked ~ label:after {
    left: 9px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); }

.search-input {
  position: relative;
  width: 100%; }
  .search-input span {
    position: absolute;
    top: 12px;
    left: 12px;
    max-width: 25px;
    width: 100%; }
  .search-input input {
    padding-left: 50px; }

.pagination {
  display: flex;
  align-items: center;
  grid-column: 1 / -1;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px; }
  .pagination__btn-link {
    grid-column: auto !important;
    width: auto !important; }
    .pagination__btn-link:first-child {
      margin-right: 10px; }
    .pagination__btn-link:last-child {
      margin-left: 10px; }
  .pagination__btn {
    border-top: 2px solid #008bcb;
    border-right: 2px solid #008bcb;
    width: 12px;
    height: 12px;
    transform: rotate(-135deg);
    display: block;
    transition: all .4s ease; }
    .pagination__btn--last {
      transform: rotate(45deg); }
  .pagination__item {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 10px; }
    .pagination__item span, .pagination__item a {
      font-size: 20px; }
    .pagination__item button {
      color: #F39200;
      color: var(--c-primary-accent);
      font-size: 16px; }
    .pagination__item--active {
      background-color: #008bcb;
      background-color: var(--c-primary);
      border-radius: 50%;
      padding: 6px 11px; }
      .pagination__item--active span {
        color: #fff;
        color: var(--c-light-neutral); }
  .pagination__dots {
    color: #F39200;
    color: var(--c-primary-accent); }
  .pagination button:hover {
    background: none; }
  @media all and (min-width: 1024px) {
    .pagination__item {
      margin-right: 5px; }
      .pagination__item span, .pagination__item a {
        font-size: 16px; }
    .pagination__btn {
      width: 9px;
      height: 9px; } }

.pagination-container {
  grid-column: 1/-1;
  width: 100%; }
  .pagination-container p {
    flex-basis: 30%; }
  @media all and (min-width: 1024px) {
    .pagination-container {
      grid-column: 2/12;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }

.page-builder-txt {
  grid-column: 1/-1;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px; }
  .page-builder-txt p {
    margin-bottom: 15px; }
  .page-builder-txt ul,
  .page-builder-txt ol {
    margin: 30px 0 30px 30px; }
  .page-builder-txt ul {
    list-style-type: initial; }
  .page-builder-txt ol {
    list-style-type: decimal; }
  @media all and (min-width: 550px) {
    .page-builder-txt {
      grid-column: 2/12; } }
  @media all and (min-width: 1024px) {
    .page-builder-txt {
      grid-column: 4/10; } }

.page-builder-img {
  text-align: right;
  grid-column: 1/-1;
  margin-top: 30px;
  margin-bottom: 30px; }
  @media all and (min-width: 550px) {
    .page-builder-img {
      grid-column: 2/12; } }
  @media all and (min-width: 1024px) {
    .page-builder-img {
      grid-column: 3/11; } }

.page-loader {
  position: absolute;
  height: 50px;
  width: 135px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center; }
  .page-loader__ball {
    width: 20px;
    position: relative;
    display: inline-block;
    margin: 3px;
    height: 20px;
    border-radius: 50%;
    z-index: 17;
    opacity: 1; }
    .page-loader__ball--end {
      animation: ending-balls 1s !important;
      opacity: 0 !important;
      transition: all 1s; }
      .page-loader__ball--end:nth-child(2) {
        animation-delay: 0.1s; }
      .page-loader__ball--end:nth-child(3) {
        animation-delay: 0.2s; }
      .page-loader__ball--end:nth-child(4) {
        animation-delay: 0.3s; }

@keyframes ending-balls {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-1000px); } }
    .page-loader__ball:first-child {
      background-color: #008bcb;
      background-color: var(--c-primary);
      animation: jump .5s ease 0s infinite alternate; }
    .page-loader__ball:nth-child(2) {
      background-color: #F39200;
      background-color: var(--c-primary-accent);
      animation: jump .5s ease .15s infinite alternate; }
    .page-loader__ball:nth-child(3) {
      background-color: #003a78;
      background-color: var(--c-secondary);
      animation: jump .5s ease .25s infinite alternate; }
    .page-loader__ball:nth-child(4) {
      background-color: #F39200;
      background-color: var(--c-primary-accent);
      animation: jump .5s ease .35s infinite alternate; }

@keyframes jump {
  0% {
    transform: scaleY(0.8); }
  100% {
    transform: translateY(-90px); } }
  .page-loader__shadow {
    position: relative;
    opacity: .1;
    bottom: 20px;
    width: 20px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
    display: inline-block;
    margin: 3px;
    z-index: 2; }
    .page-loader__shadow--one {
      animation: shrink .5s ease .0s infinite alternate; }
    .page-loader__shadow--two {
      animation: shrink .5s ease .15s infinite alternate; }
    .page-loader__shadow--three {
      animation: shrink .5s ease .25s infinite alternate; }
    .page-loader__shadow--four {
      animation: shrink .5s ease .35s infinite alternate; }

@keyframes shrink {
  100% {
    transform: scaleX(0.5);
    opacity: .01; } }
    .page-loader__shadow--end {
      opacity: 0 !important;
      transition: all 0.5s; }

.loader-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: 15; }
  .loader-overlay--end {
    opacity: 0;
    transition: all 2s; }

.animation-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px; }
  .animation-container .tile-wrapper {
    width: calc(50% - 5px);
    height: 275px; }
    .animation-container .tile-wrapper:nth-child(2), .animation-container .tile-wrapper:nth-child(4) {
      left: 50%; }
      .animation-container .tile-wrapper:nth-child(2) .tile, .animation-container .tile-wrapper:nth-child(4) .tile {
        left: 0; }
        .animation-container .tile-wrapper:nth-child(2) .tile .sizable-wrapper, .animation-container .tile-wrapper:nth-child(4) .tile .sizable-wrapper {
          left: 50%;
          margin-left: 5px; }
    .animation-container .tile-wrapper:nth-child(3) .tile .sizable-wrapper, .animation-container .tile-wrapper:nth-child(4) .tile .sizable-wrapper {
      top: 50%; }
    .animation-container .tile-wrapper--active .sizable-wrapper {
      cursor: default !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      width: 100% !important;
      max-width: 100% !important;
      height: 100% !important;
      margin-left: 0 !important; }
    .animation-container .tile-wrapper--active .tile-close-btn {
      opacity: 1 !important;
      visibility: visible !important; }
    .animation-container .tile-wrapper--active .tile__right-slide {
      transform: translateX(-1100px) !important; }
    .animation-container .tile-wrapper--active .tile__left-slide {
      transform: translateX(1100px) !important; }
    .animation-container .tile-wrapper .tile {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden; }
      .animation-container .tile-wrapper .tile--active {
        z-index: 3; }
      .animation-container .tile-wrapper .tile__right-slide {
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: 1;
        transform: translateZ(0);
        right: -1100px;
        transition: transform 170ms ease-in-out;
        will-change: transform;
        display: block !important; }
      .animation-container .tile-wrapper .tile__left-slide {
        position: absolute;
        bottom: 0;
        width: 480px;
        height: 374px;
        z-index: 1;
        transform: translateZ(0);
        left: -1079px;
        transition: transform 10ms ease-in-out;
        will-change: transform;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px; }
        .animation-container .tile-wrapper .tile__left-slide ul {
          padding-top: 40px;
          grid-column: span 2;
          column-count: 2; }
          .animation-container .tile-wrapper .tile__left-slide ul li {
            margin: 0 15px 10px 0; }
            .animation-container .tile-wrapper .tile__left-slide ul li a {
              text-decoration: underline;
              cursor: pointer;
              line-height: 20px; }
      .animation-container .tile-wrapper .tile .tile-close-btn {
        background-color: #008bcb;
        background-color: var(--c-primary);
        transform: translateZ(0);
        opacity: 0;
        transition: opacity 170ms .1s ease-in-out;
        will-change: opacity;
        visibility: hidden;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        flex-direction: row-reverse;
        padding: 20px;
        cursor: pointer; }
        .animation-container .tile-wrapper .tile .tile-close-btn svg {
          width: 100%;
          max-width: 30px;
          max-height: 50px; }
          .animation-container .tile-wrapper .tile .tile-close-btn svg circle {
            fill: #fff;
            fill: var(--c-light-neutral); }
          .animation-container .tile-wrapper .tile .tile-close-btn svg path {
            fill: #008bcb;
            fill: var(--c-primary); }
    .animation-container .tile-wrapper .sizable-wrapper {
      border-color: #008bcb;
      border-color: var(--c-primary);
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      border-radius: 3px;
      background-color: #fff;
      background-color: var(--c-light-neutral);
      position: relative;
      z-index: 1;
      display: inline-block;
      left: 0;
      top: 0;
      width: 50%;
      max-width: calc(50% - 5px);
      height: 265px;
      cursor: pointer;
      transform: translateZ(0);
      transition: all 170ms ease-in-out,max-height 0s,padding 0s;
      will-change: top,bottom,left,right,width,height,max-width; }
      .animation-container .tile-wrapper .sizable-wrapper:after {
        content: "";
        display: block;
        position: absolute;
        background-color: #F9D900;
        height: 100%;
        width: 5px;
        bottom: 0;
        left: 0;
        left: 8px; }
      .animation-container .tile-wrapper .sizable-wrapper:before {
        content: "";
        display: block;
        position: absolute;
        background-color: #F39200;
        height: 100%;
        width: 8px;
        bottom: 0;
        left: 0;
        z-index: 1; }
      .animation-container .tile-wrapper .sizable-wrapper .mobile-wrapper {
        display: none; }
      .animation-container .tile-wrapper .sizable-wrapper .mobile-icon {
        display: none; }
    .animation-container .tile-wrapper .text-wrapper {
      max-width: 480px;
      display: inline-block;
      padding: 24px; }
      .animation-container .tile-wrapper .text-wrapper h3 {
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 20px; }
      .animation-container .tile-wrapper .text-wrapper .logo-title {
        margin-bottom: 20px; }
        .animation-container .tile-wrapper .text-wrapper .logo-title__logo {
          max-width: 80px; }
  @media all and (max-width: 1200px) {
    .animation-container .tile-wrapper .text-wrapper, .animation-container .tile__left-slide {
      max-width: 385px; } }
  @media all and (max-width: 1024px) {
    .animation-container .tile-wrapper .text-wrapper, .animation-container .tile__left-slide {
      max-width: 315px; } }
  @media all and (max-width: 900px) {
    .animation-container {
      height: auto; }
      .animation-container .tile-wrapper {
        width: 100%;
        height: auto;
        margin-top: 10px; }
        .animation-container .tile-wrapper:nth-child(2) .sizable-wrapper, .animation-container .tile-wrapper:nth-child(4) .sizable-wrapper {
          width: 100%;
          top: 0 !important;
          left: 0 !important;
          margin-left: 0 !important; }
        .animation-container .tile-wrapper:nth-child(3) .tile .sizable-wrapper, .animation-container .tile-wrapper:nth-child(4) .tile .sizable-wrapper {
          top: 0; }
        .animation-container .tile-wrapper .tile {
          position: relative; }
          .animation-container .tile-wrapper .tile--active .tile__left-slide {
            opacity: 1;
            visibility: visible; }
          .animation-container .tile-wrapper .tile__right-slide {
            display: none !important; }
          .animation-container .tile-wrapper .tile__left-slide {
            display: none !important; }
          .animation-container .tile-wrapper .tile .tile-close-btn {
            bottom: 0;
            top: auto;
            width: 100%;
            text-align: center; }
        .animation-container .tile-wrapper--active .sizable-wrapper {
          max-height: 100% !important;
          cursor: default !important; }
        .animation-container .tile-wrapper .sizable-wrapper {
          width: 100%;
          max-width: 100%;
          display: block;
          overflow: hidden;
          min-height: 235px;
          max-height: 235px;
          height: auto;
          transition: max-height .4s ease-out;
          will-change: max-height; }
          .animation-container .tile-wrapper .sizable-wrapper .mobile-wrapper {
            display: block; }
            .animation-container .tile-wrapper .sizable-wrapper .mobile-wrapper ul {
              padding: 20px;
              margin-top: 30px; }
              .animation-container .tile-wrapper .sizable-wrapper .mobile-wrapper ul li {
                margin: 0 20px 20px 0; }
                .animation-container .tile-wrapper .sizable-wrapper .mobile-wrapper ul li a {
                  text-decoration: underline; }
            .animation-container .tile-wrapper .sizable-wrapper .mobile-wrapper__image {
              height: 400px; }
          .animation-container .tile-wrapper .sizable-wrapper .mobile-icon {
            display: block; }
          .animation-container .tile-wrapper .sizable-wrapper .desktop-icon {
            display: none; }
        .animation-container .tile-wrapper .text-wrapper {
          max-width: 100%; } }

.opening-section__content-container {
  background-color: #008bcb;
  background-color: var(--c-primary);
  position: relative; }
  .opening-section__content-container h2 {
    color: #fff;
    color: var(--c-light-neutral);
    margin-bottom: 20px; }
  .opening-section__content-container p {
    margin-bottom: 20px;
    color: #fff;
    color: var(--c-light-neutral); }
  .opening-section__content-container a {
    color: #fff;
    color: var(--c-light-neutral); }

.opening-section__content {
  padding: 40px 20px; }
  .opening-section__content a {
    text-decoration: underline;
    font-weight: 600;
    font-size: 18px; }

.opening-section__image-list {
  margin: 0 auto; }
  .opening-section__image-list .image-list__item {
    padding: 32px 0; }

.opening-section .video-placeholder {
  margin: 0; }

@media all and (min-width: 900px) {
  .opening-section {
    display: flex;
    flex-wrap: wrap; }
    .opening-section__content-container {
      flex-basis: 48%; }
      @supports (display: grid) {
        .opening-section__content-container {
          flex-basis: 50%; } }
    .opening-section__content {
      padding: 40px; }
    .opening-section__left-container {
      flex-basis: 52%;
      display: flex;
      flex-wrap: wrap-reverse; }
      @supports (display: grid) {
        .opening-section__left-container {
          flex-basis: 50%; } }
    .opening-section__image-list {
      flex-basis: 100%; } }

@media all and (min-width: 1024px) {
  .opening-section__content-container {
    display: flex;
    justify-content: center;
    align-items: center; }
  .opening-section__content {
    padding: 40px 0;
    max-width: 450px; } }

@media all and (min-width: 1200px) {
  .opening-section .video-placeholder--background-image {
    height: 350px; } }

@media all and (min-width: 1600px) {
  .opening-section__content-container {
    flex-basis: 40%; }
  .opening-section__left-container {
    flex-basis: 60%; }
  .opening-section .video-placeholder--background-image {
    height: 400px; } }

@media all and (min-width: 2000px) {
  .opening-section__content-container {
    flex-basis: 35%; }
  .opening-section__left-container {
    flex-basis: 65%; } }

.quote {
  grid-column: 1/-1;
  position: relative;
  text-align: center;
  padding: 0 20px;
  margin-top: 60px;
  margin-bottom: 60px; }
  .quote__quote-mark {
    width: 100%;
    max-width: 60px;
    position: absolute; }
    .quote__quote-mark:first-child {
      top: -38px;
      left: 0; }
    .quote__quote-mark:last-child {
      bottom: -38px;
      right: 0; }
  .quote__content {
    margin-bottom: 30px; }
  .quote p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
    font-weight: 600; }
  .quote svg g {
    fill: #F39200;
    fill: var(--c-primary-accent); }
  .quote svg path {
    fill: #F39200;
    fill: var(--c-primary-accent);
    mask: none; }
  @media all and (min-width: 768px) {
    .quote__quote-mark {
      max-width: 90px; }
    .quote__content {
      max-width: 80%;
      margin: 0 auto;
      margin-bottom: 30px; }
    .quote p {
      font-size: 20px;
      line-height: 28px; } }
  @media all and (min-width: 900px) {
    .quote__quote-mark {
      max-width: 130px; }
      .quote__quote-mark:first-child {
        top: -30px; }
      .quote__quote-mark:last-child {
        bottom: -30px; } }

.quote.quote--white .quote__quote-mark path {
  fill: #fff; }

.quote.quote--white h5, .quote.quote--white .how-to__steps-item h5 a, .how-to__steps-item h5 .quote.quote--white a {
  color: #fff; }

.social-icons li {
  display: inline-block;
  padding-right: 5px; }
  .social-icons li:last-child {
    padding-right: 0; }
  .social-icons li svg {
    width: 100%;
    max-width: 40px; }
    .social-icons li svg:hover circle {
      fill: #F39200;
      fill: var(--c-primary-accent); }
    .social-icons li svg circle {
      fill: #008bcb;
      fill: var(--c-primary);
      transition: 0.3s ease; }
    .social-icons li svg path {
      fill: #fff; }

.local-office-cta {
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 120px;
  background-color: #008bcb;
  background-color: var(--c-primary); }
  .local-office-cta__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: 0 auto; }
    @supports (display: grid) {
      .local-office-cta__container {
        grid-column: 1 / -1; } }
  .local-office-cta h3, .local-office-cta p, .local-office-cta label {
    color: #fff;
    color: var(--c-light-neutral); }
  .local-office-cta h3, .local-office-cta p {
    margin-bottom: 20px; }
  .local-office-cta h3 {
    font-size: 28px; }
  .local-office-cta label {
    text-align: left; }
  .local-office-cta__content {
    order: 1; }
  .local-office-cta__button .btn, .local-office-cta__button input[type=submit] {
    width: 100%; }
  .local-office-cta__error {
    padding: 2rem 0;
    color: #f33f3f; }
  .local-office-cta__icon {
    align-self: flex-start;
    width: 100%;
    margin-bottom: 20px; }
    .local-office-cta__icon svg {
      width: 100%;
      max-width: 70px;
      max-height: 115px;
      padding: 45px 50px 20px 55px;
      border-radius: 50%;
      background-color: #008bcb;
      background-color: var(--c-primary); }
      .local-office-cta__icon svg circle {
        fill: #008bcb;
        fill: var(--c-primary); }
      .local-office-cta__icon svg g {
        transform: scale(0.6) translate(11px, 11px);
        stroke-width: 0.1; }
  .local-office-cta__image {
    margin-bottom: 24px; }
    .local-office-cta__image img {
      max-width: 115px; }
  @media all and (min-width: 768px) {
    .local-office-cta__container {
      text-align: left;
      flex-direction: row;
      justify-content: space-between;
      max-width: 100%;
      align-items: center;
      margin: 0; }
      @supports (display: grid) {
        .local-office-cta__container {
          grid-column: 2/12; } }
    .local-office-cta__content {
      order: 0;
      max-width: 496px; }
    .local-office-cta__inputs {
      display: flex;
      align-items: flex-end; }
      .local-office-cta__inputs input {
        margin-bottom: 0;
        margin-right: 20px;
        width: 220px; }
    .local-office-cta__icon {
      width: auto;
      align-self: auto; }
      .local-office-cta__icon svg {
        max-width: 50px;
        padding: 30px 35px 15px; }
    .local-office-cta__image {
      margin-bottom: 0; }
      .local-office-cta__image img {
        max-width: 170px; } }
  @media all and (min-width: 1024px) {
    .local-office-cta__content {
      max-width: 100%; }
      .local-office-cta__content h3 {
        font-size: 34px; }
    .local-office-cta input {
      width: 270px; } }
  @media all and (min-width: 1200px) {
    .local-office-cta__icon svg {
      max-width: 80px;
      padding: 40px 50px 20px; } }

.modal-popup {
  display: none;
  position: fixed;
  min-width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 30; }
  .modal-popup__container {
    max-width: 600px;
    width: 100%;
    background-color: #003a78;
    background-color: var(--c-secondary);
    padding: 40px;
    position: relative;
    border-radius: 3px; }
  .modal-popup__close {
    background-color: #F39200;
    background-color: var(--c-primary-accent);
    padding: 15px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10; }
    .modal-popup__close svg {
      width: 100%;
      max-width: 30px;
      max-height: 30px; }
      .modal-popup__close svg circle {
        fill: #fff;
        fill: var(--c-light-neutral); }
      .modal-popup__close svg path {
        fill: #008bcb;
        fill: var(--c-primary); }
  .modal-popup--nav-form {
    background: none; }
    .modal-popup--nav-form .modal-popup__container {
      overflow-y: scroll;
      height: 100vh;
      top: 124px;
      padding: 25px;
      padding-bottom: 300px;
      max-width: 100%;
      -webkit-overflow-scrolling: touch; }
      .modal-popup--nav-form .modal-popup__container::-webkit-scrollbar {
        display: none; }
      .modal-popup--nav-form .modal-popup__container .contact-form h2 {
        font-size: 28px;
        margin-bottom: 10px; }
      @supports (display: grid) {
        .modal-popup--nav-form .modal-popup__container .contact-form .grid:last-child .grid__item {
          grid-column: 1/-1; } }
      .modal-popup--nav-form .modal-popup__container .contact-form .grid:last-child .grid__item iframe {
        left: 0; }
  .modal-popup--active {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center; }
  .modal-popup form .formwidget-submit-text {
    margin-top: 24px;
    position: relative; }
  @media all and (min-width: 1024px) {
    .modal-popup--nav-form .modal-popup__container {
      position: absolute;
      right: 170px;
      top: 10px;
      max-width: 600px;
      max-height: 735px;
      padding-bottom: 30px;
      height: auto; }
      .modal-popup--nav-form .modal-popup__container .contact-form .ktc-default-section {
        margin-bottom: 10px; }
      .modal-popup--nav-form .modal-popup__container .contact-form__text {
        margin-bottom: 24px;
        line-height: 20px; }
      .modal-popup--nav-form .modal-popup__container .contact-form textarea {
        min-height: 65px; }
      .modal-popup--nav-form .modal-popup__container .contact-form input[type="text"] {
        padding: 10px; }
      .modal-popup--nav-form .modal-popup__container .contact-form .grid {
        margin-bottom: 10px; }
        .modal-popup--nav-form .modal-popup__container .contact-form .grid__item {
          flex-basis: 100%; }
    .modal-popup__container {
      padding: 30px; }
    .modal-popup__close {
      padding: 20px;
      z-index: 55; } }

.link-arrow {
  position: relative;
  line-height: 22px; }
  .link-arrow:after {
    content: ">";
    color: #008bcb;
    color: var(--c-primary);
    background-color: #fff;
    background-color: var(--c-light-neutral);
    padding: 0px 6px;
    border-radius: 50%;
    margin-left: 10px;
    font-size: 12px;
    position: absolute;
    bottom: 0; }

.expandable-panels {
  margin-top: 30px;
  margin-bottom: 30px;
  grid-column: 1/-1;
  max-width: 800px;
  margin: 0 auto; }
  .expandable-panels__item {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 3px;
    position: relative;
    border-style: solid;
    border-width: 1px;
    border-color: #008bcb;
    border-color: var(--c-primary);
    border-radius: 3px;
    margin-bottom: 30px;
    background-color: #fff;
    background-color: var(--c-light-neutral);
    z-index: 0;
    box-shadow: 0px 0px 22px -5px rgba(0, 0, 0, 0.33); }
    .expandable-panels__item--secondary .expandable-panels__top-container {
      border: 1px solid #F39200;
      border: 1px solid var(--c-primary-accent);
      border-width: 0 0 0 10px; }
      .expandable-panels__item--secondary .expandable-panels__top-container:before {
        background-color: #F39200;
        background-color: var(--c-primary-accent); }
      .expandable-panels__item--secondary .expandable-panels__top-container:after {
        content: "";
        display: block;
        position: absolute;
        background-color: #F9D900;
        height: 100%;
        width: 5px;
        bottom: 0;
        left: 0;
        z-index: -2; }
    .expandable-panels__item--secondary .expandable-panels__cross {
      background-color: #F39200;
      background-color: var(--c-primary-accent); }
  .expandable-panels .expandable-panels__item--secondary.expandable-panels--open .expandable-panels__cross:before {
    background-color: #F39200;
    background-color: var(--c-primary-accent); }
  .expandable-panels__top-container {
    margin: -10px -20px;
    padding: 20px 30px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-style: solid;
    border-width: 0 0 0 10px;
    border-color: #008bcb;
    border-color: var(--c-primary);
    transition: all 0.3s ease-out; }
    .expandable-panels__top-container:before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #008bcb;
      background-color: var(--c-primary);
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition-property: transform;
      transition-duration: 0.25s;
      transition-timing-function: ease-out; }
    .expandable-panels__top-container h3, .expandable-panels__top-container h4, .expandable-panels__top-container h5, .expandable-panels__top-container .how-to__steps-item h5 a, .how-to__steps-item h5 .expandable-panels__top-container a {
      margin-bottom: 0;
      padding-right: 20px; }
    .expandable-panels__top-container h5, .expandable-panels__top-container .how-to__steps-item h5 a, .how-to__steps-item h5 .expandable-panels__top-container a {
      color: #008bcb;
      color: var(--c-primary); }
  .expandable-panels__cross {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    min-width: 25px;
    background-color: #008bcb;
    background-color: var(--c-primary);
    border-radius: 50%;
    transition: 0.3s all; }
    .expandable-panels__cross:before, .expandable-panels__cross:after {
      content: "";
      display: block;
      position: absolute;
      transition: .3s;
      background-color: #fff;
      background-color: var(--c-light-neutral);
      border-radius: 2px; }
    .expandable-panels__cross:before {
      top: 25%;
      bottom: 25%;
      width: 10%;
      left: 45%; }
    .expandable-panels__cross:after {
      left: 25%;
      right: 25%;
      height: 10%;
      top: 45%; }
  .expandable-panels__content {
    display: block;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.4s ease; }
    .expandable-panels__content ul {
      margin: 30px 0 30px 30px; }
      .expandable-panels__content ul li {
        padding-left: 30px;
        margin-bottom: 10px;
        position: relative;
        line-height: 22px; }
        .expandable-panels__content ul li:before {
          content: "";
          display: block;
          position: absolute;
          background-color: #000;
          background-color: var(--c-dark-neutral);
          width: 3px;
          height: 3px;
          border-radius: 50%;
          left: 0;
          top: 6px; }
  .expandable-panels--open .expandable-panels__top-container {
    margin-bottom: 20px;
    transition: all 0.2s ease; }
    .expandable-panels--open .expandable-panels__top-container:before {
      transform: scaleX(1); }
  .expandable-panels--open .expandable-panels__content {
    max-height: 99em !important;
    transition: all 1s ease-in-out; }
  .expandable-panels--open .expandable-panels__cross {
    background-color: #fff;
    background-color: var(--c-light-neutral); }
    .expandable-panels--open .expandable-panels__cross:before, .expandable-panels--open .expandable-panels__cross:after {
      transform: rotate(90deg);
      background-color: #008bcb;
      background-color: var(--c-primary); }
    .expandable-panels--open .expandable-panels__cross:after {
      left: 50%;
      right: 50%; }
  .expandable-panels--open h3, .expandable-panels--open h4, .expandable-panels--open h5, .expandable-panels--open .how-to__steps-item h5 a, .how-to__steps-item h5 .expandable-panels--open a {
    color: #fff;
    color: var(--c-light-neutral);
    z-index: 1; }
  @media all and (min-width: 640px) {
    .expandable-panels h3, .expandable-panels h4, .expandable-panels h5, .expandable-panels .how-to__steps-item h5 a, .how-to__steps-item h5 .expandable-panels a {
      padding-right: 40px; } }

.expandable-panels.expandable-panels--footer {
  border: none;
  margin: 0; }
  .expandable-panels.expandable-panels--footer .expandable-panels__item {
    border: 0;
    padding: 0;
    margin-top: 0;
    box-shadow: none; }
  .expandable-panels.expandable-panels--footer .expandable-panels__top-container {
    border-left: 0;
    border-color: #f5f5f5;
    border-color: var(--light-grey);
    border-style: solid;
    border-width: 0 0 2px 0;
    padding-bottom: 0;
    margin: 0; }
    .expandable-panels.expandable-panels--footer .expandable-panels__top-container:before {
      display: none; }
  .expandable-panels.expandable-panels--footer h3 {
    background-color: #fff;
    background-color: var(--c-light-neutral);
    color: #000;
    color: var(--c-dark-neutral);
    font-weight: 400;
    font-size: 20px;
    padding: 20px 30px; }
  .expandable-panels.expandable-panels--footer .expandable-panels__arrow {
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    width: 7px;
    height: 7px;
    transform: rotate(-45deg);
    display: block;
    transition: all .2s ease; }
  .expandable-panels.expandable-panels--footer .expandable-panels__content {
    padding: 0;
    margin: 0; }
    .expandable-panels.expandable-panels--footer .expandable-panels__content ul {
      padding: 20px 0;
      margin: 0; }
      .expandable-panels.expandable-panels--footer .expandable-panels__content ul li {
        padding: 20px 0 20px 50px;
        border-color: #f5f5f5;
        border-color: var(--light-grey);
        border-style: solid;
        border-width: 0 0 2px 0;
        display: block; }
        .expandable-panels.expandable-panels--footer .expandable-panels__content ul li:first-child {
          padding-top: 0; }
        .expandable-panels.expandable-panels--footer .expandable-panels__content ul li:before {
          display: none; }
        .expandable-panels.expandable-panels--footer .expandable-panels__content ul li a {
          color: #000;
          color: var(--c-dark-neutral); }
  .expandable-panels.expandable-panels--footer .expandable-panels--open .expandable-panels__arrow {
    transform: rotate(135deg); }
  @media all and (min-width: 640px) {
    .expandable-panels.expandable-panels--footer h3 {
      border: 0;
      padding: 0;
      color: #008bcb;
      color: var(--c-primary);
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 20px; }
    .expandable-panels.expandable-panels--footer .expandable-panels__top-container {
      padding: 0;
      border: 0; }
    .expandable-panels.expandable-panels--footer .expandable-panels__arrow {
      display: none; }
    .expandable-panels.expandable-panels--footer .expandable-panels__content {
      max-height: 100%; }
      .expandable-panels.expandable-panels--footer .expandable-panels__content ul {
        padding-top: 0px;
        padding-bottom: 0px; }
        .expandable-panels.expandable-panels--footer .expandable-panels__content ul li {
          border: 0;
          padding: 0;
          display: inline-block;
          margin-bottom: 15px;
          margin-right: 10px; }
          .expandable-panels.expandable-panels--footer .expandable-panels__content ul li:last-child {
            margin-right: 0px; } }
  @media all and (min-width: 1024px) {
    .expandable-panels.expandable-panels--footer {
      margin-top: 0; }
      .expandable-panels.expandable-panels--footer .expandable-panels__content ul li {
        display: block; } }

.expandable-panels.expandable-panels--faded .expandable-panels__item {
  border-style: solid;
  border-width: 1px 1px 1px 10px;
  border-color: #008bcb;
  border-color: var(--c-primary);
  padding: 0; }

.expandable-panels.expandable-panels--faded .expandable-panels__image {
  margin-right: 20px; }
  .expandable-panels.expandable-panels--faded .expandable-panels__image img {
    max-width: 100px;
    max-height: 140px;
    object-fit: cover; }
    @media all and (min-width: 768px) {
      .expandable-panels.expandable-panels--faded .expandable-panels__image img {
        max-width: 200px; } }

.expandable-panels.expandable-panels--faded .expandable-panels__content {
  max-height: 60px;
  padding: 20px; }

.expandable-panels.expandable-panels--faded .expandable-panels__top-container {
  border: 0;
  margin: 0;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #f5f5f5; }
  .expandable-panels.expandable-panels--faded .expandable-panels__top-container::before {
    display: none; }
  .expandable-panels.expandable-panels--faded .expandable-panels__top-container h2 {
    margin-bottom: 10px; }
  .expandable-panels.expandable-panels--faded .expandable-panels__top-container p {
    color: #008bcb;
    color: var(--c-primary);
    font-weight: 600; }

.expandable-panels.expandable-panels--faded .expandable-panels__bottom-container {
  background-color: #F39200;
  background-color: var(--c-primary-accent);
  padding: 20px 0;
  position: relative; }
  .expandable-panels.expandable-panels--faded .expandable-panels__bottom-container:after {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(to top, white 44%, rgba(255, 255, 255, 0) 100%);
    height: 140px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;
    transition: all 0.7s ease-in-out; }

.expandable-panels.expandable-panels--faded .expandable-panels__cross {
  margin: 0 auto;
  z-index: 1;
  background-color: #008bcb;
  background-color: var(--c-primary); }
  .expandable-panels.expandable-panels--faded .expandable-panels__cross:before, .expandable-panels.expandable-panels--faded .expandable-panels__cross:after {
    background-color: #fff;
    background-color: var(--c-light-neutral); }

@media all and (min-width: 1024px) {
  .expandable-panels.expandable-panels--faded {
    grid-column: 2/12; } }

.expandable-panels--open .expandable-panels__bottom-container {
  padding-top: 20px;
  position: relative; }
  .expandable-panels--open .expandable-panels__bottom-container:after {
    height: 10px !important;
    transition: all 0.2s ease-in-out;
    opacity: 0; }

.expandable-panels.expandable-panels--filters {
  grid-column: 1/-1; }
  .expandable-panels.expandable-panels--filters h3 {
    font-size: 24px; }
  .expandable-panels.expandable-panels--filters h4 {
    font-size: 18px;
    color: #008bcb;
    color: var(--c-primary); }
  .expandable-panels.expandable-panels--filters .expandable-panels__item {
    box-shadow: none; }
  @media all and (min-width: 1024px) {
    .expandable-panels.expandable-panels--filters .expandable-panels__item {
      border: 0;
      padding: 0; }
    .expandable-panels.expandable-panels--filters .expandable-panels__cross {
      display: none; }
    .expandable-panels.expandable-panels--filters .expandable-panels__top-container {
      display: none; }
    .expandable-panels.expandable-panels--filters .expandable-panels__content {
      max-height: 99em !important; } }
  .expandable-panels.expandable-panels--filters h4 {
    font-size: 20px;
    margin-bottom: 20px; }

.how-to {
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-color: #008bcb;
  border-color: var(--c-primary);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 3px;
  text-align: center; }
  @supports (display: grid) {
    .how-to {
      grid-column: 1 / -1; } }
  .how-to__steps-item {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px; }
    .how-to__steps-item:after {
      content: "";
      width: 2px;
      height: 40px;
      background-color: #008bcb;
      background-color: var(--c-primary);
      display: block;
      position: absolute;
      right: 50%;
      bottom: -20px; }
    .how-to__steps-item:last-child::after {
      display: none; }
    .how-to__steps-item--complete h5, .how-to__steps-item--complete .how-to__steps-item h5 a, .how-to__steps-item h5 .how-to__steps-item--complete a, .how-to__steps-item--complete p, .how-to__steps-item--complete a {
      color: #c7c7c7;
      color: var(--mid-grey); }
    .how-to__steps-item h5, .how-to__steps-item h5 a {
      margin-top: 20px;
      margin-bottom: 20px; }
      .how-to__steps-item h5 a:hover {
        text-decoration: underline; }
    .how-to__steps-item p {
      max-width: 350px;
      margin: 0 auto; }
    .how-to__steps-item svg g circle {
      fill: #fff;
      fill: var(--c-light-neutral); }
  @media all and (min-width: 550px) {
    @supports (display: grid) {
      .how-to {
        grid-column: 2/12; } } }
  @media all and (min-width: 768px) {
    .how-to__steps {
      display: flex;
      flex-wrap: wrap; }
    .how-to__steps-item {
      flex-basis: 48%;
      padding-left: 10px;
      padding-right: 10px; }
      @supports (display: grid) {
        .how-to__steps-item {
          flex-basis: 50%; } }
      .how-to__steps-item:after {
        display: none; }
      .how-to__steps-item--complete .how-to__content-wrap {
        position: relative; }
        .how-to__steps-item--complete .how-to__content-wrap:before {
          content: "";
          display: block;
          position: absolute;
          bottom: -43px;
          left: 50%;
          transform: translateX(-50%);
          height: 30px;
          width: 30px;
          border-radius: 50%;
          background-color: #008bcb;
          background-color: var(--c-primary); }
        .how-to__steps-item--complete .how-to__content-wrap:after {
          content: "";
          display: block;
          position: absolute;
          bottom: -35px;
          left: 50%;
          width: 5px;
          height: 10px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg) translateX(-50%); } }
  @media all and (min-width: 1200px) {
    .how-to__steps-item {
      flex-basis: 23%; }
      @supports (display: grid) {
        .how-to__steps-item {
          flex-basis: 25%; } }
      .how-to__steps-item:after {
        display: block;
        right: -32px;
        top: 100px;
        height: 2px;
        width: 65px; }
      .how-to__steps-item h5, .how-to__steps-item h5 a {
        min-height: 56px; }
    .how-to__content-wrap {
      padding-left: 10px;
      padding-right: 10px; } }

.search-result {
  grid-column: 1/-1;
  border-bottom: 1px solid #008bcb;
  padding: 25px 0 30px; }
  .search-result:first-of-type {
    border-top: 1px solid #008bcb;
    padding-top: 30px;
    margin-top: 60px; }
  .search-result a {
    color: #008bcb;
    color: var(--c-primary); }
    .search-result a:hover {
      text-decoration: underline; }

svg circle {
  fill: #008bcb;
  fill: var(--c-primary); }

svg path {
  fill: #fff;
  fill: var(--c-light-neutral); }

.btn-centre {
  text-align: center;
  grid-column: 1/-1; }

.card-container {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @supports (display: grid) {
    .card-container {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-column: 1 / -1; } }
  .card-container h1, .card-container h2, .card-container h3, .card-container h4, .card-container h5, .card-container .how-to__steps-item h5 a, .how-to__steps-item h5 .card-container a, .card-container h6, .card-container p, .card-container a {
    grid-column: 1 / -1;
    width: 100%; }
  .card-container h2 {
    margin-bottom: 0; }

.card-container.card-container--small {
  max-width: 850px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px; }
  @supports (display: grid) {
    .card-container.card-container--small {
      margin: 30px 0; } }
  @media all and (min-width: 550px) {
    @supports (display: grid) {
      .card-container.card-container--small {
        grid-column: 2/12; } } }
  @media all and (min-width: 768px) {
    .card-container.card-container--small .card {
      flex-basis: 39%; }
      @supports (display: grid) {
        .card-container.card-container--small .card {
          flex-basis: 42%; } }
    .card-container.card-container--small .card--icon-small {
      flex-basis: 36%; } }
  @media all and (min-width: 1024px) {
    .card-container.card-container--small .card--icon-small {
      padding-left: 30px;
      padding-right: 30px; } }
  @media all and (min-width: 1200px) {
    @supports (display: grid) {
      .card-container.card-container--small {
        grid-column: 3/11; } }
    .card-container.card-container--small .card--icon-small {
      padding-left: 70px;
      padding-right: 70px; } }

@media all and (min-width: 1024px) {
  .card-container.card-container--large .card {
    flex-basis: 31%; }
  @supports (display: grid) {
    .card-container.card-container--large {
      grid-column: 2 / 12; }
      .card-container.card-container--large .card {
        grid-column: span 4; } } }

@media all and (min-width: 550px) {
  .card-container.card-container--medium {
    grid-column: 2/12; } }

.card-container.card-container--x-small {
  max-width: 580px;
  margin: 0 auto; }
  @supports (display: grid) {
    .card-container.card-container--x-small {
      margin: 30px 0; } }
  @media all and (min-width: 550px) {
    @supports (display: grid) {
      .card-container.card-container--x-small {
        grid-column: 3/11; } } }
  @media all and (min-width: 768px) {
    .card-container.card-container--x-small .card {
      flex-basis: 33%; }
      @supports (display: grid) {
        .card-container.card-container--x-small .card {
          flex-basis: 45%; } } }
  @media all and (min-width: 1024px) {
    .card-container.card-container--x-small {
      grid-column: 3/11;
      padding: 0 20px; } }
  @media all and (min-width: 1200px) {
    .card-container.card-container--x-small {
      grid-column: 4/10; } }

.content-left-right {
  grid-column: 1/-1;
  flex-basis: 100%;
  margin-top: 40px;
  margin-bottom: 20px; }
  .content-left-right a {
    text-decoration: underline; }
  .content-left-right h2, .content-left-right h3, .content-left-right h4, .content-left-right h5, .content-left-right .how-to__steps-item h5 a, .how-to__steps-item h5 .content-left-right a, .content-left-right h6 {
    margin-bottom: 0; }
  .content-left-right .btn, .content-left-right input[type=submit] {
    text-decoration: none; }
  .content-left-right__item {
    text-align: center; }
    .content-left-right__item:last-child {
      margin-top: 20px; }
  @media all and (min-width: 1024px) {
    .content-left-right {
      display: flex;
      justify-content: space-between;
      align-items: flex-end; }
      .content-left-right__item {
        text-align: left; }
        .content-left-right__item:last-child {
          text-align: right; } }

.campaign-nav {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  background-color: #008bcb;
  background-color: var(--c-primary);
  padding: 15px 0;
  text-align: center; }
  .campaign-nav img {
    max-width: 100px; }

.grid {
  max-width: 1160px;
  margin: 0 auto; }
  @supports (display: grid) {
    .grid {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 20px; }
      .grid .ktc-border-root {
        grid-column: 1/-1; } }
  .grid h1, .grid h2, .grid h3, .grid h4, .grid h5, .grid .how-to__steps-item h5 a, .how-to__steps-item h5 .grid a, .grid h6, .grid p {
    grid-column: 1/-1; }

form .grid {
  align-items: end; }

.grid.grid--side-bar-left {
  display: flex;
  flex-wrap: wrap; }
  @supports (display: grid) {
    .grid.grid--side-bar-left {
      display: grid; } }
  @media all and (min-width: 1024px) {
    .grid.grid--side-bar-left .grid__item:nth-child(1) {
      flex-basis: 30%; }
    .grid.grid--side-bar-left .grid__item:nth-child(2) {
      flex-basis: 70%; }
    @supports (display: grid) {
      .grid.grid--side-bar-left .grid__item:nth-child(1) {
        grid-column: 1/5; }
      .grid.grid--side-bar-left .grid__item:nth-child(2) {
        grid-column: 5/13; } } }
  @media all and (min-width: 1200px) {
    @supports (display: grid) {
      .grid.grid--side-bar-left .grid__item:nth-child(1) {
        grid-column: 1/4; }
      .grid.grid--side-bar-left .grid__item:nth-child(2) {
        grid-column: 4/13; } } }

.grid.grid--two-column-2-1 {
  display: flex;
  flex-wrap: wrap;
  text-align: left; }
  @supports (display: grid) {
    .grid.grid--two-column-2-1 {
      display: grid; } }
  .grid.grid--two-column-2-1 p, .grid.grid--two-column-2-1 h1, .grid.grid--two-column-2-1 h2, .grid.grid--two-column-2-1 h3, .grid.grid--two-column-2-1 h4, .grid.grid--two-column-2-1 h5, .grid.grid--two-column-2-1 .how-to__steps-item h5 a, .how-to__steps-item h5 .grid.grid--two-column-2-1 a, .grid.grid--two-column-2-1 h6 {
    flex-basis: 100%; }
  .grid.grid--two-column-2-1 .campaign-txt {
    margin-top: 0; }
  @media all and (min-width: 1024px) {
    .grid.grid--two-column-2-1 > .grid__item:nth-child(1) {
      flex-basis: 60%; }
    .grid.grid--two-column-2-1 > .grid__item:nth-child(2) {
      flex-basis: 30%; }
    .grid.grid--two-column-2-1 > .grid__item img {
      width: auto;
      max-width: 100%; }
    @supports (display: grid) {
      .grid.grid--two-column-2-1 > .grid__item:nth-child(1) {
        grid-column: 1/7; }
      .grid.grid--two-column-2-1 > .grid__item:nth-child(2) {
        grid-column: 8/13; } } }

.grid.grid--two-column-3-1 {
  display: flex;
  flex-wrap: wrap;
  text-align: left; }
  @supports (display: grid) {
    .grid.grid--two-column-3-1 {
      display: grid; } }
  .grid.grid--two-column-3-1 .campaign-txt {
    margin-top: 0; }
  @media all and (min-width: 1024px) {
    .grid.grid--two-column-3-1 .grid__item:nth-child(1) {
      flex-basis: 70%; }
    .grid.grid--two-column-3-1 .grid__item:nth-child(2) {
      flex-basis: 20%; }
    @supports (display: grid) {
      .grid.grid--two-column-3-1 .grid__item:nth-child(1) {
        grid-column: 1/9; }
      .grid.grid--two-column-3-1 .grid__item:nth-child(2) {
        grid-column: 10/13; } } }

.grid.grid--two-column-1-1 {
  display: flex;
  flex-wrap: wrap; }
  @supports (display: grid) {
    .grid.grid--two-column-1-1 {
      display: grid;
      grid-template-columns: repeat(2, 6fr); } }
  .grid.grid--two-column-1-1 .grid__item {
    flex-basis: calc((100% /2) - 20px); }
    @supports (display: grid) {
      .grid.grid--two-column-1-1 .grid__item {
        grid-column: span 1; } }
  @media screen and (max-width: 640px) {
    .grid.grid--two-column-1-1 {
      grid-template-columns: 12fr;
      flex-basis: 100%; }
      .grid.grid--two-column-1-1 .grid__item:not(:last-child) .form-field .form-control {
        margin-bottom: 0; } }

@supports (display: grid) {
  .grid__item {
    grid-column: 1/-1; } }

.grid-masonry {
  grid-column: 1/-1; }
  .grid-masonry__item {
    margin-bottom: 20px;
    width: 100%; }
  @media all and (min-width: 768px) {
    .grid-masonry__item {
      width: 48%;
      margin-bottom: 20px; }
      .grid-masonry__item:nth-child(odd) {
        margin-right: 2%; } }
  @media all and (min-width: 1024px) {
    .grid-masonry {
      grid-column: 2/12; }
      .grid-masonry__results {
        grid-column: 2/12 !important; } }

h1, h2, h3, h4, h5, .how-to__steps-item h5 a, h6, p, li, a, label, input, div, button, textarea {
  font-family: "Raleway", sans-serif; }

h1 {
  font-size: 32px;
  line-height: 44px;
  font-weight: 600;
  color: #fff;
  color: var(--c-light-neutral);
  margin-bottom: 20px; }

h2 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 300;
  margin-bottom: 20px;
  font-weight: 600;
  color: #008bcb;
  color: var(--c-primary); }

h3, h3 a {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: #008bcb;
  color: var(--c-primary);
  margin-bottom: 10px; }

h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #008bcb;
  color: var(--c-primary); }

h5, .how-to__steps-item h5 a {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #008bcb;
  color: var(--c-primary); }

p {
  margin-bottom: 5px;
  line-height: 26px;
  font-size: 16px;
  color: #000;
  color: var(--c-dark-neutral); }

a {
  font-size: 16px;
  color: #008bcb;
  color: var(--c-primary); }

li {
  margin-bottom: 5px; }

@media all and (min-width: 1024px) {
  h1 {
    font-size: 48px;
    line-height: 64px; }
  h2 {
    font-size: 38px;
    line-height: 50px; }
  h3, h3 a {
    font-size: 32px;
    line-height: 48px; }
  h4 {
    font-size: 28px;
    line-height: 40px; }
  h5, .how-to__steps-item h5 a {
    font-size: 20px;
    line-height: 28px; } }

.opening-text {
  color: #008bcb;
  color: var(--c-primary);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 40px; }

.footer {
  border-top: 15px solid #008bcb;
  position: relative; }
  .footer:before {
    content: "";
    display: block;
    position: absolute;
    background-color: #F39200;
    height: 10px;
    width: 100%;
    top: 0;
    right: 0;
    top: 5px; }
  .footer:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #F9D900;
    height: 5px;
    width: 100%;
    top: 0;
    right: 0; }
  .footer .container {
    padding: 0; }
  .footer__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
  .footer__item {
    flex-basis: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box; }
    .footer__item:first-child {
      order: 4; }
    .footer__item:nth-child(2) {
      padding-top: 0px; }
  .footer__logos {
    text-align: center; }
    .footer__logos .bluebird-logo {
      max-width: 120px;
      margin-right: 20px; }
    .footer__logos .ukhca-logo {
      max-width: 60px; }
  .footer__item-large {
    flex-basis: 100%;
    grid-row-gap: 40px;
    text-align: center;
    border-color: #f5f5f5;
    border-color: var(--light-grey);
    border-style: solid;
    border-width: 0 0 2px 0;
    padding-bottom: 40px; }
  .footer__item-grid {
    display: flex;
    flex-wrap: wrap; }
    @supports (display: grid) {
      .footer__item-grid {
        display: grid;
        grid-row-gap: 40px; } }
  .footer__buttons {
    padding-left: 30px;
    padding-right: 30px; }
    .footer__buttons .btn:first-child, .footer__buttons input:first-child[type=submit] {
      margin-bottom: 20px; }
  .footer__news-letter-sign-up {
    border-color: #f5f5f5;
    border-color: var(--light-grey);
    border-style: solid;
    border-width: 2px 0 0 0;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px; }
    .footer__news-letter-sign-up input {
      box-sizing: border-box; }
    .footer__news-letter-sign-up p {
      padding-top: 20px;
      padding-bottom: 20px; }
    .footer__news-letter-sign-up button {
      width: 100%; }
  @supports (display: grid) {
    .footer__social-icons {
      grid-row: 3; } }
  .footer__sub-footer {
    text-align: center;
    border-color: #f5f5f5;
    border-color: var(--light-grey);
    border-style: solid;
    border-width: 2px 0 0 0;
    padding: 40px 30px; }
    .footer__sub-footer a {
      color: #000;
      color: var(--c-dark-neutral);
      font-size: 14px; }
    .footer__sub-footer ul {
      margin-bottom: 20px; }
      .footer__sub-footer ul li {
        display: inline-block;
        margin-bottom: 10px; }
        .footer__sub-footer ul li:after {
          content: "|";
          display: inline-block;
          padding-left: 10px;
          padding-right: 10px;
          color: #787a7b;
          color: var(--c-mid-neutral); }
        .footer__sub-footer ul li:last-child:after {
          display: none; }
        .footer__sub-footer ul li a {
          color: #787a7b;
          color: var(--c-mid-neutral); }
          .footer__sub-footer ul li a:hover {
            color: #000;
            color: var(--c-dark-neutral); }
    .footer__sub-footer p {
      color: #787a7b;
      color: var(--c-mid-neutral);
      font-size: 14px; }
  @media all and (min-width: 500px) {
    .footer__buttons {
      padding: 0 16%; }
    .footer__news-letter-sign-up {
      padding: 30px 16% 30px; } }
  @media all and (min-width: 640px) {
    .footer .container {
      padding-left: 20px;
      padding-right: 20px; }
    .footer__container {
      flex-direction: row;
      padding-top: 30px; }
    .footer__item-grid {
      grid-template-columns: repeat(12, 1fr); }
    .footer__item {
      flex-basis: 60%;
      padding-top: 0px; }
      .footer__item:first-child {
        order: 1;
        flex-basis: 40%; }
      .footer__item::nth-child(2) {
        order: 0; }
    .footer__item-large {
      order: 2;
      padding-top: 40px;
      border-color: #f5f5f5;
      border-color: var(--light-grey);
      border-style: solid;
      border-width: 2px 0 0 0; }
    .footer__item-grid {
      column-gap: 40px; }
    .footer__logos {
      text-align: right; }
    .footer__buttons {
      grid-row: span 2;
      grid-column: span 5;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: end; }
      .footer__buttons .btn--full-width {
        display: inline-block; }
        .footer__buttons .btn--full-width:first-child {
          margin-bottom: 0px; }
    .footer__social-icons {
      align-self: end;
      text-align: left;
      flex-basis: 100%; }
      @supports (display: grid) {
        .footer__social-icons {
          grid-column: span 5; } }
    .footer__news-letter-sign-up {
      text-align: left;
      grid-row: span 3;
      border: 0;
      padding: 0;
      flex-basis: 100%; }
      @supports (display: grid) {
        .footer__news-letter-sign-up {
          grid-column: span 7; } }
      .footer__news-letter-sign-up .btn--full-width {
        text-align: center; } }
  @media all and (min-width: 768px) {
    .footer .btn.btn--full-width, .footer input.btn--full-width[type=submit] {
      display: block; } }
  @media all and (min-width: 1024px) {
    .footer__container {
      padding-top: 60px;
      padding-bottom: 30px; }
    .footer__item {
      flex-basis: 23%; }
      .footer__item:first-child {
        order: 0;
        flex-basis: 17%; }
    .footer__item-large {
      flex-basis: 60%;
      border: 0;
      padding-top: 0px;
      padding-bottom: 0px; }
    .footer__news-letter-sign-up h4 {
      font-size: 26px; }
    .footer__logos {
      text-align: left; }
      .footer__logos .bluebird-logo {
        margin-right: 0px;
        margin-bottom: 20px;
        display: block; }
      .footer__logos .ukhca-logo {
        max-width: 85px; }
    .footer__sub-footer {
      padding-left: 0px;
      padding-right: 0px; }
      .footer__sub-footer ul {
        text-align: left; }
        .footer__sub-footer ul li {
          margin-bottom: 5px; }
    @supports (display: grid) {
      .footer__item {
        border-color: #f5f5f5;
        border-color: var(--light-grey);
        border-style: solid;
        border-width: 0 2px 0 0; }
        .footer__item:nth-child(2) {
          padding-left: 30px; }
      .footer__item-large {
        padding-left: 30px; }
      .footer__item-grid {
        column-gap: 20px; }
      .footer__buttons {
        grid-column: span 6;
        grid-row: span 1; }
      .footer__social-icons {
        grid-column: span 6;
        grid-row: 2; }
      .footer__news-letter-sign-up {
        grid-column: span 6;
        grid-row: span 2; } } }
  @media all and (min-width: 1200px) {
    .footer__container {
      padding-top: 80px; }
    .footer__item {
      padding-left: 0px; }
      .footer__item:nth-child(2) {
        padding-left: 0px; }
    .footer__item-large {
      padding-left: 0px;
      flex-basis: 59%; }
    .footer__buttons {
      flex-basis: 50%;
      order: 1;
      align-self: flex-start;
      justify-content: space-between; }
    .footer__social-icons {
      flex-basis: 50%;
      order: 2; }
    .footer__news-letter-sign-up {
      flex-basis: 49%;
      order: 0; }
    .footer__sub-footer {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .footer__sub-footer ul {
        margin-bottom: 0; }
      .footer__sub-footer p {
        color: #000;
        color: var(--c-dark-neutral); }
    @supports (display: grid) {
      .footer__item:nth-child(2) {
        padding-left: 60px; }
      .footer__item-grid {
        grid-template-columns: repeat(16, 1fr);
        column-gap: 40px; }
      .footer__buttons {
        grid-column: 2/8;
        order: 0; }
        .footer__buttons .btn:first-child, .footer__buttons input:first-child[type=submit] {
          margin-bottom: 20px; }
      .footer__social-icons {
        grid-column: 2/8; }
      .footer__news-letter-sign-up {
        grid-column: 8/ 17;
        padding-left: 60px;
        border-color: #f5f5f5;
        border-color: var(--light-grey);
        border-style: solid;
        border-width: 0 0 0 2px; } } }

.franchise-footer-contact-bar {
  background-color: #008bcb;
  background-color: var(--c-primary);
  color: #fff;
  color: var(--c-light-neutral);
  text-align: center; }
  .franchise-footer-contact-bar h5, .franchise-footer-contact-bar .how-to__steps-item h5 a, .how-to__steps-item h5 .franchise-footer-contact-bar a {
    color: #fff;
    color: var(--c-light-neutral);
    font-size: 18px; }
  .franchise-footer-contact-bar a {
    text-decoration: underline;
    font-weight: 600;
    color: #fff;
    color: var(--c-light-neutral); }
  .franchise-footer-contact-bar p {
    color: #fff;
    color: var(--c-light-neutral); }
  .franchise-footer-contact-bar .container {
    padding: 0; }
  .franchise-footer-contact-bar__contact {
    position: relative;
    padding: 30px 20px;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #fff;
    border-color: var(--c-light-neutral);
    width: 100%; }
    .franchise-footer-contact-bar__contact p {
      margin-bottom: 15px; }
    .franchise-footer-contact-bar__contact a[href^="tel:"] {
      text-decoration: none;
      font-size: 18px; }
    .franchise-footer-contact-bar__contact span {
      padding-right: 5px; }
      .franchise-footer-contact-bar__contact span svg {
        max-width: 15px; }
        .franchise-footer-contact-bar__contact span svg circle {
          fill: #fff;
          fill: var(--c-light-neutral); }
        .franchise-footer-contact-bar__contact span svg path {
          fill: #008bcb;
          fill: var(--c-primary); }
  .franchise-footer-contact-bar__address {
    padding: 30px 20px 10px; }
    .franchise-footer-contact-bar__address p {
      max-width: 400px;
      margin: 0 auto; }
  @media all and (min-width: 1024px) {
    .franchise-footer-contact-bar__container {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .franchise-footer-contact-bar__contact {
      border: 0;
      text-align: left;
      padding-right: 0;
      width: 80%; }
      .franchise-footer-contact-bar__contact a[href^="tel:"] {
        font-size: 22px; }
      .franchise-footer-contact-bar__contact span svg {
        max-height: 26px;
        max-width: 20px; }
    .franchise-footer-contact-bar__address {
      text-align: right;
      padding-left: 0; }
      .franchise-footer-contact-bar__address p {
        max-width: 100%; } }

.full-width {
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 30px; }
  .full-width p {
    color: #fff;
    color: var(--c-light-neutral); }
  .full-width h2, .full-width h3, .full-width h4, .full-width h5, .full-width .how-to__steps-item h5 a, .how-to__steps-item h5 .full-width a, .full-width h6 {
    color: #fff;
    color: var(--c-light-neutral); }
  .full-width__link {
    color: #fff;
    color: var(--c-light-neutral);
    text-decoration: underline;
    text-align: center;
    display: block; }
    @supports (display: grid) {
      .full-width__link {
        grid-column: 1/-1; } }
  .full-width img {
    width: auto;
    max-width: 100%; }
  .full-width .card {
    background-color: #fff;
    background-color: var(--c-light-neutral); }
    .full-width .card h3, .full-width .card h4, .full-width .card h5, .full-width .card .how-to__steps-item h5 a, .how-to__steps-item h5 .full-width .card a {
      color: #008bcb;
      color: var(--c-primary); }
    .full-width .card p {
      color: #000;
      color: var(--c-dark-neutral); }
  .full-width .cta-bar {
    margin: 0; }
  .full-width + .container {
    margin-top: 60px; }
  .full-width .contact-form.contact-form--campaign {
    margin-top: auto; }
  @media all and (min-width: 550px) {
    @supports (display: grid) {
      .full-width .quote {
        grid-column: 3/11; } } }

.full-width.full-width--primary {
  background-color: #008bcb;
  background-color: var(--c-primary); }

.full-width.full-width--dark-blue {
  background-color: #003a78;
  background-color: var(--c-secondary); }

.homepage-services {
  padding-bottom: 40px; }
  .homepage-services__content {
    margin-top: 60px; }
    .homepage-services__content p {
      margin-bottom: 20px;
      max-width: 500px; }
    .homepage-services__content a {
      text-decoration: underline; }
  .homepage-services tbody tr td {
    display: block;
    margin-bottom: 10px; }
  .homepage-services div.cqc-widget {
    border-color: #008bcb !important;
    border-color: var(--c-primary) !important; }
  @media all and (min-width: 1200px) {
    .homepage-services {
      margin: 0 auto; }
      .homepage-services p {
        max-width: 700px; } }

.container {
  padding-left: 20px;
  padding-right: 20px; }
  @media all and (min-width: 900px) {
    .container {
      margin: 0 auto;
      max-width: 800px; } }
  @media all and (min-width: 1024px) {
    .container {
      max-width: 940px; } }
  @media all and (min-width: 1200px) {
    .container {
      max-width: 1200px; } }

@media all and (min-width: 550px) {
  @supports (display: grid) {
    .container.container--small {
      grid-column: 3/11; } } }

@media all and (min-width: 768px) {
  .container.container--small .card {
    flex-basis: 45%; }
  .container.container--small .card--icon-small {
    flex-basis: 36%; } }

@media all and (min-width: 1024px) {
  .container.container--small .card--icon-small {
    padding-left: 30px;
    padding-right: 30px; } }

@media all and (min-width: 1200px) {
  .container.container--small .card--icon-small {
    padding-left: 70px;
    padding-right: 70px; } }

@supports (display: grid) {
  .container.container--medium {
    grid-column: 1/-1; } }

@media all and (min-width: 550px) {
  @supports (display: grid) {
    .container.container--medium {
      grid-column: 2/12; } } }

.main-content {
  padding-top: 120px;
  display: block; }
  .main-content--campaign {
    padding-top: 92px; }
  @media all and (min-width: 1024px) {
    .main-content {
      padding-top: 155px; }
      .main-content--campaign {
        padding-top: 92px; } }

.nav {
  position: fixed;
  z-index: 30;
  width: 100%;
  top: 0;
  background-color: #fff;
  background-color: var(--c-light-neutral); }
  .nav--franchise .nav__mobile-call span {
    display: none; }
    @media all and (min-width: 360px) {
      .nav--franchise .nav__mobile-call span {
        display: block; } }
  .nav__secondary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    z-index: 2; }
  .nav__logo {
    padding: 0 0 5px 20px; }
    .nav__logo .bluebird-logo {
      max-width: 80px; }
      .nav__logo .bluebird-logo--themed {
        margin-top: -14px; }
  .nav__right {
    flex-basis: 68%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px 5px 0;
    max-height: 85px; }
    .nav__right .social-icons {
      display: none; }
    .nav__right svg {
      width: 100%;
      max-width: 30px; }
      .nav__right svg path {
        fill: #008bcb;
        fill: var(--c-primary); }
  .nav__corona-link {
    text-decoration: underline; }
    .nav__corona-link--desktop {
      display: none; }
  .nav__franchise-links {
    display: none; }
  .nav__social li {
    margin-bottom: 0; }
    .nav__social li svg {
      max-height: 90px; }
  .nav__mobile-call {
    margin-right: 35px; }
    .nav__mobile-call a {
      text-decoration: underline;
      display: flex;
      align-items: center; }
      .nav__mobile-call a svg {
        max-width: 20px;
        margin-right: 10px; }
        .nav__mobile-call a svg path {
          fill: #fff;
          fill: var(--c-light-neutral); }
  .nav__search {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    padding: 20px;
    margin: -20px 15px -20px -20px; }
    .nav__search svg {
      max-width: 25px; }
    .nav__search:before {
      content: "";
      display: block;
      position: absolute;
      width: 2px;
      height: 35px;
      background-color: #f5f5f5;
      background-color: var(--light-grey);
      left: 0;
      top: 16px; }
  .nav__menu {
    display: inline-block;
    padding: 20px;
    margin: -20px;
    position: relative; }
    .nav__menu--active .nav__menu-close {
      transform: translate3d(0, 0, 0) !important; }
    .nav__menu:before {
      content: "";
      display: block;
      position: absolute;
      width: 2px;
      height: 35px;
      background-color: #f5f5f5;
      background-color: var(--light-grey);
      left: 0;
      top: 12px; }
  .nav__menu-close {
    background-color: #F39200;
    background-color: var(--c-primary-accent);
    transform: translate3d(100%, 0, 0);
    transition: .4s ease 0.3s;
    position: absolute;
    height: 106%;
    width: 110%;
    top: -5px;
    margin-left: -20px;
    z-index: 0; }
    .nav__menu-close svg {
      max-width: 23px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
      .nav__menu-close svg circle {
        fill: #fff;
        fill: var(--c-light-neutral); }
  .nav__search-bar {
    max-height: 0;
    transition: all 0.5s ease-in-out;
    width: 100%;
    overflow: hidden;
    background-color: #0057b7;
    border-bottom: 2px solid #fff;
    display: flex;
    padding: 0 20px; }
    .nav__search-bar form {
      display: inherit;
      width: 100%; }
    .nav__search-bar .nav__social-dropdown {
      display: none; }
    .nav__search-bar .search-input {
      margin: 20px 0; }
      .nav__search-bar .search-input input {
        margin-bottom: 0;
        border: none;
        padding: 14px 14px 14px 50px; }
      .nav__search-bar .search-input svg path {
        fill: #008bcb;
        fill: var(--c-primary); }
    .nav__search-bar button {
      margin: 20px 0; }
    .nav__search-bar .search-button {
      margin-left: 10px; }
  .nav__ctas {
    background-color: #008bcb;
    background-color: var(--c-primary);
    flex-basis: 100%;
    display: flex;
    position: relative;
    z-index: 2; }
    .nav__ctas--desktop {
      display: none; }
    .nav__ctas--mobile:before {
      content: "";
      display: block;
      position: absolute;
      background-color: #F39200;
      height: 10px;
      width: 100%;
      top: 0;
      right: 0;
      top: auto;
      bottom: -15px; }
    .nav__ctas--mobile:after {
      content: "";
      display: block;
      position: absolute;
      background-color: #F9D900;
      height: 5px;
      width: 100%;
      top: 0;
      right: 0;
      top: auto;
      bottom: -5px; }
    .nav__ctas a {
      color: #fff;
      color: var(--c-light-neutral); }
  .nav__cta-container {
    padding: 20px 0;
    flex-basis: 50%;
    text-align: center; }
    .nav__cta-container:first-child {
      border-right: 1px solid #fff; }
  @media all and (min-width: 550px) {
    .nav__right .social-icons {
      display: block;
      margin-right: 40px; }
      .nav__right .social-icons ul li svg {
        max-width: 32px; } }
  @media all and (min-width: 1024px) {
    .nav__secondary {
      padding: 10px; }
    .nav__logo {
      padding: 0; }
      .nav__logo .bluebird-logo {
        max-width: 110px; }
        .nav__logo .bluebird-logo--themed {
          margin-top: -16px;
          margin-bottom: -5px; }
    .nav__corona-link {
      font-weight: 600; }
      .nav__corona-link--desktop {
        display: block;
        margin-right: 28px; }
      .nav__corona-link--mobile {
        display: none !important; }
    .nav__mobile-triggers {
      display: none; }
    .nav__mobile-call {
      display: none; }
    .nav__franchise-links {
      display: flex;
      justify-content: flex-end;
      margin-right: 30px; }
      .nav__franchise-links span {
        display: flex;
        align-items: center;
        margin-right: 20px;
        max-height: 30px;
        min-width: 210px; }
        @supports (display: grid) {
          .nav__franchise-links span {
            min-width: auto; } }
        .nav__franchise-links span:first-child {
          display: none; }
        .nav__franchise-links span:last-child {
          margin-right: 0; }
        .nav__franchise-links span p {
          display: inline-block;
          margin-bottom: 0; }
        .nav__franchise-links span a {
          text-decoration: underline; }
        .nav__franchise-links span svg {
          margin-right: 10px;
          max-width: 20px; }
          .nav__franchise-links span svg path {
            fill: #fff;
            fill: var(--c-light-neutral); }
    .nav__right {
      flex-basis: 90%;
      padding-bottom: 0;
      padding-right: 0; }
      @supports (display: grid) {
        .nav__right {
          flex-basis: auto; } }
      .nav__right .social-icons {
        margin-right: 30px; }
    .nav__search-bar {
      position: absolute;
      max-height: 100%;
      max-width: 322px;
      bottom: -41px;
      border: none;
      right: 0;
      z-index: 3;
      padding: 0; }
      @supports (display: grid) {
        .nav__search-bar {
          bottom: -57px; } }
      .nav__search-bar .search-input {
        margin: 0;
        max-width: 180px; }
        .nav__search-bar .search-input input {
          padding: 14px 14px 14px 40px; }
        .nav__search-bar .search-input svg {
          width: 20px; }
          .nav__search-bar .search-input svg path {
            fill: #008bcb;
            fill: var(--c-primary); }
      .nav__search-bar .search-button button {
        margin: 0;
        padding: 12px 20px; }
    .nav__ctas {
      flex-basis: auto;
      background: none;
      padding: 0;
      max-width: 510px; }
      .nav__ctas--mobile {
        display: none; }
      .nav__ctas--desktop {
        display: flex; }
      .nav__ctas .nav__cta-container:last-child a {
        background-color: #F39200;
        background-color: var(--c-primary-accent); }
        .nav__ctas .nav__cta-container:last-child a:hover {
          background-color: #008bcb;
          background-color: var(--c-primary); }
      .nav__ctas a {
        background-color: #F8A61C;
        background-color: var(--c-primaryAccentLight);
        color: #fff;
        color: var(--c-light-neutral);
        font-size: 16px;
        border-radius: 3px;
        padding: 12px 20px;
        font-weight: 400;
        transition: 0.3s ease;
        text-decoration: none;
        display: inline-block; }
        .nav__ctas a:hover {
          background-color: #008bcb;
          background-color: var(--c-primary); }
    .nav__cta-container {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-basis: auto;
      margin-right: 10px; }
      .nav__cta-container:last-child {
        border: none;
        margin-right: 10px; } }
  @media all and (min-width: 1100px) {
    .nav__secondary {
      padding: 10px 50px; }
    .nav__search-bar {
      max-width: 337px; }
      .nav__search-bar .search-input {
        max-width: 195px; }
    .nav__franchise-link span {
      min-width: 190px; } }
  @media all and (min-width: 1200px) {
    .nav__search-bar {
      max-width: 317px;
      right: 25px; }
      .nav__search-bar .nav__social-dropdown {
        display: block;
        min-width: 115px; }
        .nav__search-bar .nav__social-dropdown li {
          padding-right: 5px; }
          .nav__search-bar .nav__social-dropdown li:last-child {
            padding-right: 0; }
          .nav__search-bar .nav__social-dropdown li svg:hover circle {
            fill: #003a78;
            fill: var(--secondary); }
          .nav__search-bar .nav__social-dropdown li svg:hover path {
            fill: #fff;
            fill: var(--c-light-neutral); }
        .nav__search-bar .nav__social-dropdown svg {
          max-width: 25px; }
          .nav__search-bar .nav__social-dropdown svg circle {
            fill: #fff;
            fill: var(--c-light-neutral); }
          .nav__search-bar .nav__social-dropdown svg path {
            fill: #008bcb;
            fill: var(--c-primary); }
      .nav__search-bar:not(.active-search) .search-input {
        cursor: pointer; }
      .nav__search-bar .search-input {
        max-width: 200px;
        padding-left: 20px;
        transform: translateX(80%); }
        .nav__search-bar .search-input:before {
          content: "";
          display: block;
          position: absolute;
          background-color: #fff;
          background-color: var(--c-light-neutral);
          width: 1px;
          height: 39px;
          top: 5px;
          left: 0; }
        .nav__search-bar .search-input svg {
          padding-left: 18px; }
          .nav__search-bar .search-input svg path {
            fill: #fff;
            fill: var(--c-light-neutral); }
        .nav__search-bar .search-input input {
          background-color: #0057b7; }
          .nav__search-bar .search-input input::placeholder {
            color: #fff;
            color: var(--c-light-neutral); }
          .nav__search-bar .search-input input::-ms-input-placeholder {
            color: #fff;
            color: var(--c-light-neutral); }
      .nav__search-bar .search-button {
        transform: translateX(100%);
        opacity: 0;
        transition: all 1s ease-in-out; }
        .nav__search-bar .search-button .btn:hover, .nav__search-bar .search-button input:hover[type=submit] {
          background-color: #F39200;
          background-color: var(--c-primary-accent); }
    .nav__search-bar.nav__search-bar--franchise {
      height: 66px;
      bottom: -65px; }
      .nav__search-bar.nav__search-bar--franchise .nav__social-dropdown {
        position: absolute;
        top: 20px; }
      .nav__search-bar.nav__search-bar--franchise .search-input, .nav__search-bar.nav__search-bar--franchise .search-button {
        margin-top: 10px; }
    .nav__franchise-links span:first-child {
      display: flex; } }
  @media all and (min-width: 1250px) {
    .nav__search-bar {
      max-width: 352px; }
      .nav__search-bar .search-input {
        max-width: 235px; } }
  @media all and (min-width: 1300px) {
    .nav__search-bar {
      right: 60px;
      max-width: 351px; }
      .nav__search-bar .search-input {
        max-width: 270px;
        transform: translateX(75%); } }
  @media all and (min-width: 1500px) {
    .nav__secondary {
      padding: 10px 100px; }
    .nav__search-bar {
      right: 90px; } }

.active-search {
  height: auto;
  max-height: 200px; }
  @media all and (min-width: 1200px) {
    .active-search .search-input {
      transform: translateX(0);
      transition: 0.5s all ease-in 0.1s; }
      .active-search .search-input input {
        background-color: #fff;
        background-color: var(--c-light-neutral);
        transition: 0.3s all ease; }
        .active-search .search-input input::placeholder {
          color: #c7c7c7;
          color: var(--mid-grey);
          transition: 0.3s all ease; }
      .active-search .search-input svg path {
        fill: #008bcb;
        fill: var(--c-primary); }
    .active-search .search-button {
      transform: translateX(0);
      opacity: 1;
      transition: all 0.3s ease-in 0.3s; }
    .active-search .nav__social-dropdown ul li {
      transform: translateY(-100%);
      opacity: 0;
      transition: all 0.2s; }
      .active-search .nav__social-dropdown ul li:first-child {
        transition-delay: 0.3s; }
      .active-search .nav__social-dropdown ul li:nth-child(3) {
        transition-delay: 0.1s; }
      .active-search .nav__social-dropdown ul li:nth-child(2) {
        transition-delay: 0.2s; } }

.body-active-nav {
  overflow: hidden;
  height: 100%; }
  .body-active-nav .nav {
    height: 100vh; }
  @media all and (min-width: 1024px) {
    .body-active-nav {
      overflow: auto; } }

.nav__primary {
  background-color: #fff;
  background-color: var(--c-light-neutral);
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 124px;
  bottom: 0;
  transform: translateX(100%);
  transition: .4s ease;
  opacity: 0;
  overflow-x: scroll;
  padding-bottom: 100px;
  -webkit-overflow-scrolling: touch; }
  .nav__primary::-webkit-scrollbar {
    display: none; }
  .nav__primary .nav__franchise-name {
    display: flex;
    align-items: center; }
    .nav__primary .nav__franchise-name a {
      color: #008bcb;
      color: var(--c-primary);
      width: 100%; }
      .nav__primary .nav__franchise-name a span {
        width: 25px; }
        .nav__primary .nav__franchise-name a span svg {
          margin-right: 10px;
          max-width: 20px; }
  .nav__primary a {
    color: #000;
    color: var(--c-dark-neutral);
    text-decoration: none;
    padding: 30px 0 30px 30px;
    display: block;
    line-height: 22px;
    border-color: #f5f5f5;
    border-color: var(--light-grey);
    border-style: solid;
    border-width: 0 0 2px 0; }
    .nav__primary a li:after {
      display: none; }
  .nav__primary .btn, .nav__primary input[type=submit] {
    color: #fff;
    color: var(--c-light-neutral);
    padding: 15px 30px;
    margin-top: 40px; }
  .nav__primary li {
    margin-bottom: 0; }
  .nav__primary .nav__trigger {
    display: block;
    padding: 30px 0 30px 30px;
    border-color: #f5f5f5;
    border-color: var(--light-grey);
    border-style: solid;
    border-width: 0 0 2px 0;
    position: relative;
    cursor: pointer; }
    .nav__primary .nav__trigger:after {
      content: "";
      display: block;
      position: absolute;
      right: 30px;
      top: 40%;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      width: 7px;
      height: 7px;
      transform: rotate(45deg);
      display: block;
      transition: all .2s ease; }
  .nav__primary .nav__dropdown-button {
    max-width: 400px;
    margin: 0 auto;
    padding: 0 30px;
    text-align: center; }
    .nav__primary .nav__dropdown-button .btn, .nav__primary .nav__dropdown-button input[type=submit] {
      text-align: center; }
  .nav__primary .nav__social-dropdown {
    margin-top: 40px; }
    .nav__primary .nav__social-dropdown ul {
      text-align: center; }
      .nav__primary .nav__social-dropdown ul li {
        margin-right: 10px; }
        .nav__primary .nav__social-dropdown ul li:last-child {
          margin-right: 0; }
        .nav__primary .nav__social-dropdown ul li a {
          border: none;
          padding: 0; }
  .nav__primary .nav__level-two {
    background-color: #fff;
    background-color: var(--c-light-neutral);
    left: initial;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 124px;
    bottom: 0;
    transform: translateX(100%);
    transition: .4s ease;
    width: 100%;
    padding-bottom: 100px;
    z-index: 1;
    opacity: 0;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; }
    .nav__primary .nav__level-two::-webkit-scrollbar {
      display: none; }
    .nav__primary .nav__level-two ul {
      margin-bottom: 30px; }
  .nav__primary .nav__level-two-back {
    background-color: #f5f5f5;
    background-color: var(--light-grey);
    padding: 29px 38px;
    position: relative;
    margin-right: 20px;
    margin-left: -11px; }
    .nav__primary .nav__level-two-back:before {
      content: "";
      display: block;
      position: absolute;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      width: 7px;
      height: 7px;
      left: 44%;
      top: 44%;
      transform: rotate(-135deg); }
  .nav__primary .nav__level-primary {
    color: #008bcb;
    color: var(--c-primary);
    padding: 30px 0 30px 13px;
    border-color: #f5f5f5;
    border-color: var(--light-grey);
    border-style: solid;
    border-width: 0 0 2px 0; }
    .nav__primary .nav__level-primary:after {
      display: none !important; }
  .nav__primary .nav__level-three {
    background-color: #fff;
    background-color: var(--c-light-neutral);
    height: 100%;
    left: initial;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 124px;
    bottom: 0;
    transform: translateX(100%);
    transition: .4s ease;
    width: 100%;
    z-index: 1;
    opacity: 0;
    padding-bottom: 300px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; }
    .nav__primary .nav__level-three::-webkit-scrollbar {
      display: none; }
  .nav__primary .nav__info-card {
    background-color: #003a78;
    background-color: var(--c-secondary);
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px; }
    .nav__primary .nav__info-card h5, .nav__primary .nav__info-card .how-to__steps-item h5 a, .how-to__steps-item h5 .nav__primary .nav__info-card a, .nav__primary .nav__info-card p, .nav__primary .nav__info-card a {
      color: #fff;
      color: var(--c-light-neutral); }
    .nav__primary .nav__info-card h5, .nav__primary .nav__info-card .how-to__steps-item h5 a, .how-to__steps-item h5 .nav__primary .nav__info-card a {
      margin-bottom: 20px; }
    .nav__primary .nav__info-card p {
      margin-bottom: 20px; }
    .nav__primary .nav__info-card a {
      text-decoration: underline;
      border: none;
      padding: 0; }
    .nav__primary .nav__info-card .link-arrow:after {
      padding: 0px 8px 1px;
      font-weight: 600; }
  .nav__primary .nav__view-more a {
    text-decoration: underline; }
  .nav__primary .nav__view-more span {
    font-size: 20px; }
  @media all and (min-width: 1024px) {
    .nav__primary {
      transform: none;
      background: none;
      max-height: 81px;
      top: 89px;
      opacity: 1;
      padding: 0 40px;
      overflow-x: visible;
      background-color: #0057b7;
      box-shadow: 0px 6px 22px -10px rgba(0, 0, 0, 0.57); }
      .nav__primary:before {
        content: "";
        display: block;
        position: absolute;
        background-color: #ffd700;
        height: 10px;
        width: 100%;
        top: 0;
        right: 0;
        top: auto;
        bottom: 0; }
      .nav__primary:after {
        content: "";
        display: block;
        position: absolute;
        background-color: #ffd700;
        height: 5px;
        width: 100%;
        top: 0;
        right: 0;
        top: auto;
        bottom: 9px; }
      .nav__primary .nav__franchise-name {
        display: none; }
      .nav__primary .btn, .nav__primary input[type=submit] {
        display: none; }
      .nav__primary .nav__social-dropdown {
        display: none; }
      .nav__primary .nav__non-level-link {
        color: #fff;
        color: var(--c-light-neutral); }
      .nav__primary li {
        display: inline-block;
        color: #fff;
        color: var(--c-light-neutral);
        margin-bottom: 0px;
        margin-right: -4px;
        transition: 0.3s ease; }
        .nav__primary li:hover {
          background-color: #004694; }
          .nav__primary li:hover > .nav__trigger-arrow:after {
            transform: rotate(-45deg) translateY(100%);
            transform-origin: left bottom; }
        .nav__primary li a {
          border: none;
          display: inline-block;
          padding: 22px 20px; }
      .nav__primary .nav__trigger {
        border: none;
        padding: 25px 35px 25px 15px; }
        .nav__primary .nav__trigger:after {
          border-color: #fff;
          border-color: var(--c-light-neutral);
          right: 10px;
          width: 6px;
          height: 6px;
          transform: rotate(135deg); }
      .nav__primary .nav__level-two {
        display: none;
        opacity: 1;
        transform: none;
        border-radius: 5px;
        box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.24);
        top: 66px;
        left: 40px;
        bottom: auto;
        width: 960px;
        height: auto;
        min-height: 460px;
        overflow: hidden;
        position: absolute;
        padding-bottom: 0;
        background-color: #f5f5f5;
        background-color: var(--light-grey); }
        .nav__primary .nav__level-two ul {
          order: 1;
          flex-basis: 33%;
          border-color: #f5f5f5;
          border-color: var(--light-grey);
          border-style: solid;
          border-width: 0 2px 0 0;
          border-color: #f5f5f5;
          background-color: #fff;
          background-color: var(--c-light-neutral);
          margin-bottom: 0;
          min-height: 450px; }
          .nav__primary .nav__level-two ul li {
            color: #008bcb;
            color: var(--c-primary);
            font-size: 18px;
            font-weight: 600;
            display: block;
            border-color: #f5f5f5;
            border-color: var(--light-grey);
            border-style: solid;
            border-width: 0 0 2px 0;
            border-color: #f5f5f5; }
            .nav__primary .nav__level-two ul li:after {
              border-color: #000;
              border-color: var(--c-dark-neutral);
              transform: rotate(45deg); }
            .nav__primary .nav__level-two ul li:hover {
              background: none; }
            .nav__primary .nav__level-two ul li:hover > .nav__trigger:after {
              transform: rotate(45deg); }
            .nav__primary .nav__level-two ul li a {
              font-weight: 400;
              padding-left: 25px; }
              .nav__primary .nav__level-two ul li a:hover {
                text-decoration: underline; }
        .nav__primary .nav__level-two .nav__trigger {
          padding-left: 25px;
          line-height: 26px; }
          .nav__primary .nav__level-two .nav__trigger:after {
            border-color: #000;
            border-color: var(--c-dark-neutral);
            right: 25px;
            width: 6px;
            height: 6px;
            transform: rotate(45deg); }
          .nav__primary .nav__level-two .nav__trigger--active {
            background: #f5f5f5; }
      .nav__primary .nav__level-two.child-menu-active {
        display: flex;
        flex-wrap: wrap; }
      .nav__primary .nav__level-three {
        opacity: 1;
        background-color: #f5f5f5;
        background-color: var(--light-grey);
        background-color: #f5f5f5 !important;
        background-color: var(--light-grey) !important;
        transform: none;
        padding-bottom: 0;
        position: absolute;
        top: 0;
        height: 100%;
        display: none;
        max-height: 600px;
        width: 29%;
        overflow: hidden; }
        @supports (display: grid) {
          .nav__primary .nav__level-three {
            width: 37%; } }
        .nav__primary .nav__level-three .nav__info-card {
          display: none; }
        .nav__primary .nav__level-three a {
          display: block;
          border-width: 0;
          font-weight: 400;
          font-size: 14px;
          padding: 17px 45px 0 45px;
          line-height: 22px; } }
      @media all and (min-width: 1024px) and (min-width: 1024px) and (max-height: 640px) {
        .nav__primary .nav__level-three a {
          padding-top: 11px; } }
  @media all and (min-width: 1024px) {
        .nav__primary .nav__level-three li {
          border: none !important; }
      .nav__primary .nav__level-three.child-menu-active {
        display: block; }
      .nav__primary .nav__info-card {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        flex-basis: 30%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 5px 0 0 5px; }
        .nav__primary .nav__info-card h5, .nav__primary .nav__info-card .how-to__steps-item h5 a, .how-to__steps-item h5 .nav__primary .nav__info-card a {
          font-size: 22px; }
        .nav__primary .nav__info-card a {
          padding: 0; }
      .nav__primary .nav__dropdown-button {
        display: none; }
      .nav__primary .nav__level-two-back, .nav__primary .nav__level-primary {
        display: none !important; }
      .nav__primary .child-menu-active {
        display: block; } }
  @media all and (min-width: 1100px) {
    .nav__primary .nav__trigger {
      padding: 25px 30px 25px 20px; } }
  @media all and (min-width: 1200px) {
    .nav__primary li a {
      padding: 22px 25px; }
    .nav__primary .nav__trigger {
      padding: 25px 50px 25px 25px; }
      .nav__primary .nav__trigger:after {
        right: 20px; } }
  @media all and (min-width: 1300px) {
    .nav__primary .nav__level-two {
      left: 0; }
    .nav__primary .nav__list-item {
      position: relative; } }
  @media all and (min-width: 1500px) {
    .nav__primary {
      padding: 0 80px; }
      .nav__primary .nav__level-two {
        width: 960px; } }

.child-menu-active, .main-menu-active {
  opacity: 1 !important;
  transform: none !important; }

.rich-text {
  grid-column: 1/-1;
  max-width: 650px;
  margin: 0 auto; }
  @supports (display: grid) {
    .rich-text {
      max-width: auto;
      margin: 0; } }
  .rich-text p {
    margin-bottom: 15px; }
  .rich-text ul, .rich-text ol {
    margin: 30px 0 30px 30px; }
    .rich-text ul li, .rich-text ol li {
      padding-left: 30px;
      margin-bottom: 10px;
      position: relative;
      line-height: 26px; }
      .rich-text ul li:before, .rich-text ol li:before {
        content: "";
        display: block;
        position: absolute;
        background-color: #000;
        background-color: var(--c-dark-neutral);
        width: 3px;
        height: 3px;
        border-radius: 50%;
        left: 0;
        top: 6px; }
  .rich-text ol {
    list-style-type: decimal; }
    .rich-text ol li:before {
      display: none; }
  .rich-text a {
    text-decoration: underline; }
  .rich-text .image-list {
    list-style: none;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 0;
    display: block; }
    @supports (display: grid) {
      .rich-text .image-list {
        display: flex; } }
    .rich-text .image-list li {
      padding: 0; }
      .rich-text .image-list li:before {
        display: none; }
      .rich-text .image-list li img {
        margin: auto; }
  .rich-text .btn, .rich-text input[type=submit] {
    margin-top: 20px; }
  .rich-text .social-icons ul {
    margin: 0; }
    .rich-text .social-icons ul li {
      padding-left: 0;
      margin-bottom: 0; }
      .rich-text .social-icons ul li:before {
        display: none; }
      .rich-text .social-icons ul li a svg circle {
        fill: #008bcb;
        fill: var(--c-primary); }
      .rich-text .social-icons ul li a svg path {
        fill: #fff;
        fill: #fff; }
  .rich-text table {
    width: 100%;
    border: 1px solid #d0d0d0; }
    .rich-text table th {
      border: 1px solid #d0d0d0;
      padding: 15px;
      text-align: left; }
    .rich-text table tr td {
      padding: 20px;
      min-width: 130px;
      line-height: 20px;
      border: 1px solid #d0d0d0; }
  @media all and (min-width: 640px) {
    .rich-text {
      grid-column: 2/12; } }
  @media all and (min-width: 1024px) {
    .rich-text {
      grid-column: 3/11; }
      .rich-text .video-placeholder, .rich-text img {
        width: 134%;
        margin: 40px -40px 40px -96px; }
      .rich-text .quote {
        margin-left: -94px;
        width: 133%; }
      .rich-text .content-left-right {
        margin-left: -80px;
        margin-right: -80px; } }
  @media all and (min-width: 1200px) {
    .rich-text {
      grid-column: 4/10; } }

.k-disallow--deafult .k-section--default {
  display: none; }

.k-disallow--deafult .k-disallow-message--default {
  display: block; }

.k-disallow--default-inverted .k-section--default-inverted {
  display: none; }

.k-disallow--default-inverted .k-disallow-message--default-inverted {
  display: block; }

.k-disallow--two-column-2-1 .k-disallow-message--two-column-2-1 {
  display: block; }

.k-disallow--two-column-2-1 .k-section--two-column-2-1 {
  display: none; }

.k-section--two-column-2-1 {
  max-width: 1100px; }
  .k-section--two-column-2-1 .page-builder-txt {
    max-width: 700px; }

.text-editor p {
  margin-bottom: 15px; }

.text-editor ul,
.text-editor ol {
  margin: 30px 0 30px 30px; }

.text-editor ul {
  list-style-type: initial; }

.text-editor ol {
  list-style-type: decimal; }

.k-disallow-message {
  display: none;
  border-width: 2px;
  border-style: solid;
  border-color: #D32D27;
  border-color: var(--red); }
  .k-disallow-message p {
    color: var(--red);
    color: #D32D27;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700; }

.about-us h2 {
  text-align: center; }

.about-us-detail {
  margin-bottom: 150px; }
  .about-us-detail .usps-btn {
    grid-column: 1/-1;
    text-align: center; }

.campaign-page .card-container--large {
  grid-column: 1/-1; }

.campaign-page .video-placeholder {
  max-width: 800px;
  margin: 0 auto; }

.campaign-page div[id^="google-recaptcha-form"] {
  margin-top: 25px;
  margin-bottom: 25px; }

@media all and (min-width: 550px) {
  .campaign-page .video-placeholder, .campaign-page .card-container--large {
    grid-column: 2/12; }
  .campaign-page .grid.grid--text-left-image-right .grid__item:nth-child(1) {
    grid-column: 2/7; }
  .campaign-page .grid.grid--text-left-image-right .grid__item:nth-child(2) {
    grid-column: 8/12; } }

@media all and (min-width: 1024px) {
  .campaign-page .video-placeholder {
    grid-column: 3/11; } }

@media all and (min-width: 1024px) {
  .campaign-1 .campaign-txt {
    grid-column: 2/12; } }

.careers {
  margin-bottom: 100px; }
  .careers .card-container {
    margin-bottom: 60px; }

.error .footer__container {
  display: block; }

@media all and (min-width: 640px) {
  .error .footer__item-grid {
    display: block; }
  .error .footer__logos {
    text-align: center; }
  .error .footer__buttons {
    display: block; } }

@media all and (min-width: 768px) {
  .error .footer .btn.btn--full-width, .error .footer input.btn--full-width[type=submit] {
    display: inline-block; } }

@media all and (min-width: 1024px) {
  .error .footer__container {
    display: flex; }
  .error .footer__item:first-child {
    flex-basis: 35%; }
  .error .footer__logos {
    text-align: left; }
    .error .footer__logos .bluebird-logo {
      display: inline-block; }
  .error .footer__item-grid {
    text-align: right; }
  .error .footer__item-large {
    display: flex;
    justify-content: flex-end;
    align-items: center; } }

@media all and (min-width: 1024px) {
  .faqs .expandable-panels {
    grid-column: 3/11; } }

.franchise-contact-us .full-width {
  margin-top: 0; }

.franchise-contact-us .card-container--small {
  max-width: 900px;
  margin: 0 auto; }

@media all and (min-width: 1024px) {
  .franchise-contact-us .rich-text {
    grid-column: 2/12;
    padding: 0 10px;
    max-width: 100%; }
  .franchise-contact-us .card-container--small .card {
    flex-basis: 33%; } }

@media all and (min-width: 1200px) {
  .franchise-contact-us .rich-text {
    grid-column: 3/11; } }

.news {
  margin-bottom: 150px; }
  @media all and (min-width: 1024px) {
    .news .pagination-container {
      grid-column: 1/-1; } }

.campaign-page-builder .main-content .hero {
  overflow: visible !important; }

.campaign-page-builder .homepage-hero.homepage-hero--campaign {
  overflow: visible !important; }

.campaign-page-builder .hero.hero--fallback {
  overflow: visible !important; }

.dist-editor-btn {
  background-color: #008bcb !important; }

.search {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 100px; }
  @supports (display: grid) {
    .search {
      grid-column: 1/-1;
      max-width: initial;
      margin: initial; } }
  .search form {
    display: flex;
    margin-bottom: 40px;
    position: relative; }
    .search form span {
      position: absolute;
      top: 15px;
      left: 15px; }
      .search form span svg {
        width: 20px;
        max-height: 25px; }
        .search form span svg path {
          fill: #008bcb;
          fill: var(--c-primary); }
    .search form input {
      margin-bottom: 0;
      margin-right: 20px;
      padding-left: 45px; }
  .search .pagination-container {
    margin-top: 20px; }
  @media all and (min-width: 550px) {
    .search {
      grid-column: 2/12; } }
  @media all and (min-width: 1024px) {
    .search {
      grid-column: 3/11; } }

.vacancies {
  margin-bottom: 60px; }
  .vacancies .expandable-panels {
    margin: 0; }
    .vacancies .expandable-panels__item {
      margin: 0; }

.find-your-local-office {
  grid-column: 1/-1;
  margin-bottom: 60px; }
  @media all and (min-width: 550px) {
    .find-your-local-office {
      grid-column: 2/12; } }
  .find-your-local-office .local-office-cta__inputs {
    margin-top: 20px; }
  .find-your-local-office .card-container {
    margin-top: 10px; }
  .find-your-local-office .google-maps {
    margin-bottom: 40px; }

.fader {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0; }
  .fader img {
    opacity: 0;
    transition: all 1.5s; }
    .fader img.active {
      opacity: 1; }
