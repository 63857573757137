.video-popup {
	position:fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	z-index: 35;
  display:none;
  &__close{
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.6;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      @include background-light-neutral;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
	&__container{
    max-width: 800px;
    width: 90%;
		position:relative;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	&__video {
		position: relative;
		overflow: hidden;
		padding-top: 56.25%;
		width: 100%;
		@include background-dark-neutral;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
		}
	}
	&--active{
		display:block;
	}
	@media all and (min-width:$screen-xl) {
		&__container {
			max-width: 940px;
		}
	}
}