.image-list {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  li {
    display: inline-block;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    img {
      max-height: 100px;
      width: auto;
      max-width: 100%;
    } 
  }
  
  
  // below for a generic image list commented out so that styling is just for the awards
  // li:first-child:nth-last-child(2),
  // li:first-child:nth-last-child(2)~li, {
  //   flex-basis:49%; 
  //   margin-right: 1%; 
  //   &:last-child {
  //     margin-right: 0;
  //   }
  // }
 
  // below for a generic image list commented out so that styling is just for the awards
  /* three items */
  // li:first-child:nth-last-child(3),
  // li:first-child:nth-last-child(3)~li,
  // li:first-child:nth-last-child(4),
  // li:first-child:nth-last-child(4)~li,
  // li:first-child:nth-last-child(5),
  // li:first-child:nth-last-child(5)~li,
  // li:first-child:nth-last-child(8),
  // li:first-child:nth-last-child(8)~li {
  //   flex-basis: 32%;
  //   margin-right: 1.3%;    

  // }

  // li:first-child:nth-last-child(4),
  // li:first-child:nth-last-child(4)~li,
  // li:first-child:nth-last-child(5),
  // li:first-child:nth-last-child(5)~li,
  // li:first-child:nth-last-child(8),
  // li:first-child:nth-last-child(8)~li {
  //   margin-right: auto;
  // }

  // // six items and seven items
  // li:first-child:nth-last-child(6),
  // li:first-child:nth-last-child(6)~li, 
  // li:first-child:nth-last-child(7),
  // li:first-child:nth-last-child(7)~li {
  //   flex-basis: 32%;
  // }

  
  // @media all and (min-width: $screen-extra-small) {
  //   li {
  //     flex-basis:50%;    
  //   }
  //   /* five items */
  //   // li:first-child:nth-last-child(5),
  //   // li:first-child:nth-last-child(5)~li,
  //   // li:first-child:nth-last-child(10),
  //   // li:first-child:nth-last-child(10)~li {
  //   //   flex-basis:19%; 
  //   //   margin-right: 0.5%; 
  //   // }
  //   // li:first-child:nth-last-child(6),
  //   // li:first-child:nth-last-child(6)~li {
  //   //   flex-basis: 15%;
  //   // }
  // }
  // @media all and (min-width: $screen-small) {
  //   li:first-child:nth-last-child(4),
  //   li:first-child:nth-last-child(4)~li,
  //   li:first-child:nth-last-child(5),
  //   li:first-child:nth-last-child(5)~li, 
  //   li:first-child:nth-last-child(7),
  //   li:first-child:nth-last-child(7)~li,
  //   li:first-child:nth-last-child(8),
  //   li:first-child:nth-last-child(8)~li {
  //     flex-basis: 23%;
  //   }
  //   li:first-child:nth-last-child(4),
  //   li:first-child:nth-last-child(4)~li, {
  //     margin-right: 1.3%;
  //   }
  // } 
  @media all and (min-width: $large-tablet) {
    li {
      margin-right: 20px;
    }
  } 
}
