.card-container.card-container--small {
  .card.card--icon-medium {
    padding: 30px;
    @media all and (min-width:$screen-xxs){
      padding-left: 40px;
      padding-right: 40px;
    }  
    @media all and (min-width:$large-tablet){
      padding-left: 60px;
      padding-right: 60px;
    }
  } 
}