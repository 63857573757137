@import './helpers.scss';
// COLOURS -----------------------------------

// Primary colours

$c-primary: #008bcb;
$c-secondary: #003a78;
$c-primaryAccent: #F39200;
$c-secondaryAccent: #F39200;
$c-tertiaryAccent: #F9D900;

$c-primaryAccentLight: #F8A61C;

$c-darkNeutral: #000;
$c-midNeutral: #787a7b;
$c-lightNeutral: #fff;

// secondary colours
$mid-grey: #c7c7c7;
$light-grey: #f5f5f5;
$red: #D32D27;
$error-red: #f33f3f;

//font colours
$primary-header-colour: $c-primary;

// UK Variables
:root {
  --c-primary: #008bcb;
  --c-secondary: #003a78;
  --c-primary-accent: #F39200;
  --c-secondary-accent: #F39200;
  --c-tertiaryAccent: #F9D900;

  --c-primaryAccentLight: #F8A61C;

  --c-dark-neutral: #000;
  --c-mid-neutral: #787a7b;
  --c-light-neutral: #fff;

  
  // secondary colour
  --mid-grey: #c7c7c7; 
  --light-grey: #f5f5f5;
  --red: #D32D27;
  --error-red: #f33f3f;
  // font colours
  --primary-header-colour: --primary;

}

$Shadow: 0 10px 24px 0 rgba(0,0,0,0.24);
$border-radius: 3px;

// FONT STYLING ----------------------------------- 
$font: 'Raleway', sans-serif;
$light: 300;
$regular: 400;
$semi-bold: 600;
$bold: 700;
@mixin primary-font-styling {
  font-weight: $semi-bold;
  color: $c-primary;
  color: var(--c-primary);
}


// Basic hover effects
$basicHover: 0.3s ease;


// MEDIA SIZES -----------------------------------
// A mobile first approach  has been taken with this project. All media queries should be min-width

$screen-xxl:1600px;
$screen-xl:1200px;
// tablet landscape (break point for the nav to change into mobile design)
$large-tablet:1024px;
$screen-large:900px;
$screen-medium:768px;
$screen-small:640px;
$screen-extra-small:550px;
$screen-xxs:440px;






