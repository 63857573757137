.content-left-right {
  grid-column: 1/-1;
  flex-basis: 100%;
  @include margin-top(4);
  @include margin-bottom(2);
  a {
    text-decoration: underline;
  }
  h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }
  .btn {
    text-decoration: none;
  }
  &__item {
    text-align: center;
    &:last-child {
      margin-top: 20px;
    }
  }
  @media all and (min-width:$large-tablet) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &__item {
      text-align: left;
      &:last-child {
        text-align: right;
      }
    }
  }
}