.card-container.card-container--x-small{
  max-width: 580px;
  margin: 0 auto;
  @supports(display:grid) {
    margin: 30px 0;
  }
  @media all and (min-width: $screen-extra-small){
    @supports(display:grid) {
      grid-column: 3/11;
    }
  }
  @media all and (min-width:$screen-medium){
    .card {
      flex-basis: 33%;
      @supports(display:grid) {
        flex-basis: 45%;
      }
    }    
  }  
  @media all and (min-width:$large-tablet){
    grid-column: 3/11;
    padding: 0 20px;
  }
  @media all and (min-width:$screen-xl){
    grid-column: 4/10;
  }
}