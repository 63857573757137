.social-icons {
  li {
    display: inline-block;
    padding-right: 5px;
    &:last-child {
      padding-right: 0;
    }
    svg {
      width: 100%;
      max-width: 40px;      
      &:hover  {
        
        circle {
          fill: $c-primaryAccent;
          fill: var(--c-primary-accent);
        }
      }
      circle {
        fill: $c-primary;
        fill: var(--c-primary);
        transition: $basicHover;          
      }
      path {
        fill: $c-lightNeutral;
      }
    }
  } 
}