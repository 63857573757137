.campaign-nav {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  @include background-primary;
  padding: 15px 0;
  text-align: center;
  img {
    max-width: 100px;
  }
}