.image-list.image-list--grid {
  @supports(display:grid) {
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    grid-gap: 20px;
 
  }
  img {
    max-height: 150px;
    object-fit: cover;
  }
  @media all and (min-width:$screen-small) {
    img {
      max-height: 200px;
    }
  }
}