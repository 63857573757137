.card.card--detail-button {
  @include color-primary;
  text-decoration: none;
  font-size: 20px;
  font-weight: $semi-bold;
  position: relative;
  transition: 0.3s all;
  grid-column: 1/-1;
  padding: 20px 50px 20px 26px;
  line-height: 22px;
  &:before {
    @include before-after;
    @include card-hover(scaleX(0.03), 0 50%);
  }
  &:after {    
    left: 0;
    width: 13px;
    background: linear-gradient(to right,$c-primaryAccent 0,$c-primaryAccent 30%,70%,$c-tertiaryAccent 70%,$c-tertiaryAccent 100%);
  }
  span {
    position: absolute;
    top: 18px;
    right: 15px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    @include background-primary;
    transition: 0.5s 0.2s all;
    &:after {
      @include before-after;
      @include arrow(45deg);
      border-top: 2px solid $c-lightNeutral;
      border-right: 2px solid $c-lightNeutral;
      top: 8px;
      left: 6px;    
      transition: 0.5s 0.2s all;  
    }
  }  
  &:hover {
    @include color-light-neutral;
    
    &:before {
      transform: scaleX(1);
    } 
    & > span{     
      @include background-light-neutral;
      &:after {
        border-top: 2px solid $c-primary;
        border-right: 2px solid $c-primary;
      }     
    }
  }
  
  @media all and (min-width: $screen-medium) {
    grid-column: span 6;
    
  }
}