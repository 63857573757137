.page-builder-img {
  text-align: right;
  grid-column: 1/-1;
  @include vertical-margin(3);
  @media all and (min-width: $screen-extra-small) {   
    grid-column: 2/12;            
  }
  @media all and (min-width:$large-tablet) {  
    grid-column: 3/11;   
  }
}