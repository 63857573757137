.cta-bar {
  text-align: center;
  grid-column: 1/-1;
  @include vertical-margin(3);
  &__icon {
    max-width: 60px;
    margin: 0 auto;
    @include background-primary;
    border-radius: 50%;
    padding: 15px;
    .fa, .fas, .far {
      font-size: 30px;
      @include color-light-neutral;
    }
  }
  h2, h3, h4, h5, h6 {
    margin: 20px 0;
  }
  
}