.card-container.card-container--large {
  @media all and (min-width:$screen-medium) {
    .card {
      // &:nth-child(even) {
      //   margin-right: auto;
      // }
    }
  }
  @media all and (min-width:$large-tablet) {
    .card{
      flex-basis: 31%;
    }
    @supports(display:grid) {
      grid-column: 2 / 12;
      .card {
        grid-column: span 4;
      }
    }
  }  
}