.card {
  @include standard-border(1px 1px 1px 1px);
  padding: 20px; 
  display: flex;
  flex-wrap: wrap;
  transition: $basicHover;
  box-shadow: 0 0 17px -5px rgba(0, 0, 0, 0.23);
  margin-top: 15px;
  flex-basis: 100%;
  margin-bottom: 10px;
  @supports(display:grid) {
    grid-column: 1 / -1;
    margin-top: 0;
    margin-bottom: 0;
  }
  p {
    @include margin-bottom(2);
    flex-basis: 100%;
  }
  a[href^="tel:"] {
    @include color-primary;
    text-decoration: none;
    font-size: 28px;
    font-weight: $semi-bold;
    @include margin-bottom(3);
    @include margin-top(1);
    display: block;
    //fixes ie problem 
    flex-basis: 100%;
  }
  a[href^="mailto:"] {
    @include margin-bottom(1);
    display: block;
    font-weight: $semi-bold;
    font-size: 18px;
    word-break: break-all;
  }
  h3, h4, h5 {
    @include margin-bottom(2);
    flex-basis: 100%;
  }
  form {
    width: 100%;
  }
  button {
    width: 100%;
    padding: 15px 30px;
    @include background-primary-accent;
  }
  &__miles {
    display: flex;
    flex-basis: 100%;
    align-items: baseline;
    @include margin-bottom(2);
    p {
      flex-basis: auto;
      margin-right: 20px;
      width: auto!important;
    }
    a {
      font-size: 18px;
      font-weight: $semi-bold;
      width: auto!important;
    }
  }
  &__opening-times {
    margin: 0 auto;
    // max-width: 230px;
    text-align: left;
    p {
      margin-bottom: 0;
    }
  }
  &__content {
    width: 100%;
  }
  &__date {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: flex-end;
    svg {
      width: 100%;
      max-width: 30px;
    }
    p {
      font-weight: $bold;
      font-size: 18px;
      flex-basis: auto;
      margin-bottom: 0;
      margin-left: 10px;
      @include color-dark-neutral;
    }
  }
  
  .local-office-cta__inputs {
    display: block;
    input {
      margin-right: 0;
      width: 90%;
      margin-bottom:15px;
    }
  }
  .page-builder-txt {
    margin-top: 0;
  }
  .container {
    padding: 0;
  }
  .grid {
    display: block;
  }
  @media all and (min-width:$screen-medium){
    // margin-right: auto;
    // margin-bottom: 15px;
    flex-basis: 44%;
    margin-right: auto;
    margin-bottom: 15px;
    @supports(display:grid) {
      grid-column: span 6;
      margin-right: 0;
      margin-bottom: 0;
    } 
    &:last-child {
      margin-right: 0;
    }
    a[href^="tel:"] {
      font-size: 32px;
    }
    h3 {
      font-size: 29px;
      line-height: 38px;
    }
  }
}

