.container {    
    @include horizontal-padding(2);
    @media all and (min-width: $screen-large) {
        margin: 0 auto;
        max-width: 800px;
    }
    @media all and (min-width: $large-tablet) {
        max-width: 940px;
    }
    @media all and (min-width: $screen-xl) {
        max-width: 1200px;
    }
}
 