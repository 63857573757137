.quote {
  grid-column: 1/-1;
  position: relative;
  text-align: center;
  padding: 0 20px;
  @include vertical-margin(6);
  &__quote-mark {
    width: 100%;
    max-width: 60px;
    position: absolute;
    &:first-child {
      top: -38px; 
      left: 0;
    }
    &:last-child {
      bottom: -38px;
      right: 0;
    }
  }  
  &__content {
    margin-bottom: 30px;
  }
  p {      
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
    font-weight: $semi-bold;
  }
  svg {
    g {
      fill: $c-primaryAccent;
      fill: var(--c-primary-accent);
    }
    path {
      fill: $c-primaryAccent;
      fill: var(--c-primary-accent);
      mask: none;
    }
  }
  @media all and (min-width: $screen-medium) {
    &__quote-mark {
      max-width: 90px;            
    }
    &__content {     
      max-width: 80%;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    p {      
      font-size: 20px;
      line-height: 28px;
    }
    
  } 
  @media all and (min-width: $screen-large) {
    &__quote-mark {
      max-width: 130px;
      &:first-child {
          top: -30px;     
      }
      &:last-child {
        bottom: -30px;

      }    
    }      
  }       
}