.local-office-cta{
  @include vertical-padding(6);
  @include margin-top(12);
  @include background-primary;
  &__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    @supports(display:grid){
      grid-column: 1 / -1;
    }
  }
  h3, p, label {
    color: $c-lightNeutral;
    color: var(--c-light-neutral);
  }
  h3, p {
    @include margin-bottom(2);
  }
  h3 {
    font-size: 28px;
  }
  label {
    text-align: left;
  }
  &__content {
    order: 1;
  }
  &__button {
    .btn {
      width: 100%;
    }
  }
  &__error {
    padding: 2rem 0;
    color: $error-red;
  }
  &__icon {
    align-self: flex-start;
    width: 100%;
    @include margin-bottom(2);
    svg {
      width: 100%;
      max-width: 70px;
      max-height: 115px;
      padding: 45px 50px 20px 55px;
      border-radius: 50%;
      background-color: $c-primary;
      background-color: var(--c-primary);
      circle {
        fill: $c-primary;
        fill: var(--c-primary);
      }
      g {
        transform: scale(0.6) translate(11px, 11px);
        stroke-width: 0.1;
      }
    }
  }
  &__image {
    margin-bottom: 24px;
    img {
      max-width: 115px;
    }
  }
  @media all and (min-width: $screen-medium) {
    &__container {
      text-align: left;
      flex-direction: row;
      justify-content: space-between;
      max-width: 100%;
      align-items: center;
      margin: 0;
      @supports(display:grid){
        grid-column: 2/12;
      }
    }
    &__content {
      order: 0;
      max-width: 496px;
    }
    &__inputs {
      display: flex;
      align-items: flex-end;
      input {
        margin-bottom: 0;
        @include margin-right(2);
        width: 220px;
      }
    }
    &__icon {
      width: auto;
      align-self: auto;
      svg {
        max-width: 50px;
        padding: 30px 35px 15px;
      }
    }
    &__image {
      margin-bottom: 0;
      img {
        max-width: 170px;
      }
    }
  }
  @media all and (min-width: $large-tablet) {
    &__content {
      max-width: 100%;
      h3 {
        font-size: 34px;
      }
    }
    input {
      width: 270px;
    }
  }
  @media all and (min-width: $screen-xl) {
    &__icon {
      svg {
        max-width: 80px;
        padding: 40px 50px 20px;
      }
    }
  }
}
