.modal-popup {
	display: none;
	position: fixed;
  // width: 100%;
  min-width: 100%;
	height: 100%;
  top:0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
	z-index: 30;
	&__container {
    max-width: 600px;
    width: 100%;
    @include background-secondary;
    padding: 40px;
    position: relative;
    border-radius: 3px;
	}
	&__close {
		@include background-primary-accent;
		padding: 15px;
		cursor:pointer;
		position: absolute;
		right:0;
    top: 0;   
    z-index: 10; 
    svg {
      width: 100%;
      max-width: 30px;
      max-height: 30px;
      circle {
        fill: $c-lightNeutral;
        fill: var(--c-light-neutral);        
      }
      path {
        fill: $c-primary;
        fill: var(--c-primary);
      }
    }
  }
  &--nav-form {
    background: none;
    .modal-popup__container {
      overflow-y: scroll;
      height: 100vh;
      top: 124px;
      padding: 25px;
      padding-bottom: 300px;
      max-width: 100%;
      -webkit-overflow-scrolling: touch;      
      &::-webkit-scrollbar { 
        display: none; 
      }
      .contact-form {        
        h2 {
          font-size: 28px;
          margin-bottom: 10px;
        }        
        .grid {          
          &:last-child {
            .grid__item {
              @supports (display: grid) {
                grid-column: 1/-1;
              }
              iframe {
                left: 0;
              }              
            }
          }
        }
      }
    }
  }
	&--active{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
  form .formwidget-submit-text {
    margin-top: 24px;
    position: relative;
  }
  @media all and (min-width:$large-tablet) {
    &--nav-form {
      .modal-popup__container {
        position: absolute;
        right: 170px;
        top: 10px;
        max-width: 600px;
        max-height: 735px;
        padding-bottom: 30px;
        height: auto;
        // padding: 20px;
        .contact-form {
         
          .ktc-default-section {
            margin-bottom: 10px;
          }
          &__text {
            margin-bottom: 24px;
            line-height: 20px;
          }
          textarea {
            min-height: 65px;
          }
          input[type="text"] {
            padding: 10px;
          }
          .grid {
            margin-bottom: 10px;
            &__item {
              flex-basis: 100%;
            }
          }
        }
        
      }
    }
    &__container {
      padding: 30px;
    }
    &__close {
      padding: 20px;
      z-index: 55;
    }
  }
}