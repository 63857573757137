.card-popup {
  padding: 0;  
  &__content {
    @include vertical-padding(2);
    @include horizontal-padding(2);    
  }
  &__icon {
    @include background-light-grey;  
    cursor: pointer;   
    padding: 10px;
    text-align: center; 
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    svg {
      width: 100%;
      max-width: 30px;
      // position: absolute;
      circle {
        transition: $basicHover;
      }
    }
    &:hover > svg {
      circle {
        fill: $c-primaryAccent;
        fill: var(--c-primary-accent);
      }
    }
  }
  @media all and (min-width: $screen-medium) {
    &__content {
      flex-basis: 72%;
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 15%;
    }
    @supports (display:grid){
      &__content {
        flex-basis: 85%;
      }
      &__icon {
        flex-basis: 15%;
      }
    }
  }  
  // max width only used on the popup cards!!
  @media all and (max-width:$screen-large) {
    &__icon {
      width: 100%;
      top: auto;
    }
  }
}