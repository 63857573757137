.nav {
  position: fixed;
  z-index: 30;
  width: 100%;
  top: 0;
  @include background-light-neutral;
  &--franchise {
    .nav__mobile-call {
      span {
        display: none;
        @media all and (min-width:360px) {
          display: block;
        } 
      }
    }
  }
  &__secondary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; 
    padding-top: 10px;
    z-index: 2;
  }
  &__logo {
    padding: 0 0 5px 20px;
    .bluebird-logo {
      max-width: 80px;

      &--themed{
        margin-top: -14px;
      }
    }
  }
  &__right {
    flex-basis: 68%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px 5px 0;
    max-height: 85px;
    .social-icons {
      display: none;   
        
    }
    svg {
      width: 100%;
      max-width: 30px;
      path {
        fill: $c-primary;
        fill: var(--c-primary);
      }
    } 
  }
  &__corona-link{
    text-decoration: underline;
    &--desktop {
      display: none;
    }
  }
  &__franchise-links {
    display: none;
    
  }
  &__social {
    li {
      margin-bottom: 0;
      svg {
        max-height: 90px;
      }
    }
  }
  &__mobile-call {    
    margin-right: 35px;
    a {
      text-decoration: underline;
      display: flex;
      align-items: center;
      svg {
        max-width: 20px;
        margin-right: 10px;
        path {
          @include fill-light-neutral;
        }
      }
    }    
  }
  &__search {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    padding: 20px;
    margin: -20px 15px -20px -20px;
    svg {
      max-width: 25px;
    }
    &:before {
      @include before-after;
      width: 2px;
      height: 35px;
      @include background-light-grey;
      left: 0;
      top: 16px;
    }
  }
  &__menu {
    display: inline-block;
    padding: 20px;
    margin: -20px;
    position: relative;
    &--active{
      .nav__menu-close {     
        transform: translate3d(0,0,0)!important;        
      }
    }
    &:before {
      @include before-after;
      width: 2px;
      height: 35px;
      @include background-light-grey;
      left: 0;
      top: 12px;
    }
  }
  &__menu-close {
    @include background-primary-accent;
    transform: translate3d(100%,0,0);
    transition: .4s ease 0.3s;  
    position: absolute;
    height: 106%;
    width: 110%;
    top: -5px;
    margin-left: -20px;
    z-index: 0;
    svg {
      max-width: 23px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      circle {
        @include fill-light-neutral;
      }
      path {
        // fill: $c-lightNeutral;
        // fill: var(--c-light-neutral);
      }
    }
  }
  &__search-bar {
    max-height: 0;
    transition: all 0.5s ease-in-out;
    width: 100%;
    overflow: hidden;
    @include background-primary-ukraine;
    border-bottom: 2px solid $c-lightNeutral; 
    display: flex;
    padding: 0 20px;
    form {
      display: inherit;
      width: 100%;
    }
    .nav__social-dropdown {
      display: none;
    }
    .search-input {
      margin: 20px 0;
      input {
        margin-bottom: 0;
        border: none;
        padding: 14px 14px 14px 50px;
      }
      svg {
        path {
          fill: $c-primary;
          fill: var(--c-primary);
        }
      }
    }    
    button {
      margin: 20px 0;
    }
    .search-button {
      margin-left: 10px;
    }
  }
  &__ctas {
    @include background-primary;
    flex-basis: 100%;
    display: flex;
    position: relative;
    z-index: 2;
    &--desktop {
      display: none;
    }
    &--mobile {
      &:before {
        @include primary-line-horizontal;
        top: auto; 
        bottom: -15px;
      }  
      &:after {
        @include secondary-line-horizontal;
        top: auto;   
        bottom: -5px;
      }
    }
    a {
      @include color-light-neutral;
    }
  }
  &__cta-container {
    padding: 20px 0;
    flex-basis: 50%;
    text-align: center;
    &:first-child {
      border-right: 1px solid $c-lightNeutral;
    }
  }
  @media all and (min-width:$screen-extra-small) {
    
    &__right {
      .social-icons {
        display: block;
        margin-right: 40px;       
        ul {
          li {
            svg {
              max-width: 32px;
            }
          }
        }      
      }
    }
  } 
  @media all and (min-width:$large-tablet) {
    &__secondary {
      padding: 10px;
    }
    &__logo {
      padding: 0;
      .bluebird-logo {
        max-width: 110px;

        &--themed{
          margin-top: -16px;    
          margin-bottom: -5px;
        }
      } 
    }
    &__corona-link{
      font-weight: 600;
      &--desktop {
        display: block;
        margin-right: 28px;
      }
      &--mobile {
        display: none!important;
      }
    }
    &__mobile-triggers {
      display: none;
    }
    &__mobile-call {
      display: none;
    }
    &__franchise-links {
      display: flex;  
      justify-content: flex-end;
      margin-right: 30px;  
      span {
        display: flex;
        align-items: center;
        margin-right: 20px;
        max-height: 30px;
        // ie fix
        min-width: 210px;
        @supports(display:grid) {
          min-width: auto;
        }
        &:first-child {
          display: none;
        }
        &:last-child {
          margin-right: 0;
        }
        p {
          display: inline-block;
          margin-bottom: 0;
        }
        a {
          text-decoration: underline;
        }
        svg {
          margin-right: 10px;
          max-width: 20px;
          path {
            @include fill-light-neutral;
          }
        }
      }
    }
    &__right {
      flex-basis: 90%;
      @supports(display:grid) {
        flex-basis: auto;
      }
      padding-bottom: 0;
      padding-right: 0;
      .social-icons {
        margin-right: 30px;
      }
    }    
    &__search-bar {
      position: absolute;
      max-height: 100%;
      max-width: 322px;
      bottom: -41px;
      @supports(display:grid) {
        bottom: -57px;
      }
      border: none;
      right: 0;
      z-index: 3;
      padding: 0;
      
      .search-input {
        margin: 0;        
        max-width: 180px;
        
        input {          
          padding: 14px 14px 14px 40px;
        }
        svg {
          width: 20px;
          path {
            fill: $c-primary;
            fill: var(--c-primary);
          }
        }
      }
      .search-button {
        button {
          margin: 0;
          padding: 12px 20px;
        }
      }      
    }
    &__ctas {
      flex-basis: auto;
      background: none;
      padding: 0;
      max-width: 510px;
      &--mobile { 
        display: none;
      }
      &--desktop {
        display: flex;
      }
      .nav__cta-container {
        &:last-child {
          a {
            @include background-primary-accent;
            &:hover {
              @include background-primary;
            }
          }
        }
      }
      a {
        @include background-primary-accent-light;
        @include color-light-neutral;
        font-size: 16px;
        border-radius: $border-radius; 
        padding: 12px 20px;
        font-weight: $regular;
        transition: $basicHover;
        text-decoration: none;
        display: inline-block;
        &:hover {
          @include background-primary;
        }
      }
    }
    &__cta-container {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-basis: auto;
      margin-right: 10px;
      &:last-child {
        border: none;
        margin-right: 10px;
      }
    }
  }
  @media all and (min-width:1100px) {
    &__secondary {
      padding: 10px 50px;
    }
    &__search-bar {
      max-width: 337px;
      .search-input {
        max-width: 195px;
      }
    }    
    &__franchise-link {
      span {
        min-width: 190px;
      }
    }
  }
  @media all and (min-width:$screen-xl) {
    &__search-bar {
      max-width: 317px;
      right: 25px;
      .nav__social-dropdown {
        display: block;
        min-width: 115px;
        li {
          padding-right: 5px;
          &:last-child {
            padding-right: 0;
          }
          svg {
            &:hover {
              circle {
                fill: $c-secondary;
                fill: var(--secondary);
              }
              path {
                @include fill-light-neutral;
              }
            }
          }
        }
        svg {
          max-width: 25px;
          circle {
            @include fill-light-neutral;        
          }
          path {
            fill: $c-primary;
            fill: var(--c-primary);
          }
        }
      }
      
      &:not(.active-search) .search-input {
      	cursor: pointer;
      }
      
      .search-input {
        max-width: 200px;        
        padding-left: 20px; 
        transform: translateX(80%); 
        
        &:before {
          @include before-after;
          @include background-light-neutral;
          width: 1px;
          height: 39px;
          top: 5px;
          left: 0;
        }       
        svg {
          padding-left: 18px;
          path {
            @include fill-light-neutral;
          }
        }  
        input {
          @include background-primary-ukraine;
          &::placeholder {
            @include color-light-neutral;
          }
          &::-ms-input-placeholder {
            @include color-light-neutral;
          }
        }     
      }
      .search-button {
        transform: translateX(100%);
        opacity: 0;
        transition: all 1s ease-in-out;
        .btn:hover {
          @include background-primary-accent;
        }
      }
    }
    &__search-bar.nav__search-bar--franchise{
      height: 66px;
      bottom: -65px;
      .nav__social-dropdown {
        position: absolute;
        top: 20px;
      }
      .search-input, .search-button {
        margin-top: 10px;
      }
    }
    &__franchise-links {  
      span {
        &:first-child {
          display: flex;
        }
      }
    }
  }
  @media all and (min-width:1250px) {
    &__search-bar {
      max-width: 352px;
      .search-input {
        max-width: 235px;
      }
    }    
  }
  @media all and (min-width:1300px) {
    &__search-bar   {
      right: 60px;
      max-width: 351px;
      .search-input {
        max-width: 270px;
        transform: translateX(75%);
      }
    }
  }  
  @media all and (min-width:1500px) {
    &__secondary {
      padding: 10px 100px;
    }
    &__search-bar   {
      right: 90px;
    }
  } 
}

.active-search {
  height: auto;
  max-height: 200px;
  @media all and (min-width:$screen-xl) {
    .search-input {
      transform: translateX(0);
      transition: 0.5s all ease-in 0.1s;
      input {
        @include background-light-neutral;
        transition: 0.3s all ease;
        &::placeholder {
          @include color-mid-grey;
          transition: 0.3s all ease;
        }
      }
      svg {
        path {
          fill: $c-primary;
          fill: var(--c-primary);
        }
      }
    } 
    .search-button {
      transform: translateX(0);
      opacity: 1;
      transition: all 0.3s ease-in 0.3s;
    }     
    .nav__social-dropdown {
      ul {
        li {
          transform: translateY(-100%);
          opacity: 0;
          transition: all 0.2s;    
          &:first-child {
            transition-delay: 0.3s;
          }
          &:nth-child(3) {
            transition-delay: 0.1s;
          }
          &:nth-child(2) {
            transition-delay: 0.2s;
          }
        }
      }
    }     
  }
}

.body-active-nav {
  overflow: hidden;
  height: 100%;
  .nav {
    height: 100vh;    
  }
  @media all and (min-width:$large-tablet) {
    overflow: auto;
  }
}
