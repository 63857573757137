.search-input {
  position: relative;
  width: 100%;
  span {
    position: absolute;
    top: 12px;
    left: 12px;
    max-width: 25px;
    width: 100%;
  }
  input {
    @include padding-left(5);
  }  
}