.campaign-page-builder {
  .main-content {
    .hero {
      overflow: visible!important; 
    }
  }
  .homepage-hero.homepage-hero--campaign {
    overflow: visible!important; 
  }
  .hero.hero--fallback {
    overflow: visible!important;
  }
}  
.dist-editor-btn {
  background-color: #008bcb!important;
}