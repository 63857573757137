.homepage-hero-video {
  background-color: var(--c-dark-neutral);
  position:relative;
	height: 560px;
	display: flex;
	justify-content:center;
  align-items:center; 
  video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    display: none;
  }  
  video[poster] {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .container {
    height: 100%;
    margin: 0;
  }
  .homepage-hero-video__section {
    &:nth-child(3) {
      margin-top: 24px;
    }
  }
  &__content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 2;
    max-width: 650px;
    h1, h2, p {
      color: var(--c-light-neutral);
    }
    h1 {
      font-weight: 300;
      font-size: 34px;
      margin-bottom: 8px;
    }
    h2 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .input-button input, .input-button input[type="text"] {
      min-width: 245px;
    }
  }
  &__title {
    position: relative;
    padding-bottom: 40px;
    &:after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      left: 50%;
      bottom: 16px;
      background-color: var(--c-light-neutral);
      transform: translate(-50%, 0);
      max-width: 350px;
    }
    
  }
  &__play-button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    margin: 0 auto;
    margin-top: 16px;
    svg {
      max-width: 22px;
      max-height: 22px;
      width: 100%;
      margin-right: 8px;
    }
  }
  &__image {
    position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
    object-position: center bottom;
    z-index: 1;
    opacity: 0.6;
  }
  @media all and (min-width:$screen-medium) {
    // height: 600px;
    height: 85vh;
    video {
      display: block;
    }
    &__content {
      h1 {
        font-size: 48px;
      }
    }
    &__image {
      display: none;
    }
  }
}