.rich-text {
  grid-column: 1/-1;
  max-width: 650px;
  margin: 0 auto;
  @supports(display:grid) {
    max-width: auto;
    margin: 0;
  }
  p {
    margin-bottom: 15px;
  }
  ul, ol {
    margin: 30px 0 30px 30px;
    li {
      padding-left: 30px;
      margin-bottom: 10px;
      position: relative;
      line-height: 26px;
      &:before {
        @include before-after;
        @include background-dark-neutral;
        width: 3px;
        height: 3px;
        border-radius: 50%;  
        left: 0;
        top: 6px;
      }
    }
  }

  //undo removal of list-style-type for ordered lists
  ol {
    list-style-type: decimal;

    li {
      &:before {
        display: none;
      }
    }
  }

  a {
    text-decoration: underline;
  }
  .image-list {
    list-style: none;
    @include vertical-margin(4);
    margin-left: 0;
    display: block;
    @supports(display:grid) {
      display: flex;
    }
    li {
      padding: 0;
      &:before {
        display: none;
      }
      img {
        margin: auto;
      }
    }
  }
  .btn {
    margin-top: 20px;
  }
  .social-icons {
    ul {
      margin: 0;
      li {
        padding-left: 0;
        margin-bottom: 0;
        &:before {
          display: none;
        }
        a {
          svg {
            circle {
              fill: $c-primary;
              fill: var(--c-primary);
            }
            path {
              fill: $c-lightNeutral;
              fill: $c-lightNeutral;
            }
          }
        }
      }
    }
  }
  table {
    width: 100%;
    border: 1px solid #d0d0d0;
    th {
      border: 1px solid #d0d0d0;
      padding: 15px;
      text-align: left;
    }
    tr {
      td {
        padding: 20px;
        min-width: 130px;
        line-height: 20px;
        border: 1px solid #d0d0d0;
      }
    }
  }
  @media all and (min-width:$screen-small) {
    grid-column: 2/12;
  }
  @media all and (min-width:$large-tablet) {
    grid-column: 3/11;
    .video-placeholder, img {
      width: 134%;
      margin: 40px -40px 40px -96px;
    }
    .quote {
      margin-left: -94px;
      width: 133%;
    }
    .content-left-right {
      margin-left: -80px;
      margin-right: -80px;
    }
  }  
  @media all and (min-width:$screen-xl){
    grid-column: 4/10;
  }
}