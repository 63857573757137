.k-disallow-message {
  display: none;
  border-width: 2px;
  border-style: solid;
  border-color: $red;
  border-color: var(--red);  
  p{
    color: var(--red);
    color: $red;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: $bold;
  }
}