.page-loader {
  position: absolute; 
  height: 50px;
  width: 135px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
  &__ball {
      width: 20px;
      position: relative;
      display: inline-block;
      margin: 3px;
      height: 20px;
      border-radius: 50%;
      z-index: 17;
      opacity: 1;
      &--end {
          animation: ending-balls 1s!important;
          opacity: 0!important;
          transition: all 1s; 
          &:nth-child(2) {  
              animation-delay: 0.1s;
          }
          &:nth-child(3) {
              animation-delay: 0.2s;
          }
          &:nth-child(4) {
              animation-delay: 0.3s;   
          }
          @keyframes ending-balls {
              0% {
                  transform: translateY(0);
              } 
              100% {
                  transform: translateY(-1000px);
              }   
          }
      }
      &:first-child {
          @include background-primary;
          animation: jump .5s ease 0s infinite alternate;
      }
      &:nth-child(2) {
          @include background-primary-accent;
          animation: jump .5s ease .15s infinite alternate;
      }
      &:nth-child(3) {
          @include background-secondary;
          animation: jump .5s ease .25s infinite alternate;
      }
      &:nth-child(4) {
          @include background-primary-accent;
          animation: jump .5s ease .35s infinite alternate;
      }
      @keyframes jump {
          0% {transform: scaleY(.8);}
          100% {
              transform: translateY(-90px);
          }   
      }
      
  }
  &__shadow{
      position: relative;
      opacity: .1;
      bottom: 20px;
      width: 20px;
      height: 5px;
      border-radius: 50%;
      background-color: black;
      display: inline-block;
      margin: 3px;
      z-index: 2;
      &--one {
          animation: shrink .5s ease .0s infinite alternate;
      }
      &--two {
          animation: shrink .5s ease .15s infinite alternate;
      }
      &--three {
          animation: shrink .5s ease .25s infinite alternate;
      }
      &--four {
          animation: shrink .5s ease .35s infinite alternate;
      }
      @keyframes shrink {
          100% {
              transform: scaleX(.5);
              opacity: .01;
          }    
      }
      &--end {
          opacity: 0!important;
          transition: all 0.5s; 
      }
  }
}

.loader-overlay{
	position:absolute;
	top: 0;
	bottom: 0;
	left:0;
	right:0;
	opacity: 1;
	z-index: 15;
	&--end {
		opacity: 0;
		transition: all 2s;
	}
}