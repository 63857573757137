.search-result {
  grid-column: 1/-1;  
  // @include vertical-margin(2);
  border-bottom: 1px solid $c-primary;
  padding: 25px 0 30px;
  &:first-of-type{
    border-top: 1px solid $c-primary;
    padding-top: 30px;
    @include margin-top(6);
  }
  a {
    @include color-primary;
    &:hover {
      text-decoration: underline;
    }
  }
}