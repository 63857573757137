.pagination {
  display: flex;
  align-items: center;
  grid-column: 1 / -1;
  justify-content: center;
  @include margin-top(1);
  @include margin-bottom(3);
  &__btn-link {
    grid-column: auto!important;
    width: auto!important;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
  &__btn {
		border-top: 2px solid $c-primary;
    border-right: 2px solid $c-primary;
		width: 12px;
		height: 12px;
		transform: rotate(-135deg);
		display: block;
		transition: all .4s ease;
		&--last {
			transform: rotate(45deg);
		}
	}
	&__item {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 10px;
    // &:last-child {
    //   margin-right: 0;
    // }
    span, a {
      font-size: 20px;
    }
		button {
      @include color-primary-accent;
      font-size: 16px;
		}
		&--active {
			@include background-primary;
			border-radius: 50%;
			padding: 6px 11px;
			span {
				@include color-light-neutral;
			}
		}
  }
  &__dots {
    @include color-primary-accent;
  }
  button {
    &:hover {
      background: none;
    }
  }
  @media all and (min-width:$large-tablet) {
    &__item {
      margin-right: 5px;
      span, a {
        font-size: 16px;
      }
    }
    &__btn {
      width: 9px;
      height: 9px;
    }
  }
}