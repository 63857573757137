// @mixin footer-border($sides) {
//   border-color: $light-grey;
//   border-color: var(--light-grey);
//   border-style: solid;
//   border-width: $sides;
// }

.footer {
  border-top: 15px solid $c-primary;
  position: relative;
  //add spacing above the footer
  //unless it's immediate previous sibling is franchise-footer-contact-bar
  //in which case, they should touch
  //nb it is actually local-office-cta that adds the margin above the footer 'block'
  
  // removed due to it adding margin top on the general pages and pushed the find care near you up creating a white gap. Find care near you is within the main content wrapper and can not be targetted like franchise footer
  // @include margin-top(12);
  // .franchise-footer-contact-bar + & {
  //   margin-top: 0;
  // }
  &:before {
    @include primary-line-horizontal;
    top: 5px;
  }  
  &:after {
    @include secondary-line-horizontal;     
  }
  .container {
    padding: 0;
  }
  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  &__item {
    flex-basis: 100%;
    @include vertical-padding(4);
    box-sizing: border-box;
    &:first-child {
      order: 4;
    }
    &:nth-child(2) {
      @include padding-top(0);
    }
  }
  &__logos {
    text-align: center;
    .bluebird-logo {
      max-width: 120px;
      @include margin-right(2);
    }
    .ukhca-logo {
      max-width: 60px;
    }
  }
  &__item-large {
    flex-basis: 100%;
    grid-row-gap: 40px;
    text-align: center;
    @include footer-border(0 0 2px 0 );
    @include padding-bottom(4);
  }
  &__item-grid {
    display: flex;
    flex-wrap: wrap;
    @supports(display:grid) {
      display: grid;
      grid-row-gap: 40px;
    }
  }
  &__buttons {
    @include horizontal-padding(3);
    .btn:first-child {
      @include margin-bottom(2);
    }
  }
  &__news-letter-sign-up {
    @include footer-border(2px 0 0 0 );
    @include padding-top(3);
    @include horizontal-padding(3);
    input {
      box-sizing: border-box;
    }
    p {
      @include vertical-padding(2);
    }
    button {
      width: 100%;
    }
  }
  &__social-icons {
    @supports(display:grid) {
      grid-row: 3;
    }   
  }
  &__sub-footer {
    text-align: center;
    @include footer-border(2px 0 0 0 );
    padding: 40px 30px;
    a {
      @include color-dark-neutral;
      font-size: 14px;
    }
    ul {  
      @include margin-bottom(2);    
      li {
        display: inline-block;
        @include margin-bottom(1);
        &:after {
          content: "|";
          display: inline-block;
          @include horizontal-padding(1);
          @include color-mid-neutral;               
        }    
        &:last-child {
          &:after {
            display: none;
          }
        }    
        a {
          @include color-mid-neutral;
          &:hover {
            @include color-dark-neutral;
          }
        }
      }
    }
    p{
      @include color-mid-neutral;
      font-size: 14px;
    }
  }
  @media all and (min-width: 500px){
    &__buttons {
      padding: 0 16%;
    }
    &__news-letter-sign-up {
      padding: 30px 16% 30px;
    }
  }
  @media all and (min-width: $screen-small) {
    .container {
      @include horizontal-padding(2);
    }
    &__container {
      flex-direction: row;
      @include padding-top(3);
    }
    &__item-grid {
      grid-template-columns: repeat(12, 1fr);
    }
    &__item {
      flex-basis: 60%;
      @include padding-top(0);
      &:first-child {
        order: 1;
        flex-basis: 40%;
      }
      &::nth-child(2) {
        order: 0;
      }
    }
    &__item-large {
      order: 2;
      @include padding-top(4);
      @include footer-border(2px 0 0 0 );
    }
    &__item-grid {
      column-gap: 40px;
    }
    &__logos {
      text-align: right;
    }
    &__buttons {
      grid-row: span 2;
      grid-column: span 5;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: end;
      .btn--full-width {
        display: inline-block;
        &:first-child {
          @include margin-bottom(0);
        }
      }
    }
    &__social-icons {
      align-self: end;
      text-align: left;
      flex-basis: 100%;
      @supports(display:grid) {
        grid-column: span 5;
      }
    }
    &__news-letter-sign-up {
      text-align: left;
      grid-row: span 3;
      border: 0;
      padding: 0;      
      flex-basis: 100%; 
      @supports(display:grid) {
        grid-column: span 7;
      }
      .btn--full-width {
        text-align: center;
      }
    }    
  }
  @media all and (min-width: $screen-medium) {
    .btn.btn--full-width {
      display: block;
    }
  }
  @media all and (min-width: $large-tablet) {
    &__container {
      @include padding-top(6);
      @include padding-bottom(3);
    }
    &__item {
      
      flex-basis: 23%;
      &:first-child {
        order: 0;
        flex-basis: 17%;
      }
    }
    &__item-large {
      flex-basis: 60%;
      border: 0;
      @include vertical-padding(0);
    }       
    &__news-letter-sign-up {         
      h4 {
        font-size: 26px;
      }
    }
    &__logos {
      text-align: left;
      .bluebird-logo{
        @include margin-right(0);
        @include margin-bottom(2);
        display: block;
      }
      .ukhca-logo {
        max-width: 85px;
      }
    }
    &__sub-footer {
      @include horizontal-padding(0);  
      ul {
        text-align: left;
        li {
          margin-bottom: 5px;
        }
      } 
    }
    @supports(display:grid) {
      &__item {
        @include footer-border(0 2px 0 0 );
        &:nth-child(2){
          @include padding-left(3);
        }
      }
      &__item-large {
        @include padding-left(3);
      }
      &__item-grid {
        column-gap: 20px;
      }
      &__buttons {
        grid-column: span 6;
        grid-row: span 1;
      }
      &__social-icons {
        grid-column: span 6;
        grid-row: 2;
      }
      &__news-letter-sign-up {
        grid-column: span 6;
        grid-row: span 2;
      }
    } 
  }  
  @media all and (min-width: $screen-xl) {
    &__container {
      @include padding-top(8);
    }
    &__item {
      @include padding-left(0);
      &:nth-child(2) {
        @include padding-left(0);
      }
    }
    &__item-large {
      @include padding-left(0);
      flex-basis: 59%;
    }
        
    &__buttons {
      flex-basis: 50%;
      order: 1;
      align-self: flex-start;
      justify-content: space-between;
    }
    &__social-icons {
      flex-basis: 50%;
      order: 2;
    }
    &__news-letter-sign-up {
      flex-basis: 49%;  
      order: 0;
      
      // @include padding-left(6);
    }
    &__sub-footer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      ul {
        margin-bottom: 0;
      }      
      p {
        @include color-dark-neutral;
      }
    }
    @supports(display:grid) {
      &__item {
        &:nth-child(2) {
          @include padding-left(6);
        }
      }
      &__item-grid {
        grid-template-columns: repeat(16,1fr);
        column-gap: 40px;
      }
      &__buttons{
        grid-column: 2/8;
        order: 0;
        .btn {
          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
      &__social-icons {
        grid-column: 2/8;
      }
      &__news-letter-sign-up {
        grid-column: 8/ 17;
        @include padding-left(6);
        @include footer-border(0 0 0 2px);
      }
    }
  }  
}