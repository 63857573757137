.contact-form.contact-form--campaign {
  @include background-secondary;
  padding: 40px;
  z-index: 2;
  grid-column: 1/-1;
  text-align: left;
  border-radius: 3px;

  @supports(display: grid) {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    h3 {
      margin-bottom: 0;
    }
    input {
    	margin-top: 5px;
    }
  }

  .formwidget-submit-text {
    p {
      // color: var(--c-secondary);
    }
  }
 
  input[type="radio"]~label,
  input[type="checkbox"]~label {
    box-sizing: border-box;
    font-size: 16px;
    position: relative;
    &::before {
      left: 0;
      top: 2px;
    }
  }

  .contact-form__button-wrapper {
    .btn {
      top: auto;
    }
  }
  .ktc-default-section {
    margin-top: 15px;
    div {
      margin-bottom: 15px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media all and (min-width:$screen-small) {
    margin: 0 auto;
    .formwidget-submit-text {
      position: relative;
      max-width: 650px;
      height: 200px;
    }
  }
  @media all and (min-width:$screen-medium){
    max-width: 700px;
    .grid {
      &__item {
        grid-column: span 6;
      }
    }
    .formwidget-submit-text {
      max-width: 700px;
    }
  }
  @media all and (min-width:$screen-large) {
    max-width: 800px;
    padding: 40px 80px;
    .formwidget-submit-text {
      max-width: 800px;
    }

    //if is nested inside a two-col grid, reduce the padding
    .grid--two-column-2-1 &{
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  @media all and (min-width:$large-tablet) {
    .formwidget-submit-text {
      height: 400px;
    }
  }
} 