.grid.grid--two-column-3-1 {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  @supports (display:grid){
    display: grid;
  }  
  // p, h1, h2, h3, h4, h5, h6 {
  //   flex-basis: 100%;
  // }
  .campaign-txt {
    margin-top: 0;
  }
  @media all and (min-width:$large-tablet) {
    .grid__item {
      &:nth-child(1) {
        flex-basis: 70%;
      }
      &:nth-child(2) {
        flex-basis: 20%;
        
      }
    }
    @supports (display:grid){
      .grid__item {
        &:nth-child(1) {
          grid-column: 1/9;
        }
        &:nth-child(2) {
          grid-column: 10/13;
        }
      }
    }
  }
}