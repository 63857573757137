.fader {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  img {
    opacity: 0;
    transition: all 1.5s;

    &.active {
      opacity: 1;
    }
  }
}