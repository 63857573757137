.expandable-panels.expandable-panels--filters {
  grid-column: 1/-1;
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 18px;
    @include color-primary;
  }
  .expandable-panels__item {
    box-shadow: none;
  }
  @media all and (min-width:$large-tablet) {
    .expandable-panels__item {
      border: 0;
      padding: 0;
    }
    .expandable-panels__cross {
      display: none;
    }
    .expandable-panels__top-container {
      display: none;
    }
    .expandable-panels__content {
      max-height: 99em!important;
    }
  }
  h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
}