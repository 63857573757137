.input-button {  
  input, input[type="text"] {
    width: auto;
    padding: 12px;
    min-width:205px;
    border: none;
    margin-right: 8px;
    margin-bottom: 0;
  }  
  &__container {
    display: flex;
    flex-wrap: wrap;
  }
  &__button {
    display: inline;
  }
  @media all and (min-width:$screen-small) {  
    input {
      padding: 13px;
    }    
  }
}