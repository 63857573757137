.expandable-panels.expandable-panels--footer {
  border: none;
  margin: 0;  
  .expandable-panels__item {
    border: 0;
    padding: 0;
    margin-top: 0;
    box-shadow: none;
  }
  .expandable-panels__top-container{
    border-left: 0; 
    @include footer-border(0 0 2px 0);
    padding-bottom: 0;
    margin: 0;
    &:before {
      display: none;
    }
  }
  h3 {
    @include background-light-neutral;
    @include color-dark-neutral;
    font-weight: $regular;    
    font-size: 20px;
    padding: 20px 30px;    
  }
  .expandable-panels__arrow {
    @include arrow(-45deg);
  }
  .expandable-panels__content {
    // padding-left: 20px;
    padding: 0;
    margin: 0;
    ul {
      padding: 20px 0;
      margin: 0;
      li {
        padding: 20px 0 20px 50px;
        @include footer-border(0 0 2px 0);
        display: block;
        &:first-child {
          padding-top: 0;
        }
        &:before{
          display: none;
        }
        a {
          @include color-dark-neutral;
        }
      }
    }        
  }
  .expandable-panels--open {
    .expandable-panels__arrow {
      transform: rotate(135deg);
    }
  }
  @media all and (min-width: $screen-small) {
    h3 {
      border: 0;
      padding: 0;
      @include color-primary;
      font-weight: $semi-bold;
      font-size: 22px;
      @include margin-bottom(2);
    }
    .expandable-panels__top-container {
      padding: 0;
      border: 0;
    }
    .expandable-panels__arrow {
      display: none;
    }
    .expandable-panels__content {
      max-height: 100%;
      ul {
        @include vertical-padding(0);
        li {
          border: 0;
          padding: 0;
          display: inline-block;
          @include margin-bottom(1.5);
          @include margin-right(1);
          &:last-child {
            @include margin-right(0);
          }
        }
      }
    }
  }
  @media all and (min-width: $large-tablet) {
    margin-top: 0;
    .expandable-panels__content {
      ul {
        li {
          display: block;
        }
      }
    }
  }
}