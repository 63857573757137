.radio-input, .ktc-radio {
  @include margin-bottom(2); 
  @include margin-top(2); 
  position: relative;    
  display: block;
    label {
        &:before {
            border-radius: 50%; 
        }
    }
    input:checked ~ label {
        &:before {
            border-color: $c-primary;
        }
        &:after {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            left: 6px;
            top: 2px;
        }
    }
}

