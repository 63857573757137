.pagination-container {
  grid-column: 1/-1;
  width: 100%;  
  p {
    flex-basis: 30%;
  }
  @media all and (min-width:$large-tablet) {
    grid-column: 2/12;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}