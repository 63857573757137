.logo-title {
  grid-column: 1/-1;
  display: flex;
  width: 100%;
  align-items: center;
  &__logo {
    max-width: 120px;
    margin-right: 20px;
  }
  h2, h3 { 
    margin-bottom: 0!important;
  }
}