input {
  &::placeholder {
    @include color-mid-grey;
  }
}
input[type="text"], input[type="email"], textarea {
  border: 1px solid $c-midNeutral;
  border-radius: $border-radius;
  outline: none;
  padding: 13px;
  width: 100%;
  width: -webkit-fill-available;
  font-size: 16px;
  box-sizing:border-box;
  @include margin-bottom(2);
  &:focus {
    border-color: $c-primary;
  }
} 

label {
  display: block;
  @include margin-bottom(0.3);
}
input[type="radio"], input[type="checkbox"] {
  position: absolute;
  left: -10000px;
}
input[type="radio"] ~ label, input[type="checkbox"] ~ label {
  padding-left: 40px;
  margin-bottom: 0;
  &:before {
    content: "";
    height: 22px;
    width: 22px;
    border: 1px solid $c-midNeutral;
    background-color: $c-lightNeutral;
    display: block;
    position: absolute;
    left: 0;
    top: -4px;
    cursor: pointer;
  }
  &:hover {
    &:before {
      border-color: $c-primary;
    }
  }
}
input[type="radio"]:checked ~ label, input[type="checkbox"]:checked ~ label {
  &:before {
    border-color: $c-primary;
  }
  &:after {
    content: "";
    display: block;
    background-color: $c-primary;
    position: absolute;
  }
} 
button {
  border: none;
  cursor: pointer;
  outline: none;
  background: none;
  @include color-light-neutral;
  font-size: 16px;
  border-radius: $border-radius;   
  font-weight: $regular;
  transition: $basicHover;
  text-decoration: none;
  display: inline-block;
  &:hover {
    @include background-primary;
  }
}

select {
  padding: 14px 40px 14px 10px;
  border-radius: $border-radius;
  border-color: $c-midNeutral;
  border-width: 1px; //firefox sets to 2px by default
  border-color: var(--grey);
  display: block;
  width: 100%;
  color: $c-midNeutral;
  color: var(--grey);
  outline: none;
  font-size: 16px;
  -webkit-appearance: none;
     -moz-appearance: none;
      -ms-appearance: none;
          appearance: none;
  background: url('../Images/arrow.svg') no-repeat right center;
  cursor: pointer;
  @include margin-bottom(2);
 
}


//style the default 'submit' button as .btn
//add some extra spacing above/below also
//this element appears in formbuilder forms 
input[type=submit]{
  @extend .btn;
  outline: none;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  cursor: pointer;
}