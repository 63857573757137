.grid.grid--side-bar-left {
  display: flex;
  flex-wrap: wrap;
  @supports (display:grid){
    display: grid;
  }
  @media all and (min-width:$large-tablet) {
    .grid__item {
      &:nth-child(1) {
        flex-basis: 30%;
      }
      &:nth-child(2) {
        flex-basis: 70%;
      }
    }
    @supports (display:grid){
      .grid__item {
        &:nth-child(1) {
          grid-column: 1/5;
        }
        &:nth-child(2) {
          grid-column: 5/13;
        }
      }
    }
  }
  @media all and (min-width:$screen-xl) {
    @supports (display:grid){
      .grid__item {
        &:nth-child(1) {
          grid-column: 1/4;
        }
        &:nth-child(2) {
          grid-column: 4/13;
        }
      }
    }
  }
  
}