.hero {
  @include background-secondary;
  @include margin-bottom(6);
  &__container {
    grid-column: 1 / -1;
    @include vertical-padding(3);
    // @include horizontal-padding(2);
    display: flex;
    flex-wrap: wrap;
    min-height: 250px;
    @include color-light-neutral;
  }
  .bread-crumbs {
    opacity: 2;
    position: relative;
    @include margin-bottom(2);
    flex-basis: 100%;
  }
  &__content {
    z-index: 2;
    position: relative;
    flex-basis: 100%; 
    p {
      @include margin-bottom(1);
      @include color-light-neutral;
    }   
  }
  &__article-assets {
    z-index: 2;
    position: relative;
  }
  &__social-icons.social-icons {
    li {
      &:first-child {
        display: block;
      }
    }
    svg {
      @include margin-right(0.3);
      &:hover  {        
        circle {
          fill: $c-lightNeutral;
          fill: var(--c-light-neutral);
        }
      }
      circle {
        fill: $c-lightNeutral;
        fill: var(--c-light-neutral);
      }
      path {
        fill: $c-primary;
        fill: var(--c-primary);
      }
    }
  }
  &__date {
    @include color-dark-neutral;
  }
  
  // @media all and (min-width: $screen-extra-small){
  //   &__container {
  //     grid-column: 2/12;
  //   }
  // }
  @media all and (min-width: $large-tablet){
    &__container {
      min-height: 320px;
      h1, p {
        max-width: 800px;
      }      
    } 
    &__content {
      h1 {
        font-size: 28px;
        line-height: 41px;
        margin-bottom: 8px;
      }
    }
    &__date {
      p {
        font-size: 18px;
      }      
    }
  }
  @media all and (min-width: $screen-xl) {
    &__content {
      flex-basis: 70%;
    }
    &__article-assets {
      flex-basis: 30%;
      text-align: right;
    }
  }
}