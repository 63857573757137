// Needs changing into BEM naming and organising

.animation-container {
  position: relative;
  // height: 551px;
  display: flex;
  flex-wrap: wrap;
  @include margin-top(4);
  .tile-wrapper {
    width: calc(50% - 5px);
    height: 275px;
   
    &:nth-child(2), &:nth-child(4) {
      left:50%;
      .tile {
        left:0;
        .sizable-wrapper {
          left: 50%;
          margin-left: 5px;
        }
      }
    }
    &:nth-child(3), &:nth-child(4) {
      .tile {
        .sizable-wrapper {
          top: 50%;
        }
      }
    }
    &--active {
      .sizable-wrapper {
        cursor: default!important;
        top: 0!important;
        bottom: 0!important;
        left: 0!important;
        right: 0!important;
        width: 100%!important;
        max-width: 100%!important;
        height: 100%!important;
        margin-left: 0!important;
      }
      .tile-close-btn  {
        opacity: 1!important;
        visibility: visible!important;
      }
      .tile__right-slide {
        transform: translateX(-1100px)!important;
      }
      .tile__left-slide {
        transform: translateX(1100px)!important;
      }
    }	
    .tile {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;      
      &--active {
        z-index: 3;        
      }
      &__right-slide {
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: 1;
        transform: translateZ(0);
        right: -1100px;
        transition: transform 170ms ease-in-out;
        will-change: transform;
        display: block!important;
      }
      &__left-slide {
        position: absolute;
        bottom: 0;
        width: 480px;
        height: 374px;
        z-index: 1;
        transform: translateZ(0);
        left: -1079px;
        transition: transform 10ms ease-in-out;
        will-change: transform;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;
        ul {
          padding-top:40px;
          grid-column: span 2;
          column-count: 2;
          li {
            margin: 0 15px 10px 0;
            a {              
              text-decoration: underline;
              cursor: pointer;
              line-height: 20px;
            }
          }
        }
      }
      .tile-close-btn {
        @include background-primary;
        transform: translateZ(0);
        opacity: 0;
        transition: opacity 170ms .1s ease-in-out;
        will-change: opacity;
        visibility: hidden;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        flex-direction: row-reverse;        
        padding: 20px;
        cursor: pointer;
        svg {
          width: 100%;
          max-width: 30px;
          max-height: 50px;
          circle {
            fill: $c-lightNeutral;
            fill: var(--c-light-neutral);
          }
          path {
            fill: $c-primary;
            fill: var(--c-primary);
          }
        }
      }
    }
    .sizable-wrapper {
      @include standard-border(1px 1px 1px 1px);
      @include background-light-neutral;
      position: relative;
      z-index: 1;
      display: inline-block;    
      left: 0;
      top: 0;
      width: 50%;
      max-width: calc(50% - 5px);
      height: 265px;
      cursor: pointer;
      transform: translateZ(0);
      transition: all 170ms ease-in-out,max-height 0s,padding 0s;
      will-change: top,bottom,left,right,width,height,max-width; 
      &:after {
        @include secondary-line;
        left: 8px;
      }     
      &:before {
        @include primary-line;
        z-index: 1;
      }   
      .mobile-wrapper {
        display:none;
      }
      .mobile-icon {
        display: none;
      }
    }
    // &--bottom {
    //   .sizable-wrapper {
    //     top: 50%;
    //   }
    // }
    .text-wrapper {
      max-width: 480px;
      display: inline-block;
      padding: 24px;
      h3 {
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 20px;
      }
      .logo-title {
        margin-bottom: 20px;
        &__logo {
          max-width: 80px;
        }
        // h3 {
        //   font-size: 26px;
        //   line-height: 34px;
        // }
      }
    }
    		
  }
  @media all and (max-width:$screen-xl){
    .tile-wrapper .text-wrapper, .tile__left-slide {
      max-width: 385px;
    }
  }
  @media all and (max-width:$large-tablet){
    .tile-wrapper .text-wrapper, .tile__left-slide {
      max-width: 315px;
    }
  }
  @media all and (max-width:$screen-large) {
    height: auto;
    .tile-wrapper {
      width: 100%;
      height: auto;
      margin-top: 10px;
      &:nth-child(2), &:nth-child(4) {
        .sizable-wrapper {
          width: 100%;
          top: 0!important;
          left: 0!important;
          margin-left: 0!important;
        }
      }
      &:nth-child(3), &:nth-child(4) {
        .tile {
          .sizable-wrapper {
            top: 0;
          }
        }
      }
      .tile {
        position: relative;
        &--active {
          .tile__left-slide {
            opacity: 1;
            visibility: visible;            
          }
        }
        &__right-slide {
          display: none!important;
        }
        &__left-slide {
          display: none!important;
        }
        .tile-close-btn {
          bottom: 0;
          top: auto;
          width: 100%;
          text-align: center;
        }
      }
      &--active {
        .sizable-wrapper {
          max-height: 100%!important;
          cursor: default!important;
        }
      }
      .sizable-wrapper {
        width: 100%;
        max-width: 100%;
        display: block;
        overflow: hidden;
        min-height: 235px;
        max-height: 235px;
        height: auto;
        transition: max-height .4s ease-out;
          will-change: max-height;
     
        .mobile-wrapper {
          display:block;
          ul {
            padding: 20px;
            margin-top: 30px;
            li {
              margin: 0 20px 20px 0;
              // display: inline-block;
              a {
                text-decoration: underline;
              }
            }
          }
          &__image {
            height: 400px;
          }
        }
        .mobile-icon {
          display: block;
        }
        .desktop-icon {
          display: none;
        }
      }
      .text-wrapper {
        max-width: 100%;
      }
    }  
    // .card-popup__icon {
    //   &:after {
    //     @include before-after;
    //     background: linear-gradient(to top,#fff 44%,rgba(255,255,255,0) 100%);
    //     height: 100px;
    //     width: 100%;
    //     bottom: 0;
    //     left: 0;
    //     z-index: 0;
    //   }
    // } 
  }
}
