.card.card--news-background-image {
  // border-bottom:
  
  border-color: $c-secondary;
  border-color: var(--secondary);
  
  &:after {
    @include before-after;
    @include background-primary;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
    opacity: 0.6;
    transition: $basicHover;
  }
  h2, h3, h4, h5, h6, p {
    @include color-light-neutral;
    transition: $basicHover;
  }
  .card__image--background {
    position: absolute;
    max-height: 100%;
    z-index: -1;
    &:after {
      @include before-after;
      @include background-primary;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.6;
      transition: $basicHover;
    }
  }
  .card__date {
    svg {      
      circle {
        fill: $c-lightNeutral;
        fill: var(--c-light-neutral);
        transition: $basicHover;
      }
      path {
        fill: $c-primary;
        fill: var(--c-primary);
        transition: $basicHover;
      }
    }
  }
  &:hover {
    &:after {
      transform: none;
      @include background-light-neutral;
    }
    .card__image--background:after {
      @include background-light-neutral;
    }
    h2, h3, h4, h5, h6 {
      @include color-primary;
    }
    p {
      @include color-dark-neutral;
    }
    .card__date {
      svg {
        circle {
          fill: $c-primary;
          fill: var(--c-primary);
        }
        path {
          fill: $c-lightNeutral;
          fill: var(--c-light-neutral);
        }
      }
    }
  }
}