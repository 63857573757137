.card.card--detailed {
  // border-left: 10px solid $c-primaryAccent;
  position: relative;
  padding-left: 28px;
  &:after {
    @include secondary-line;    
    left: 9px;
  }
  &:before {
    @include before-after;
    @include card-hover-secondary(scaleX(0.03), 0 50%);
  }
  p {
    margin-bottom: 5px;
  }
  a {
    text-decoration: underline;
  }
  h5 a{
    text-decoration: none;
    color: $c-primary;
    font-size: 16px;
    line-height: 24px;
  }
  .link-arrow {
    &:after {
      @include background-primary;
      @include color-light-neutral;
    }
  }
  a[href^="tel:"] {
    font-size: 20px;
    text-decoration: none;
  }
  @media all and (min-width:$screen-extra-small){
    &:after {    
      left: 10px;
    }
  }
  @media all and (min-width:$screen-small){
    &:after {    
      left: 12px;
    }
  }
  @media all and (min-width:$screen-medium){
    &:after {    
      left: 9px;
    }
  }
  
  @media all and (min-width:$large-tablet) {
    h5 a{
      font-size: 20px;
      line-height: 28px;
    }
  }
}
