.campaign-page {
  // @include padding-top(4);
  .card-container--large {
    grid-column: 1/-1;
  }
  .video-placeholder {
    max-width: 800px;
    margin: 0 auto;
   
  }

  //add margins above/below the recaptca element in the campaign page forms
  //have to style using partial id as cannot add a class as is formbuilder
  div[id^="google-recaptcha-form"] {
  	margin-top: 25px;
  	margin-bottom: 25px;
  }

  @media all and (min-width: $screen-extra-small) {
    .video-placeholder, .card-container--large {
      grid-column: 2/12;
    }
    .grid.grid--text-left-image-right {
      .grid__item {
        &:nth-child(1) {
          grid-column: 2/7;
        }
        &:nth-child(2) {
          grid-column: 8/12;
        }
      }
    }    
  }
  @media all and (min-width:$large-tablet) {
    .video-placeholder {
      grid-column: 3/11;
    }
  }
}