.page-builder-txt {
  // @include margin-top(6);
  @include rich-text;
  grid-column: 1/-1;
  
  max-width: 600px;
  margin: 0 auto;
  @include vertical-margin(3);
  
  @media all and (min-width:$screen-extra-small) {
    grid-column: 2/12;
    
  }
  @media all and (min-width:$large-tablet) {
    grid-column: 4/10;
    
  }
}

// .ktc-editable-area {
//   z-index: 10;
// }