.testimonial.testimonial--side-image {
  
  @media all and (min-width: $screen-xl) {
    display: flex;
    flex-wrap: wrap;
    .testimonial__image-container{
      flex-basis: 36%;
      height: auto;
    }
    .testimonial__content-container {
      flex-basis: 64%;
    }
  }
}