.card.card--icon-small {
  padding: 30px;
  position: relative;
  .card__icon {
    max-width: 90px;
  }
  a[href^="mailto:"] {
    position: absolute;
    left: 0;
    padding: 0 10px;
    font-size: 16px;
  }
  a[href^="tel:"] {
    font-size: 22px;
  }
  @media all and (min-width:$screen-xxs){
    padding: 30px 70px;
  } 
  @media all and (min-width:$screen-medium){
    padding: 30px 20px;
    p {
      font-size: 18px;
    }
    grid-column: span 6;
    a[href^="tel:"] {
      font-size: 26px;
    }
  } 
  @media all and (min-width:$large-tablet){
    padding: 30px 70px;
  } 
  
}