.expandable-panels {
  @include vertical-margin(3);
  grid-column: 1/-1;
  max-width: 800px;
  margin: 0 auto;
  &__item {
    cursor: pointer;
		padding: 10px 20px;
    border-radius:3px;
    position: relative;
    border-style: solid;
    border-width: 1px;
    border-color: $c-primary;
    border-color: var(--c-primary);
    border-radius: 3px; 
    margin-bottom: 30px;
    @include background-light-neutral;
    z-index: 0;
    box-shadow: 0px 0px 22px -5px rgba(0,0,0,0.33);
    &--secondary {
      .expandable-panels__top-container {
        @include border-primary-accent;
        border-width: 0 0 0 10px;
        &:before {
          @include background-primary-accent;
        }     
        &:after {
          @include secondary-line;
          z-index: -2;
        }   
      }
      .expandable-panels__cross {
        @include background-primary-accent;
      }      
    }    
  }
  .expandable-panels__item--secondary.expandable-panels--open {
    .expandable-panels__cross:before {
      @include background-primary-accent;
    }
  }
  &__top-container {
    margin: -10px -20px;
    padding: 20px 30px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-style: solid;
    border-width: 0 0 0 10px;
    border-color: $c-primary;
    border-color: var(--c-primary);
    transition: all 0.3s ease-out;
    &:before {
      @include before-after;
      @include card-hover(scaleX(0), 0 50%);
      // z-index: -1;
    }
    h3, h4, h5 {
      margin-bottom: 0;
      padding-right: 20px;
    }
    h5 {
      @include color-primary;
    }
  }
  &__cross {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    min-width: 25px;
    @include background-primary;
    border-radius: 50%;
    transition: 0.3s all;
    &:before, &:after { 
      content: "";
      display: block;
      position: absolute;
      transition: .3s;
      @include background-light-neutral;
      border-radius: 2px;
    }
    &:before {
      top: 25%;
      bottom: 25%;
      width: 10%;
      left: 45%;
    }
    &:after {
      left: 25%;
      right: 25%;
      height: 10%;
      top: 45%;
    }
  }
  
	&__content {
		display: block;
		max-height: 0px;
		overflow: hidden;
    transition: all 0.4s ease;
    ul {
      margin: 30px 0 30px 30px;
      li {
        padding-left: 30px;
        margin-bottom: 10px;
        position: relative;
        line-height: 22px;
        &:before {
          @include before-after;
          @include background-dark-neutral;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          left: 0;
          top: 6px;
        }
      }
    }
	}
	&--open {
    .expandable-panels__top-container {      
      margin-bottom: 20px;
      transition: all 0.2s ease;
      &:before {
        transform: scaleX(1);
      }
    }    
		.expandable-panels__content {
			max-height: 99em!important;
      transition: all 1s ease-in-out;
      
    }
    .expandable-panels__cross {
      @include background-light-neutral;
      &:before, &:after {
        transform: rotate(90deg);
        @include background-primary;
      }
      &:after {
        left: 50%;
        right: 50%;
      }
    }
    h3, h4, h5 {
      @include color-light-neutral;
      z-index: 1;
      
    }
  }
  @media all and (min-width:$screen-small) {
    h3, h4, h5 {
      padding-right: 40px;
    }
  }
}