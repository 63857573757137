.checkbox, .ktc-checkbox {
  position: relative;
  margin-bottom: 20px;
  margin-top: 5px; 
  label {
    margin-top: 15px;
    line-height: 18px;
    &:before {
      border-radius: 3px; 
    }
  }
  input:checked ~ label {
    &:before {
      border-color: $c-primary;
      background-color: $c-primary;
    }
    &:after {
      left: 9px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}