.contact-form {  
  position: relative;
  max-width: 650px;
  margin: 0 auto;
  @supports(display:grid) {
    margin: auto;
    grid-column: 1/-1;
  }

  fieldset {
    min-width: 0; //override the default that stretches fieldset to fit contents
  }

  textarea {
    min-height: 100px;
  }
  h2, h3, h4, h5, p, label, div, a {
    @include color-light-neutral;
  }
  button {
    width: 100%;
  }
  .field-validation-error {
    font-size: 14px;
    margin: 5px 0;
  }
  &__phone-number {
    text-decoration: underline;
  }
  .grid {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @supports(display:grid) {
      display: grid;
    }
    &__item {
      flex-basis: 48%;
      .ktc-checkbox {
        label {
          font-size: 14px;
          line-height: 17px;
          margin: 0 15px 10px 0;          
          &:before {
            z-index: 2;
          }
          &:after {
            z-index: 3;
          }
        }
      }
      .explanation-text {
        position: relative;
        padding-left: 40px;
        top: -15px;
      }
      
    }

    //auto-wrap the second column if is too narrow
    &.grid--two-column-1-1 {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
  input[type="text"], input[type="email"], textarea {
    margin-bottom: 0;
  }
  .ktc-default-section {    
    margin-bottom: 20px;    
  }

  //error styling 
  .input-validation-error {
    border-color: $error-red;
    border-color: var(--error-red);
    border-width: 2px;
    &:focus {
      border-color: $error-red;
      border-color: var(--error-red);
    }
  }
  .field-validation-error {
    @include color-error-red;
    font-weight: 600;
  }
  .loader-overlay {
    @include background-secondary;
  }
  .formwidget-submit-text {
    position: absolute;
    @include background-secondary;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 28px;
    }
  }
  &__text {
    margin-bottom: 20px;
  }
  @media all and (min-width: $screen-xxs) {
    @supports (display: grid) {
      grid-column: 2/12;
    }
  }
  @media all and (min-width:$screen-small){
    @supports (display: grid) {
      grid-column: 3/11;
    }
  }
  @media all and (min-width:$large-tablet){
    .grid {
      &__item {
        @supports (display: grid) {
          grid-column: span 6;
        }
        iframe {
          right: 0;
          position: absolute;
        }
      }
    }
  }
  @media all and (min-width:$screen-xl){
    @supports (display: grid) {
      grid-column: 4/10;
    }
  }
}

@media screen and (max-width: $screen-xxs) {
  .contact-form {
    .grid.grid--two-column-1-1 {
    	grid-template-columns: 1fr;
    }
  }
}


.field-validation-error {
  @include color-error-red;
  font-weight: 600;
}
.input-validation-error {
  border-color: $error-red;
  border-color: var(--error-red);
  border-width: 2px;
  &:focus {
    border-color: $error-red;
    border-color: var(--error-red);
  }
}