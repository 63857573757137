.hero.hero--campaign {
  text-align: center;
  // overflow: visible;
  h1 {
    font-weight: $light;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 25px;
  }
  label {
    @include color-primary;
  }
  .hero__container {
    align-items: center;
  }
  .hero__content {
    .container {
      padding: 0;
    }
  }
  .contact-form {
    display: block;
  }
  .cta-bar {
    text-align: center;
    h2, h3, h4, h5, h6 {
      color: var(--c-mid-neutral);
      font-weight: $regular;
    }
  }
  .contact-form--campaign, .formwidget-submit-text {
    background: none;
    padding: 0 20px;
    
  }
  .contact-form--campaign .formwidget-submit-text {
    height: 100%;
    width: 100%;
    justify-content: end;
    padding: 0;
  }
  @media all and (min-width:$screen-small) {
    .contact-form--campaign{
      margin: 0;
    }
  }
  @media all and (min-width:$large-tablet) {
    text-align: left;
    .hero__container {
      min-height: 350px;
    }
    h1 {
      font-size: 55px;
      max-width: 600px;
      line-height: 75px;
    }
    .cta-bar {
      text-align: left; 
    }
    .contact-form--campaign {
      padding: 0;
    }
  }  
}