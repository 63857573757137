.search {    
  max-width: 800px;
  margin: 0 auto;
  @supports (display:grid){
    grid-column: 1/-1;
    max-width: initial;
    margin: initial;
  }  
  @include margin-bottom(10);
  form {
    display: flex;
    @include margin-bottom(4);
    position: relative;
    span {
      position: absolute;
      top: 15px;
      left: 15px;
      svg {
        width: 20px;
        max-height: 25px;
        path {
          fill: $c-primary;
          fill: var(--c-primary);
        }
      }
    }
    input {
      margin-bottom: 0;
      margin-right: 20px;
      padding-left: 45px;
    }
  }
  .pagination-container {
    margin-top: 20px;
  }
  @media all and (min-width:$screen-extra-small) {
    grid-column: 2/12;
  }
  @media all and (min-width:$large-tablet) {
    grid-column: 3/11;
  }
}