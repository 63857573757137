.card-container {
  @include vertical-margin(3);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @supports(display:grid) {
    display: grid;
    grid-template-columns:repeat(12, 1fr);
    grid-column-gap:20px;
    grid-row-gap:20px;
    grid-column: 1 / -1; 
    
  }
  h1, h2, h3, h4, h5, h6, p, a {
    grid-column: 1 / -1; 
    width: 100%;
  }
  h2 {
    margin-bottom: 0;
  }
 
}