.how-to {
  padding: 20px;
  @include vertical-margin(3);
  @include standard-border(1px 1px 1px 1px);  
  text-align: center;
  @supports(display:grid) {  
    grid-column: 1 / -1;
  }
  &__steps-item {
    position: relative;
    @include vertical-padding(3);
    &:after {
      content: "";
      width: 2px;
      height: 40px;      
      @include background-primary;
      display: block;
      position: absolute;    
      right: 50%;
      bottom: -20px; 
    }
    &:last-child {
      &::after{
        display: none;
      }
    }
    &--complete {
      h5, p, a {
        @include color-mid-grey;
      }     
    }
    h5 {
      @include vertical-margin(2);
      a {
        @extend h5;
        &:hover {         
          text-decoration: underline;          
        }
      }
    }
    p {
      max-width: 350px;
      margin: 0 auto;
    }
    svg {
      g {
        circle {
          @include fill-light-neutral;
        }
      }
    }
  }
  
  @media all and (min-width: $screen-extra-small){
    @supports(display:grid) {  
      grid-column: 2/12;
    }
  }
  @media all and (min-width: $screen-medium){
    &__steps {
      display: flex;
      flex-wrap: wrap;
    }
    &__steps-item {
      @supports(display:grid) {
        flex-basis: 50%;
      }
      flex-basis: 48%;
      @include horizontal-padding(1);
      &:after {
        display: none;
      }
      &--complete {
        .how-to__content-wrap {
          position: relative;
          &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: -43px;
            left: 50%;
            transform: translateX(-50%);
            height: 30px;
            width: 30px;
            border-radius: 50%; 
            @include background-primary;
          }
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -35px;
            left: 50%;
            // transform: translateX(-50%);
            width: 5px;
            height: 10px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg) translateX(-50%);
          }
        } 
      }
    }
  }
  @media all and (min-width: $screen-xl){
    &__steps-item {
      @supports(display:grid) {
        flex-basis: 25%;
      }
      flex-basis: 23%;
      &:after {
        display: block;
        right: -32px;
        top: 100px;
        height: 2px;
        width: 65px;
      }
      h5 {
        min-height: 56px;
      }
    }
    &__content-wrap {
      @include horizontal-padding(1);
    }
  }
}