@mixin vertical-padding($factor) {
  padding-top:($factor * 10)+px;
  padding-bottom:($factor * 10)+px;
}
@mixin horizontal-padding($factor) {
  padding-left:($factor * 10)+px;
  padding-right:($factor * 10)+px;
}
@mixin vertical-margin($factor) {
  margin-top:($factor * 10)+px;
  margin-bottom:($factor * 10)+px;
}
@mixin horizontal-margin($factor) {
  margin-left:($factor * 10)+px;
  margin-right:($factor * 10)+px;
}
@mixin margin-top($factor) {
  margin-top:($factor * 10)+px;
}
@mixin margin-bottom($factor) {
  margin-bottom:($factor * 10)+px;
}
@mixin margin-right($factor) {
  margin-right:($factor * 10)+px;
}
@mixin margin-left($factor) {
  margin-left:($factor * 10)+px;
}
@mixin padding-top($factor) {
  padding-top:($factor * 10)+px;
}
@mixin padding-bottom($factor) {
  padding-bottom:($factor * 10)+px;
}
@mixin padding-left($factor) {
  padding-left:($factor * 10)+px;
}
@mixin padding-right($factor) {
  padding-right:($factor * 10)+px;
}

// COLOUR MIXINS ##########################################


// core blue
@mixin background-primary {
  background-color: $c-primary;
  background-color: var(--c-primary);
}

@mixin background-primary-ukraine {
  background-color: #0057b7;
}


@mixin background-primary-hover {
  background-color: $c-primaryHover;
  background-color: var(--c-primary-hover);
}
@mixin color-primary {
  color: $c-primary;
  color: var(--c-primary);
}

// dark blue 
@mixin background-secondary {
  background-color: $c-secondary;
  background-color: var(--c-secondary);
}
@mixin color-secondary {
  color: $c-secondary;
  color: var(--c-secondary);
}

// orange 
@mixin background-primary-accent {
  background-color: $c-primaryAccent;
  background-color: var(--c-primary-accent);
}

@mixin background-primary-accent-ukraine {
  background-color: #004694;
}

@mixin background-primary-accent-hover {
  background-color: $c-primaryAccentHover;
  background-color: var(--c-primary-accent-hover);
}
@mixin color-primary-accent {
  color: $c-primaryAccent;
  color: var(--c-primary-accent);
}

// c-primaryAccentLight
@mixin background-primary-accent-light {
  background-color: $c-primaryAccentLight;
  background-color: var(--c-primaryAccentLight);
}
@mixin color-primary-accent-light {
  color: $c-primaryAccentLight;
  color: var(--c-primaryAccentLight);
}

//grey 
@mixin background-mid-neutral {
  background-color: $c-midNeutral;
  background-color: var(--c-mid-neutral);
}
@mixin color-mid-neutral {
  color: $c-midNeutral;
  color: var(--c-mid-neutral);
}

// white
@mixin background-light-neutral {
  background-color: $c-lightNeutral;
  background-color: var(--c-light-neutral);
}
@mixin color-light-neutral {
  color: $c-lightNeutral;
  color: var(--c-light-neutral);
}
@mixin fill-light-neutral {
  fill: $c-lightNeutral;
  fill: var(--c-light-neutral);
}

// black
@mixin color-dark-neutral {
  color: $c-darkNeutral;
  color: var(--c-dark-neutral);
}
@mixin background-dark-neutral {
  background-color: $c-darkNeutral;
  background-color: var(--c-dark-neutral);
}

//mid grey 
@mixin background-mid-grey {
  background-color: $mid-grey;
  background-color: var(--mid-grey);
}
@mixin color-mid-grey {
  color: $mid-grey;
  color: var(--mid-grey);
}

//light grey 
@mixin background-light-grey {
  background-color: $light-grey;
  background-color: var(--light-grey);
}
@mixin color-light-grey {
  color: $light-grey;
  color: var(--light-grey);
}

// error red
@mixin color-error-red {
  color: $error-red;
  color: var(--error-red);
}

// BORDER STYLING ############################

// colour light grey
@mixin footer-border($sides) {
  border-color: $light-grey;
  border-color: var(--light-grey);
  border-style: solid;
  border-width: $sides;
}

@mixin standard-border($sides) {
  border-color: $c-primary;
  border-color: var(--c-primary);
  border-style: solid;
  border-width: $sides;
  border-radius: 3px;
}
@mixin secondary-border($sides) {
  border-color: $c-primaryAccent;
  border-color: var(--c-primaryAccent);
  border-style: solid;
  border-width: $sides;
  border-radius: 3px;
}

@mixin border-lighter-grey {
  border: 1px solid $lighter-grey;
  border: 1px solid var(--c-lighter-grey);
}

@mixin border-primary {
  border: 1px solid $c-primary;
  border: 1px solid var(--c-primary);
}

@mixin border-primary-accent {
  border: 1px solid $c-primaryAccent;
  border: 1px solid var(--c-primary-accent);
}

@mixin border-radius {
  border-radius: $border-radius;
  border-radius: var(--border-radius);
}

// orange line 
@mixin primary-line {
  @include before-after;
  background-color: $c-primaryAccent;
  height: 100%;
  width: 8px;
  bottom: 0;
  left: 0;
}

// yellow line 
@mixin secondary-line {
  @include before-after;
  background-color: $c-tertiaryAccent;
  height: 100%;
  width: 5px;
  bottom: 0;
  left: 0;
}

// yellow line horizontal
@mixin secondary-line-horizontal {
  @include before-after;
  background-color: $c-tertiaryAccent;
  height: 5px;
  width: 100%;
  top: 0;
  right: 0;
}

// orange line horizontal
@mixin primary-line-horizontal {
  @include before-after;
  background-color: $c-primaryAccent;
  height: 10px;
  width: 100%;
  top: 0;
  right: 0;
}


// Ukrain yellow line horizontal
@mixin secondary-line-horizontal-ukraine {
  @include before-after;
  background-color: #ffd700;
  height: 5px;
  width: 100%;
  top: 0;
  right: 0;
}

@mixin primary-line-horizontal-ukraine {
  @include before-after;
  background-color: #ffd700;
  height: 10px;
  width: 100%;
  top: 0;
  right: 0;
}

// Font weights

@mixin weight-bold {
  font-weight: $weight-bold;
  font-weight: var(--weight-bold);
}
@mixin weight-mid {
  font-weight: $weight-mid;
  font-weight: var(--weight-mid);
}
@mixin weight-reg {
  font-weight: $weight-reg;
  font-weight: var(--weight-reg);
}

// CARD BORDER HOVER STYLING 

@mixin card-hover ($scale, $origin) {
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include background-primary;
  transform: $scale;
  transform-origin: $origin;
  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}

// SECONDARY CARD BORDER HOVER STYLING 

@mixin card-hover-secondary ($scale, $origin) {
  @include background-primary-accent;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;  
  transform: $scale;
  transform-origin: $origin;
  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}

// CONTENT BEFORE AND AFTER DEFAULT STYLING

@mixin before-after {
  content: "";
  display: block;
  position: absolute;
}

//ARROW 

@mixin arrow($rotate) {
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  width: 7px;
  height: 7px;
  transform: rotate($rotate);
  display: block;
  transition: all .2s ease;
}

//rich-text type styling
//replaces styling removed by resets
@mixin rich-text {
  p {
  	margin-bottom: 15px;
  }

  //undo removal of list-style-type for lists
  ul,
  ol {
  	margin: 30px 0 30px 30px;
  }

  ul {
  	list-style-type: initial;
  }

  ol {
  	list-style-type: decimal;
  }
}

//TODO why is this here?  
img {
  width: 100%;
}

// cards grid 

@mixin structure-grid($grid-row-gap, $grid-column-gap) {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: $grid-row-gap;
    grid-column-gap: $grid-column-gap;
  }
}

// card columns 
// all based on a 12col grid
@mixin one-column {
  flex-basis: 100%;
  @supports (display: grid) {
    grid-column: 1/-1;
  }
}
@mixin two-column {
  // these % will change per project/per component.
  flex-basis: 46%;
  @supports (display: grid) {
    grid-column: span 6;
  }
}
@mixin three-column {
  // these % will change per project/per component.
  flex-basis: 27%;
  @supports (display: grid) {
    grid-column: span 4;
  }
}
@mixin four-column {
  // these % will change per project/per component.
  flex-basis: 21%;
  @supports (display: grid) {
    grid-column: span 3;
  }
}
// Mainly use on the page builder grid

// NEEDS TESTING ON IE
@mixin five-column-span {
  // these % will change per project/per component.
  flex-basis: 43%;
  @supports (display: grid) {
    grid-column: span 5;
  }
}
// NEEDS TESTING ON IE
@mixin eight-column-span {
  // these % will change per project/per component.
  flex-basis: 65%;
  @supports (display: grid) {
    grid-column: span 8;
  }
}