.link-arrow {
  position: relative;
  line-height: 22px;
  &:after {
    content:">";
    @include color-primary;
    @include background-light-neutral;
    padding: 0px 6px;
    border-radius: 50%;
    @include margin-left(1);
    font-size: 12px;
    position: absolute;
    bottom: 0;
  }
}