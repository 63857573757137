.google-maps {
  @include margin-top(4);
  grid-column: 1/-1;
  #map {
    height: 400px;  /* The height is 400 pixels */
    width: 100%; 
  }  
  @media all and (min-width:$screen-extra-small) {
    grid-column: 2/12;
  }
}
