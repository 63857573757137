.franchise-footer-contact-bar {
  @include background-primary;
  @include color-light-neutral;
  text-align: center;
  h5{
    @include color-light-neutral;
    font-size: 18px;
  }
  a {
    text-decoration: underline;
    font-weight: $semi-bold;
    @include color-light-neutral;
  }
  p {
    @include color-light-neutral;
  }
  .container {
    padding: 0;
  }
  &__contact {
    position: relative;
    padding: 30px 20px ;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: $c-lightNeutral;
    border-color: var(--c-light-neutral);  
    width: 100%;
    p {
      margin-bottom: 15px;
    }
    a[href^="tel:"] {
      text-decoration: none;
      font-size: 18px;
    }
    span {
      padding-right: 5px;
      svg {
        max-width: 15px;
        circle {
          fill: $c-lightNeutral;
          fill: var(--c-light-neutral);
        }
        path {
          fill: $c-primary;
          fill: var(--c-primary);
        }
      }
    }
  }
  &__address {
    padding: 30px 20px 10px;
    p {
      max-width: 400px;
      margin: 0 auto;
    }
  }
  @media all and (min-width: $large-tablet) {
    // .container {
    //   padding: 0 20px;
    // }
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    &__contact {
      border: 0;
      text-align: left;
      padding-right: 0;
      width: 80%;
      a[href^="tel:"] {
        font-size: 22px;
      }
      span {
        svg {
          max-height: 26px;
          max-width: 20px;
        }
      }
      
    }
    &__address {
      text-align: right;
      padding-left: 0;
      p {
        max-width: 100%;
      }
    }
  }
}