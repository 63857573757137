.image-list.image-list--grid-three {
  @supports(display:grid) {
    grid-template-columns:repeat(3, 1fr);
    grid-gap: 15px;
  }
  img {
    max-height: 80px;
  }
  @media all and (min-width:$screen-extra-small) {
    img {
      max-height: 140px;
    }
  }
  @media all and (min-width:$large-tablet) {
    img {
      max-height: 120px;
    }
  }
  @media all and (min-width:$screen-xl) {
    img {
      max-height: 150px;
    }
  }
}