.card.card--icon {
  text-align: center;
  display: block;
  .card__icon {    
    max-width: 140px; 
    margin: 0 auto;
    @include margin-bottom(2);
  }
  @media all and (min-width:$screen-small) {   
    grid-column: span 6;    
  }
  @media all and (min-width:$screen-medium){
    grid-column: span 4;
  }
}